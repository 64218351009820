import React, { useCallback, useEffect, useRef, useState,  useContext }  from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { GlobalContex } from "../../../globalContex";
import "./selectoptions.scss";

const allCards = [
    {
        key:0,
        title:'Broker Related',
        description:'Are You interested in the CryptoBroker program? Lets talk about it.',
        link:'/brokerrelated',
    },
    {
        key:1,
        title:'Fund Manager Related',
        description:'Are You interested in the FundManager program? Lets talk about it.',
        link:'/fundmangerrelated',
    },
    {
        key:2,
        title:'Partnerships',
        description:'Are you looking to partner with IndianOTC for your business? Lets talk about it.',
        link:'/partnerships',
    },
    {
      key:3,
      title:'Investor Relations',
      description:"Are you interested in owning India’s next big cryptocurrency exchange? Lets talk about it.",
      link:'/investorrelations',
    },
    {
      key:4,
      title:'Career Opportunities',
      description:"Are you interested in working for India’s next big cryptocurrency exchange? Get in touch today.",
      link:'/careeropportunities',
    },
    {
      key:5,
      title:'Other Inquiries',
      description:'Have general questions or feedback? We would love to hear from you.',
      link:'/otherinquiries',
    },
]

function SelectOptions() {
    
    const {setselectedcontactquery} = useContext(GlobalContex);
    let navigate = useNavigate();
    let location = useLocation();

    const handleselect = (each) => {
        setselectedcontactquery(each.title)
        // localStorage.setItem('selectquery', `/contactus${each.link}`)
        navigate(`/thecompany${each.link}`)
    }

  return (
    <div className='optionsMaster'>
        <div className='headersection'>
            <p className='maintitle'>Select Of The Options</p>
            <p className='maindesc'>If you need assistance with an issue regarding your account. You can access our dedicated customer support chat inside the application. This page is reserved for other types of inquiries.</p>
        </div>
        <div className='cardsSection'>
            {allCards.map((each, id) => {
              return(
                <div className={ location.pathname === `/thecompany${each.link}` ?
                `eachcard selectactive`: `eachcard`} key={id} onClick={() => handleselect(each)}>
                    <p className='queryTitle'>{each.title}</p>
                    <p className='queryDesc'>{each.description}</p>
                </div>
              )
            })}
        </div>
    </div>
  )
}

export default SelectOptions