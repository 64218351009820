import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContex } from "../../globalContex";
import "./navmenu.scss";

import marketos from "../../static/images/about/marketos.svg";
import mymcb from "../../static/images/about/mymcb.svg";
import metaverse from "../../static/images/about/metaverse.svg";

import sharetokens from "../../static/images/about/sharetokens.svg";
import safetokens from "../../static/images/about/safetokens.svg";
import csoptokens from "../../static/images/about/csoptokens.svg";
import esoptokens from "../../static/images/about/esoptokens.svg";
import bondtokens from "../../static/images/about/bondtokens.svg";
import estatetokens from "../../static/images/about/estatetokens.svg";

import indianinvestor from "../../static/images/about/indianinvestor.svg";
import byindustry from "../../static/images/about/byindustry.svg";
import successstories from "../../static/images/about/successstories.svg";

import pubic from "../../static/images/about/pubic.svg";
import garageic from "../../static/images/about/garageic.svg";
import startupimg from "../../static/startupimg.svg";

import m1 from "../../static/mar.svg";
import m2 from "../../static/m2.svg";
import m3 from "../../static/m3.svg";

import headerlogo from "../../static/headerlogo.svg";
const mainMenu = [
  {
    id: 0,
    title: "Marketsverse",
    desc: "Discover, hire and manage your Ai powered development team.",
    icon: m1,
    link: "https://gravitichain.com/",
  },
 
  {
    id: 1,
    title: "Garage",
    desc: "Create and manage your startup in your GarageOS.",
    icon: garageic,
    link: "https://unlockgarage.com/",
  },
  {
    id: 1,
    title: "Viralverse",
    desc: "Create and manage your startup in your GarageOS.",
    icon: m2,
    link: "https://viral.group/",
  },
  {
    id: 0,
    title: "StartupBrokers",
    desc: "Discover, hire and manage your Ai powered development team.",
    icon: startupimg,
    link: "https://startupbrokers.com/",
  },
  {
    id: 1,
    title: "AIR",
    desc: "Create and manage your startup in your GarageOS.",
    icon: m3,
    link: "https://airevolution.app/",
  },
  // {
  //     id:2,
  //     title:"Publications",
  //     desc:"Create and monetize your own content business.",
  //     icon:pubic,
  //     link:"https://publications.app/"
  // },
];

const productMenu = [
  {
    id: 0,
    title: "MarketsOS",
    desc: "Suite of infrastructure products to build your fintech app.",
    icon: marketos,
  },
  {
    id: 1,
    title: "MyCryptoBrand",
    desc: "Plug and play loyalty infused commerce solution for businesses.",
    icon: mymcb,
  },
  {
    id: 2,
    title: "Metaverse App Store",
    desc: "List your business on the worlds first Metaverse App Store.",
    icon: metaverse,
  },
];

const protocolMenu = [
  {
    id: 0,
    title: "ShareTokens",
    desc: "Representing a direct equity interest in a private company.",
    icon: sharetokens,
  },
  {
    id: 1,
    title: "SafeTokens",
    desc: "Representing a CCPS agreement in a private company.",
    icon: safetokens,
  },
  {
    id: 2,
    title: "CSOPTokens",
    desc: "Representing a CSOP agreement in a private company.",
    icon: csoptokens,
  },
  {
    id: 3,
    title: "ESOPTokens",
    desc: "Representing a ESOP agreement in a private company.",
    icon: esoptokens,
  },
  {
    id: 4,
    title: "BondTokens",
    desc: "Representing various corporate debt instruments.",
    icon: bondtokens,
  },
  {
    id: 5,
    title: "EstateTokens",
    desc: "Representing ownership in a real estate asset.",
    icon: estatetokens,
  },
];

const companyMenu = [
  {
    id: 0,
    title: "About Us",
    desc: "Learn about our mission, history and vision.",
  },
  {
    id: 1,
    title: "Case Studies",
    desc: "Meet the founders and operational team that built the MarketsVerse.",
  },
  // {
  //   id: 1,
  //   title: "Team",
  //   desc: "Meet the founders and operational team that built the MarketsVerse.",
  // },
  // {
  //   id: 2,
  //   title: "Press",
  //   desc: "See everywhere MarketsVerse is mentioned in the media.",
  // },
  {
    id: 3,
    title: "Contact",
    desc: "We would love to speak to you.",
  },
];

const Navmenu = () => {
  let navigate = useNavigate();
  const {
    navHover,
    setnavHover,
    setcompanynav,
    setselectedcontactquery,
    homeDarrow,
    sethomeDarrow,
  } = useContext(GlobalContex);
  const handleGo = (name) => {
    console.log(name, "hiiii");

    if (name === "About Us") {
      setcompanynav("company");
      navigate("/aboutus");
    } else if (name === "Team") {
      setcompanynav("founder");
      navigate("/thecompany/founder");
    } else if (name === "Contact") {
      setcompanynav("contactus");
      setselectedcontactquery("");
      navigate("/thecompany/contactus");
    } else if (name === "MarketsOS") {
      window.open("https://marketsverse.com/products/digital%20bank", "_blank");
    } else if (name === "MyCryptoBrand") {
      window.open("https://mycryptobrand.com", "_blank");
    } else if (name === "Metaverse App Store") {
      window.open("https://metaverseapp.store", "_blank");
    } else if (name == "Case Studies") {
      navigate("/casestudies");
    }
  };
  return (
    <div className="nav-menu-container">
      <div
        className="big-box0 "
        style={{ display: homeDarrow ? "flex" : "none" }}
        onMouseDown={(e) => e.stopPropagation()}
      >
      

        <div className="cardsMenu">
          {mainMenu.map((each, i) => (
            <>
              <div
                className="boxinside"
                onClick={() => each.title!="marketsverse" && window.open(each?.link, "_blank")}
              >
                <div className="boxinsideinner">
                  <img className="menu-img" src={each.icon} alt="" />
                </div>
                <p>{each.title}</p>
              </div>
            </>
          
          ))}
        </div>
       
      </div>
     
      <div
        className="big-box2"
        onMouseEnter={() => setnavHover("protocols")}
        onMouseLeave={() => setnavHover("")}
        style={{ display: navHover === "protocols" ? "" : "none" }}
      >
        <div className="menu-title">Our Protocols</div>
        <div>
          {protocolMenu.map((each, i) => (
            <div className="each-menu" key={i}>
              <div className="menu-img-box">
                <img className="menu-img" src={each.icon} alt="" />
              </div>
              <div>
                <div className="menu-title">{each.title}</div>
                <div className="menu-desc">{each.desc}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="big-box3"
        onMouseEnter={() => setnavHover("casestudies")}
        onMouseLeave={() => setnavHover("")}
        style={{ display: navHover === "casestudies" ? "" : "none" }}
      >
        <div className="menus-left">
          <div className="menu-title">Wholly Owned Platforms</div>
          <section
            className="menus"
            onClick={() => window.open("https://indianinvestor.com", "_blank")}
          >
            <div className="menu-img-box">
              <img className="menu-img" src={indianinvestor} alt="" />
            </div>
            <div>
              <div className="menu-title">IndianInvestor.com</div>
              <div className="menu-desc">
                India’s first marketplace for private equities.
              </div>
            </div>
          </section>
        </div>
        <div className="menus-right">
          <div className="menu-title">Third Party Case Studies</div>
          <section
            className="menus"
            onClick={() =>
              window.open("https://marketsverse.com/casestudies", "_blank")
            }
          >
            <div className="menu-img-box">
              <img className="menu-img" src={byindustry} alt="" />
            </div>
            <div>
              <div className="menu-title">By Industry</div>
              <div className="menu-desc">
                See how companies are using MarketsVerse in different
                industries.
              </div>
            </div>
          </section>
          <section className="menus" onClick={() => navigate("/tracker")}>
            <div className="menu-img-box">
              <img className="menu-img" src={successstories} alt="" />
            </div>
            <div>
              <div className="menu-title">MV Tracker </div>
              <div className="menu-desc">
                The companies who have benefited most from MarketsVerse.
              </div>
            </div>
          </section>
        </div>
      </div>
      <div
        className="big-box4"
        onMouseEnter={() => setnavHover("thecomapny")}
        onMouseLeave={() => setnavHover("")}
        style={{ display: navHover === "thecomapny" ? "" : "none",
    
      
            paddingLeft:"20px",
            paddingRight:"20px",
            width:"20rem"
          
    }}
      >
        {/* <div className="menu-title">The Company</div> */}
        <div>
          {companyMenu.map((each, i) => (
            <div
              className="each-menu"
              key={i}
           
            //   style={{ opacity: i === 2 ? 0.4 : 1 }}
              onClick={() => handleGo(each.title)}
            >
              <div>
                <div className="menu-title">{each.title}</div>
                {/* <div className="menu-desc">{each.desc}</div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navmenu;
