import React from "react";
import ReactPlayer from "react-player";
import "./player.scss";

const Player = () => (
  <div className="player-wrapper">
    <ReactPlayer
      url="https://www.youtube.com/watch?v=V9V_6bLuePU"
      className="react-player"
      playing
    //   width="100%"
    //   height="350px"
      controls={true}
    />
  </div>
);

export default Player;
