import React, { useEffect, useRef, useState, useContext } from "react";
import { GlobalContex } from "../../../globalContex";
import { Link, useNavigate } from "react-router-dom";
import SplashHeader from "../../../globalComponents/SplashHeader";
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";
import { companyMenu } from "../alldata";
import ContactUs from "../ContactusPage";
import gxicon from "../../../static/images/company/gxicon.svg";
import main from "../../../static/images/company/main.svg";
import shorupanimg from "../../../static/images/company/shorupanimg.svg";
import bluetick from "../../../static/images/company/bluetick.svg";
// import "./theCompany.scss";
import OldNavmenu from "../../../globalComponents/oldNavMenu/oldNavMenu";
import NewNavbar from "../../../globalComponents/newNav/newNavbar";
import Navmenu from "../../../globalComponents/navMenus/navmenu";
import starfish from "../../../static/starfish.svg";
import "./starfish.scss";
const Index = () => {
  const {
    collapsedSideBar,
    setselectedcontactquery,
    showDraw,
    setShowDraw,
    companynav,
    setcompanynav,
    mobsize,
    pin,
  } = useContext(GlobalContex);

  let navigate = useNavigate();
  useEffect(() => {
    // console.log(pin, "kjegdkjweded");
    // if (pin !== "4141") {
    //   navigate("/");
    // }
  }, []);

  return (
    <div>
      <div
        onMouseDown={(e) => setShowDraw(false)}
        className={showDraw ? "overlayClose" : ""}
      >
        {" "}
      </div>
      {/* <SplashHeader /> */}
      <>
        <div
        // style={{
        //   display: showallnav ? "" : mobdescribe === false ? "" : "none",
        // }}
        >
          {mobsize > 900 ? (
            <>
              <NewNavbar />
              <Navmenu />
              {/* <SplashHeader />
            <OldNavmenu /> */}
            </>
          ) : (
            <SplashHeader />
          )}
        </div>
      </>
      <div className="landingPage">
        <div className="pageWrap">
          <>
            {collapsedSideBar ? (
              <Mobmenu />
            ) : (
              <>
                <div className="mainStarfish">
                  <div className="starfish">
                    <div data-aos="fade-right">
                      <h1>
                        Take your startup from an idea to market ready in 6
                        months.
                      </h1>
                      <label>Apply Now</label>
                    </div>
                    <div className="blackimg" data-aos="fade-left">
                      <img src={starfish} />
                    </div>
                  </div>
                  <div className="section2stair">
                    <h1>The STA Onboarding Process.</h1>
                    <div className="cards">
                      <div className="items">
                        <h4>1</h4>
                        <h2>Submit Your Idea</h2>
                        <h5>
                          Fill out our simple form <span>Here</span>. Tell us
                          what your startup intends to build and the size of
                          your current operation
                        </h5>
                      </div>

                      <div className="items">
                        <h4>2</h4>
                        <h2>Virtual Pitch (Business)</h2>
                        <h5>
                          If we like your vision, you will be requested to
                          perform a 15 minute pitch via Zoom in front of one of
                          our analysts.
                        </h5>
                      </div>

                      <div className="items">
                        <h4>3</h4>
                        <h2>Tech Deep Dive</h2>
                        <h5>
                          If the pitch goes well, then you will conduct a call
                          with one of our senior technology executives to unpack
                          the software you envision to build.
                        </h5>
                      </div>

                      <div className="items">
                        <h4>4</h4>
                        <h2>Starfish Term Sheet</h2>
                        <h5>
                          After internal deliberation, Starfish may issue you
                          the standard <span>STA Term Sheet</span>. After which
                          you will have 48 hrs to accept or reject the offer.
                        </h5>
                      </div>

                      <div className="items">
                        <h4>5</h4>
                        <h2>We Build Your Product</h2>
                        <h5>
                          Over the next 6 months, Marketsverse will build the
                          first version of your product while Starfish gets you
                          ready for your capital raise/product launch.
                        </h5>
                      </div>

                      <div className="items">
                        <h4>6</h4>
                        <h2>Share Buy Back Upon Launch</h2>
                        <h5>
                          Once your product is ready to launch you can choose to
                          buy back the shares you gave to Starfish or keep us
                          onboard into your launch.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Index;
