import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import './mobmenu.scss';
import { GlobalContex } from "../../globalContex";

function Mobmenu() {

    const navigate = useNavigate();
    const {
        collapsedSideBar,
        setCollapsedSideBar,
        selectNav,
        setSelectNav,
        setselectedcontactquery,
        setcompanynav,
        prevnav, setprevnav,
    } = useContext(GlobalContex);

    const handleNav = (from) => {
        if(from === 'about') {
          setCollapsedSideBar(false)
          setSelectNav(from)
          // setprevnav('about')
          navigate('/')
        }else if(from === 'products'){
          setCollapsedSideBar(false)
          setSelectNav(from)
          navigate('/')
        }
        else if(from === 'casestudies'){
          setCollapsedSideBar(false)
          setSelectNav(from)
          // setprevnav('casestudies')
          navigate('/casestudies')
        }
        else if(from === 'templates'){
          setCollapsedSideBar(false)
          setSelectNav(from)
          // setprevnav('templates')
          navigate('/templates')
        }
        else if(from === 'thecompany'){
          setCollapsedSideBar(false)
          setSelectNav(from)
          setselectedcontactquery("")
          setcompanynav("company")
          // setprevnav('thecompany')
          navigate('/aboutus')
        }
        else if (from === 'Tracker')
        {
          navigate('/tracker')
        }
      }
  return (
    <div
    className="collapsedSideBar"
  >
     <div className={ selectNav === 'about' ? "mob-nav-title-active" :  "mob-nav-title"} onClick={(e) => handleNav('about')}>Home</div>
      <div className={ selectNav === 'products'? "mob-nav-title-active" :  "mob-nav-title"} onClick={() => handleNav('products')}>Products</div>
     <div className={ selectNav === 'templates'? "mob-nav-title-active" :  "mob-nav-title"} onClick={() => handleNav('templates')}>Templates</div> 
     <div className={ selectNav === 'casestudies'? "mob-nav-title-active" :  "mob-nav-title"} onClick={() => handleNav('About Us')}>About Us</div>
     <div className={ selectNav === 'thecompany'? "mob-nav-title-active" :  "mob-nav-title"} onClick={() => handleNav('thecompany')}>The Company</div>
     <div className={ selectNav === 'Store'? "mob-nav-title-active" :  "mob-nav-title"} onClick={() => handleNav('Store')}>App Store</div>
     <div className={ selectNav === 'Tracker'? "mob-nav-title-active" :  "mob-nav-title"} onClick={() => handleNav('Tracker')}>Tracker</div>
     <div className="mob-nav-title1">
      <div className="mob-login-btn" onClick={() =>  window.open(`http://app.mycryptobrand.com`, "_blank")}>Login</div>
      <div className="mob-launch-btn" onClick={() => window.open("https://registration.mycryptobrand.com/mycryptobrand", "_blank")}>Launch App</div>
     </div>
  </div>
  )
}

export default Mobmenu;