import React , {useState, useContext} from 'react';
import { GlobalContex } from "../../globalContex";
// import {Link} from 'react-scroll';
import Scrollspy from 'react-scrollspy-highlight';
import './scrollNavbar.scss';

const ScrollNavbar = () => {
  const {currentitem, setcurrentitem} = useContext(GlobalContex);
  return (
    <div>
        <Scrollspy className="scrolldiv1" items={ ['sec_1','sec_2', 'sec_3', 'sec_4', 'sec_5', 'sec_6', 'sec_7'] } style={{display: 'flex', listStyle: 'none', justifyContent: 'space-between', width: '78vw', marginLeft: "5%", padding: 0, alignItems: 'center'}}
        >
          <li onClick={e => setcurrentitem('one')} className= {currentitem !== 'one' ? "scrolllink" : 'scrolllink is-current'} id="mycb_1"><a href="#sec_1">What Is MarketsVerse?</a></li>
          <li onClick={e => setcurrentitem('two')} className= {currentitem !== 'two' ? "scrolllink" : 'scrolllink is-current'} id="mycb_2"><a href="#sec_2">Who Can Use It?</a></li>
          <li onClick={e => setcurrentitem('three')} className= {currentitem !== 'three' ? "scrolllink" : 'scrolllink is-current'} id="mycb_3"><a href="#sec_3">What Type Of MarketsVerse’s Are There?</a></li>
          <li onClick={e => setcurrentitem('four')} className= {currentitem !== 'four' ? "scrolllink" : 'scrolllink is-current'} id="mycb_4"><a href="#sec_4">What Is Included In An MarketsVerse?</a></li>
          <li onClick={e => setcurrentitem('five')} className= {currentitem !== 'five' ? "scrolllink" : 'scrolllink is-current'} id="mycb_5"><a href="#sec_5">Why Should You Use MarketsVerse?</a></li>
          <li onClick={e => setcurrentitem('six')} className= {currentitem !== 'six' ? "scrolllink" : 'scrolllink is-current'} id="mycb_6"><a href="#sec_6">Our Stats</a></li>
          {/* <li onClick={e => setcurrentitem('seven')} className= {currentitem !== 'seven' ? "scrolllink" : 'scrolllink is-current'} id="mycb_7"><a href="#sec_7">FAQ’s</a></li> */}
        </Scrollspy>
    </div>
  )
}

export default ScrollNavbar