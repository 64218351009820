import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import classNames from "./about.module.scss";
import CountUp, { useCountUp } from "react-countup";
import demoIcon from "../../../static/images/clipIcons/demo.svg";
import { GlobalContex } from "../../../globalContex";
import axios from "axios";
import SplashHeader from "../../../globalComponents/SplashHeader";
import Footer from "../../../globalComponents/Footer";
import downarrow from "../../../static/images/icons/dropdownarrow.svg";
import uparrow from "../../../static/images/icons/dropuparrow.svg";
import affliate from "../../../static/images/products/productsidebar/affliate.svg";
import bonds from "../../../static/images/products/productsidebar/bonds.svg";
import defi from "../../../static/images/products/productsidebar/defi.svg";
import digitalbank from "../../../static/images/products/productsidebar/digitalbank.svg";
import equity from "../../../static/images/products/productsidebar/equity.svg";
import exchange from "../../../static/images/products/productsidebar/exchange.svg";
import funds from "../../../static/images/products/productsidebar/funds.svg";
import nft from "../../../static/images/products/productsidebar/nft.svg";
import mycryptoicon from "../../../static/images/about/mycryptoicon.svg";
import horzline from "../../../static/images/about/horzline.svg";
import vertline from "../../../static/images/about/vertline.svg";
import tablearrow from "../../../static/images/about/tablearrow.svg";
import defaultapp from "../../../static/images/default_app.png";
import { GetAllCompanies } from "../../../services/homepage.js";
import "./AboutCustom.scss";
import {
  eachvalMenu,
  carouselMenu,
  productMenu,
  providecards,
  describeMenu,
  newcarouselMenu,
} from "../alldata";
import sidearrow from "../../../static/images/products/arrow1.svg";

import clickarrow from "../../../static/images/products/productsdata/clickarrow.svg";
import loadingimg from "../../../static/images/loading.gif";
import plus from "../../../static/images/plus.svg";
import youtubeimg from "../../../static/images/about/youtubeimg.svg";
import closeicon from "../../../static/close.svg";
import Player from "../../../globalComponents/player/player";

import start from "../../../static/images/about/start.svg";
import runningcrypto from "../../../static/images/about/runningcrypto.svg";
import community from "../../../static/images/about/community.svg";
import invest from "../../../static/images/about/invest.svg";
import runningnoncrypto from "../../../static/images/about/runningnoncrypto.svg";
import noneofabove from "../../../static/images/about/noneofabove.svg";
import searchabout from "../../../static/images/about/searchabout.svg";
import labelarrow from "../../../static/images/about/labelarrow.svg";

import whitestart from "../../../static/images/about/whitestart.svg";
import whiterunningcrypto from "../../../static/images/about/whiterunningcrypto.svg";
import whitecommunity from "../../../static/images/about/whitecommunity.svg";
import whiteinvest from "../../../static/images/about/whiteinvest.svg";
import whiterunningnoncrypto from "../../../static/images/about/whiterunningnoncrypto.svg";
import whitenoneofabove from "../../../static/images/about/whitenoneofabove.svg";

import Products from "../Products/products";
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";
import ScrollNavbar from "../../../globalComponents/scrollNav/scrollNavbar";
import FacingProducts from "../facingProducts/facingProducts";
///table images
import cryptobrandlog from "../../../static/images/templateLogos/cryptobrandlog.svg";
import correct from "../../../static/images/templateLogos/correct.svg";
import wrong from "../../../static/images/templateLogos/wrong.svg";

import line1 from "../../../static/images/about/line1.svg";
import Newtoggle from "../../../globalComponents/toggle/newtoggle";
// import anime1 from "../../../static/images/anime1.gif";
import NewNavbar from "../../../globalComponents/newNav/newNavbar";
import Navmenu from "../../../globalComponents/navMenus/navmenu";
import OldNavmenu from "../../../globalComponents/oldNavMenu/oldNavMenu";

/// end here
import TextTransition, { presets } from "react-text-transition";

import a1 from "../../../static/a1.png";
import a2 from "../../../static/a2.png";
import a3 from "../../../static/a3.png";
import a4 from "../../../static/a4.png";

import b1 from "../../../static/b1.svg";
import b2 from "../../../static/b2.svg";
import b3 from "../../../static/b3.svg";
import b4 from "../../../static/b4.svg";
import b5 from "../../../static/b5.svg";
import b6 from "../../../static/b6.svg";

import Slider from "react-slick";
const TEXTS = ["Idea", "Business", "Community", "Passion"];
const appresponsive = {
  0: {
    items: 5,
  },
  512: {
    items: 12,
  },
};

var settings = {
  dots: true,
  autoplay: true,
  arrows: false,
  dots: false,
  infinite: true,
  speed: 1000,
  swipeToSlide: true,
  slidesToShow: 9,
  slidesToScroll: 1,
};
var settingss = {
  dots: true,
  autoplay: true,
  arrows: false,
  dots: false,
  infinite: true,
  speed: 1000,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
};

const About = () => {
  const {
    selectedCoinSplash,
    setAllCoins,
    collapsedSideBar,
    selectNav,
    setSelectNav,
    setSelectedMenu1,
    showDraw,
    setcurrentitem,
    setcompanynav,
    selectedpair,
    setselectedpair,
    ischeck,
    setischeck,
    mobdescribe,
    setmobdescribe,
    userque,
    setuserque,
    mobsize,
    setnavHover,
    sethomeDarrow,
    pin,
  } = useContext(GlobalContex);
  const vidRef = useRef();
  const wrapperRef = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(pin, "kjegdkjweded");
    // if (pin !== "4141") {
    //   navigate("/");
    // }
  }, []);

  const [tempdropdown, settempdropdown] = useState();
  const [classChange, setclassChange] = useState(false);
  const [totalAppUser, setTotalAppUser] = useState(0);
  const [fiatAsset, setFiatAsset] = useState(0);
  const [cryptoAsset, setCryptoAsset] = useState(0);
  const [visibleVideoModal, setVisibleVideoModal] = useState(false);
  const [allApps, setAllApps] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showfulldescribe, setshowfulldescribe] = useState(false);

  const [disableBack, setDisableBack] = useState(false);

  const [youTubeLink, setYouTubeLink] = useState("");
  const [allcompanies, setallcompanies] = useState([]);
  const [rescer, setrescer] = useState([]);
  const [selectedacc, setselectedacc] = useState(1);
  const [iscompanyLoading, setisCompanyLoading] = useState(false);
  const [lazyloadimg, setlazyloadimg] = useState(false);
  const [playVideo, setplayVideo] = useState(false);
  const [scrollup, setscrollup] = useState(0);
  const [showallnav, setshowallnav] = useState(true);
  const [scrolltop, setscrolltop] = useState(0);
  const [startmenuCount, setstartmenuCount] = useState(10);
  const [faqdrop, setfaqdrop] = useState(9);
  // const [endmenuCount , setendmenuCount] = useState(4);
  // const [tempmenu, settempmenu] = useState(newcarouselMenu.slice(0, 4));

  // useEffect(() => { vidRef.current.play(); },[]);

  useEffect(() => {
    setnavHover("");
  }, []);

  const getcomp = () => {
    GetAllCompanies().then((response) => {
      if (response.data.status) {
        setallcompanies(response.data.apps);
        setrescer(response.data.apps);
        setisCompanyLoading(false);
      } else {
        setisCompanyLoading(false);
        // console.log(response.data.message);
      }
    });
  };

  const handledescribe = (i) => {
    setuserque(i);
    if (i === 0) {
      setshowfulldescribe(false);
    } else if (i === 6) {
      setcompanynav("contactus");
      navigate("/thecompany");
    } else {
      setshowfulldescribe(true);
    }
  };

  useEffect(() => {
    setSelectNav("about");
    if (allcompanies.length === 0) {
      setisCompanyLoading(true);
    }
  }, []);

  useEffect(() => {
    getcomp();
    // if (allcompanies.length > 0) {
    //   setTimeout(() => {
    //     setlazyloadimg(true);
    //   }, 1000);
    // }
  }, [allcompanies]);

  const convertCurrencySystem = (inputValue) => {
    if (selectedCoinSplash.coinSymbol === "INR") {
      return Math.abs(Number(inputValue)) >= 1.0e7
        ? (Math.abs(Number(inputValue)) / 1.0e7).toFixed(1)
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e5
        ? (Math.abs(Number(inputValue)) / 1.0e5).toFixed(1)
        : // Three Zeroes for Thousands
        Math.abs(Number(inputValue)) >= 1.0e3
        ? (Math.abs(Number(inputValue)) / 1.0e3).toFixed(1)
        : Math.abs(Number(inputValue));
    } else {
      // Nine Zeroes for Billions
      return Math.abs(Number(inputValue)) >= 1.0e9
        ? (Math.abs(Number(inputValue)) / 1.0e9).toFixed(1)
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e6
        ? (Math.abs(Number(inputValue)) / 1.0e6).toFixed(1)
        : // Three Zeroes for Thousands
        Math.abs(Number(inputValue)) >= 1.0e3
        ? (Math.abs(Number(inputValue)) / 1.0e3).toFixed(1)
        : Math.abs(Number(inputValue));
    }
  };

  const convertCurrencySystem1 = (inputValue) => {
    if (selectedCoinSplash.coinSymbol === "INR") {
      return Math.abs(Number(inputValue)) >= 1.0e7
        ? "Cr"
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e5
        ? "L"
        : // Three Zeroes for Thousands
        Math.abs(Number(inputValue)) >= 1.0e3
        ? "K"
        : "";
    } else {
      // Nine Zeroes for Billions
      return Math.abs(Number(inputValue)) >= 1.0e9
        ? "B"
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e6
        ? "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(inputValue)) >= 1.0e3
        ? "K"
        : "";
    }
  };

  const handlenav = (title) => {
    setSelectedMenu1(title);
    setSelectNav("products");
    // console.log(selectNav);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://comms.globalxchange.io/coin/vault/get/all/coins`)
      .then((res) => {
        setAllCoins(res.data.coins);
      });

    axios.get(`https://comms.globalxchange.io/gxb/apps/get`).then((res) => {
      setAllApps(res.data.count);
    });

    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/users/holdings/data/get`
      )
      .then((res) => {
        setTotalAppUser(res.data.totalUsersCount);
      });

    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/users/total/holdings/data/all`
      )
      .then((res) => {
        setFiatAsset(res.data.total_liquid_fiatHoldings);
        setCryptoAsset(res.data.total_liquid_cryptoHoldings);
      });
  }, []);

  const apps = allcompanies.map((each, id) => {
    return (
      <div
        className={classNames.companyeachcard}
        // onClick={() => navigate("/casestudies")}
      >
        <div className={classNames.companyeachbox}>
          <img className={classNames.companyimg} src={each.app_icon} alt="" />
          {/* <img
            className={classNames.companyimg}
            src={
              !lazyloadimg
                ? loadingimg
                : each.app_icon !== ""
                ? each.app_icon
                : defaultapp
            }
            alt=""
          /> */}
        </div>
        {/* <div className={classNames.companyname}>{each.app_name}</div> */}
      </div>
    );
  });

  useEffect(() => {
    if (visibleVideoModal) {
      setYouTubeLink("https://www.youtube.com/embed/V9V_6bLuePU");
    } else {
      setYouTubeLink("");
    }
  }, [visibleVideoModal]);

  const handleChange = (id) => {
    setuserque(id);
    setplayVideo(false);
  };

  const handleScroll = (e) => {
    setscrollup(e.target.scrollTop);
    if (
      e.target.scrollTop >
      e.target.children[0].children[0].clientHeight +
        e.target.children[0].children[1].clientHeight -
        50
    ) {
      setcurrentitem("one");
    }
    if (
      e.target.scrollTop >
      e.target.children[0].children[0].clientHeight +
        e.target.children[0].children[1].clientHeight +
        e.target.children[0].children[2].clientHeight +
        e.target.children[0].children[3].clientHeight -
        50
    ) {
      setcurrentitem("two");
    }
    if (
      e.target.scrollTop >
      e.target.children[0].children[0].clientHeight +
        e.target.children[0].children[1].clientHeight +
        e.target.children[0].children[2].clientHeight +
        e.target.children[0].children[3].clientHeight +
        e.target.children[0].children[4].clientHeight -
        50
    ) {
      setcurrentitem("three");
    }
    if (
      e.target.scrollTop >
      e.target.children[0].children[0].clientHeight +
        e.target.children[0].children[1].clientHeight +
        e.target.children[0].children[2].clientHeight +
        e.target.children[0].children[3].clientHeight +
        e.target.children[0].children[4].clientHeight +
        e.target.children[0].children[5].clientHeight -
        50
    ) {
      setcurrentitem("four");
    }
    if (
      e.target.scrollTop >
      e.target.children[0].children[0].clientHeight +
        e.target.children[0].children[1].clientHeight +
        e.target.children[0].children[2].clientHeight +
        e.target.children[0].children[3].clientHeight +
        e.target.children[0].children[4].clientHeight +
        e.target.children[0].children[5].clientHeight +
        e.target.children[0].children[6].clientHeight -
        50
    ) {
      setcurrentitem("five");
    }
    if (
      e.target.scrollTop >
      e.target.children[0].children[0].clientHeight +
        e.target.children[0].children[1].clientHeight +
        e.target.children[0].children[2].clientHeight +
        e.target.children[0].children[3].clientHeight +
        e.target.children[0].children[4].clientHeight +
        e.target.children[0].children[5].clientHeight +
        e.target.children[0].children[6].clientHeight +
        e.target.children[0].children[7].clientHeight -
        50
    ) {
      setcurrentitem("six");
    }
    if (
      e.target.scrollTop >
      e.target.children[0].children[0].clientHeight +
        e.target.children[0].children[1].clientHeight +
        e.target.children[0].children[2].clientHeight +
        e.target.children[0].children[3].clientHeight +
        e.target.children[0].children[4].clientHeight +
        e.target.children[0].children[5].clientHeight +
        e.target.children[0].children[6].clientHeight +
        e.target.children[0].children[7].clientHeight +
        e.target.children[0].children[8].clientHeight -
        50
    ) {
      setcurrentitem("seven");
    }
    if (scrollup <= e.target.scrollTop) {
      setshowallnav(false);
    } else {
      setshowallnav(true);
    }
    if (
      e.target.scrollTop >=
        e.target.children[0].children[0].clientHeight +
          e.target.children[0].children[1].clientHeight -
          50 &&
      showallnav
    ) {
      setclassChange(true);
    } else {
      setclassChange(false);
    }
  };

  const handlemove = (e) => {
    console.log(typeof e.currentTarget.id);
    if (e.currentTarget.id == 7) {
      document.getElementById(17).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(17);
    } else if (e.currentTarget.id == 9) {
      document.getElementById(18).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(18);
    } else if (e.currentTarget.id == 19) {
      document.getElementById(10).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(10);
    } else if (e.currentTarget.id == 20) {
      document.getElementById(11).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(11);
    } else {
      document.getElementById(e.currentTarget.id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(e.currentTarget.id);
    }
  };
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div
      className={disableBack && !showDraw ? "overlayClosetemp" : ""}
      style={{
        overflowX: "hidden",
        marginBottom: mobdescribe === false ? "30px" : "",
      }}
    >
      <div
        style={{
          display: showallnav ? "" : mobdescribe === false ? "" : "none",
        }}
      >
        {scrollup === 0 && mobsize > 900 ? (
          <>
            <NewNavbar />
            <Navmenu />
          </>
        ) : mobsize > 900 ? (
          <>
            <NewNavbar />
            <Navmenu />
            {/* <SplashHeader />
            <OldNavmenu /> */}
          </>
        ) : (
          <SplashHeader />
        )}
      </div>
      <>
        {selectNav === "products" ? (
          <>
            <Products />
          </>
        ) : (
          <>
            {collapsedSideBar ? (
              <Mobmenu />
            ) : (
              <>
                <div
                  className="scrollmainfull"
                  style={{
                    overflowY: disableBack ? "" : "scroll",
                    height: "100vh",
                    // paddingBottom: "110px",
                    overflowX: "hidden",
                  }}
                  // onScroll={(e) => handleScroll(e)}
                  // onClick={() => sethomeDarrow(false)}
                ></div>
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default About;
