import React, { useState, useEffect, useContext } from "react";
import {
  GetBrandCategories,
  GetAllBrands,
  GetBrandsOnCategoryaBased,
} from "../../../services/homepage";
import SplashHeader from "../../../globalComponents/SplashHeader";
import defaultapp from "../../../static/images/default_app.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./caseStudies.scss";
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContex } from "../../../globalContex";
import downarrow from "../../../static/images/icons/dropdownarrow.svg";
import uparrow from "../../../static/images/icons/dropuparrow.svg";
import OldNavmenu from "../../../globalComponents/newNav/newNavbar";
import NewNavbar from "../../../globalComponents/newNav/newNavbar";
import Navmenu from "../../../globalComponents/navMenus/navmenu";

function CaseStudies() {
  const {
    collapsedSideBar,
    setselectedcontactquery,
    showDraw,
    setShowDraw,
    mobsize,
    pin,
  } = useContext(GlobalContex);
  const [categoryMenu, setcategoryMenu] = useState([
    {
      id: "a",
      name: "All Companies",
      category_id: "",
    },
  ]);
  const [selectedcategoryid, setselectedcategoryid] = useState("");
  const [brands, setbrands] = useState([]);
  const [isbrandLoading, setisbrandLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(pin, "kjegdkjweded");
    // if (pin !== "4141") {
    //   navigate("/");
    // }
  }, []);

  useEffect(() => {
    if (categoryMenu.length === 1) {
      GetBrandCategories().then((response) => {
        let result = response.data;
        if (result.status) {
          let data = [...categoryMenu];
          data.push(...result.categories);
          setcategoryMenu(data);
          handleBrands("");
        }
      });
    }
  }, []);

  const handleBrands = (id) => {
    setselectedcategoryid(id);
    setisbrandLoading(true);
    // if(id === ''){
    //     GetAllBrands().then(response => {
    //         let result = response.data;
    //         if(result.status){
    //             setbrands(result.apps)
    //             setisbrandLoading(false)
    //         }
    //     })
    // } else{
    GetBrandsOnCategoryaBased(id).then((response) => {
      let result = response.data;
      if (result.status) {
        setbrands(result.apps);
        setisbrandLoading(false);
      }
    });
    // }
  };

  return (
    <div>
      <div
        onMouseDown={(e) => setShowDraw(false)}
        className={showDraw ? "overlayClose" : ""}
      >
        {" "}
      </div>
      {/* <SplashHeader /> */}
      <>
        <div
        // style={{
        //   display: showallnav ? "" : mobdescribe === false ? "" : "none",
        // }}
        >
          {mobsize > 900 ? (
            <>
              <NewNavbar />
              <Navmenu />
              {/* <SplashHeader />
            <OldNavmenu /> */}
            </>
          ) : (
            <SplashHeader />
          )}
        </div>
      </>
      <>
        {collapsedSideBar ? (
          <Mobmenu />
        ) : (
          <div className="casestudyMaster">
            <div className="mob-sticky">
              <div className="case-main-head">
                Companies That Have Taken Their <br /> Crypto Brand To New
                Heights
              </div>
              <div className="mob-case-main-head">
                Companies That Have Taken Their Crypto Brand To New Heights
              </div>
              <div className="all-categories">
                {categoryMenu.map((each, i) => {
                  return (
                    <div
                      className={
                        selectedcategoryid === each.category_id
                          ? "each-category-selected"
                          : "each-category"
                      }
                      key={i}
                      style={{
                        cursor: isbrandLoading ? "not-allowed" : "pointer",
                      }}
                      onClick={(e) =>
                        isbrandLoading ? "" : handleBrands(each.category_id)
                      }
                    >
                      {each.name}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="all-brands">
              {isbrandLoading ? (
                <div className="all-brands">
                  {Array(6)
                    .fill()
                    .map((one, j) => {
                      return (
                        <div className="each-brand" key={j}>
                          <Skeleton className="brand-icon" />
                          <Skeleton
                            className="brand-name"
                            style={{
                              height: "30px",
                              width: "200px",
                              borderRadius: "5px",
                            }}
                          />
                          <Skeleton
                            className="brand-name"
                            style={{
                              height: "20px",
                              width: "200px",
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              ) : (
                <>
                  {brands.length > 0 ? (
                    <div className="all-brands">
                      {brands.map((one, j) => {
                        return (
                          <div
                            className="each-brand"
                            key={j}
                            // onClick={(e) => window.open(`http://indianinvestor.com`, '_blank')}
                          >
                            <img
                              className="brand-icon"
                              src={
                                one.app_icon === "" ? defaultapp : one.app_icon
                              }
                              alt=""
                            />
                            <p className="brand-name">{one.app_name}</p>
                            <p className="brand-desc">
                              {one.short_description.length > 60
                                ? `${one.short_description.substr(0, 60)} ....`
                                : one.short_description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default CaseStudies;
