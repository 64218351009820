import { useCallback, useEffect, useRef, useState, useContext  } from "react";
import { GlobalContex } from "../../../globalContex";
import "./contactus.scss";
import { Link, useNavigate } from "react-router-dom";
import SplashHeader from "../../../globalComponents/SplashHeader";
import collaborate from '../../../static/images/collaborate.svg';
import searchicon from '../../../static/images/searchicon.svg';
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";
import PartnerShips from "../partnershipsPage";

const allCards = [
    {
        key:0,
        title:'Broker Related',
        description:'Are You interested in the CryptoBroker program? Lets talk about it.',
        link:'/brokerrelated',
    },
    {
        key:1,
        title:'Fund Manager Related',
        description:'Are You interested in the FundManager program? Lets talk about it.',
        link:'/fundmangerrelated',
    },
    {
        key:2,
        title:'Partnerships',
        description:'Are you looking to partner with IndianOTC for your business? Lets talk about it.',
        link:'/partnerships',
    },
    {
      key:3,
      title:'Investor Relations',
      description:"Are you interested in owning India’s next big cryptocurrency exchange? Lets talk about it.",
      link:'/investorrelations',
    },
    {
      key:4,
      title:'Career Opportunities',
      description:"Are you interested in working for India’s next big cryptocurrency exchange? Get in touch today.",
      link:'/careeropportunities',
    },
    {
      key:5,
      title:'Other Inquiries',
      description:'Have general questions or feedback? We would love to hear from you.',
      link:'/otherinquiries',
    },


]

function ContactUs() {
  const {collapsedSideBar, selectedcontactquery, setselectedcontactquery, showDraw, setShowDraw} = useContext(GlobalContex);
  const [verticals, setverticals] = useState('')
  let navigate = useNavigate();
  const handleselect = (each) => {
    setselectedcontactquery(each.title)
    navigate(`/thecompany${each.link}`)
  }

  return (
    <>
   {
    selectedcontactquery === "" ? 
        <div className='contactMaster'>
          <div className='titlesection'>
            <div className="title-box1">It’s Time To Collaboarate</div>
            <div className="input-box1">
              <img className="searchicon" src={searchicon} alt='' />
              <input
                className="verticals"
                type="text"
                style={{
                  padding: "0px 5px",
                  border: "none",
                }}
                value={verticals}
                onChange={(e) => setverticals(e.target.value)}
                placeholder="Search For Verticals..."
              />
            </div>
          </div>
          <div className='querysection'>
            {allCards.filter(x => {
              return(
                x.title.toLowerCase().includes(verticals.toLowerCase())
              )
            })
            .map((each, id) => {
              return(
                <div className={id === 0 || id === 3 ? 'eachcard1' : 'eachcard'} key={id} onClick={() => handleselect(each)}>
                    <p className='queryTitle'>{each.title}</p>
                    <p className='queryDesc'>{each.description}</p>
                </div>
              )
            })}
          </div>
        </div>

        : 
        < PartnerShips />}
    </>
      
  );
}

export default ContactUs;
