import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import './newNavbar.scss';
import myCrypto_full from "../../static/logo.svg";
import { GlobalContex } from "../../globalContex";
import d1arrow from "../../static/images/about/d1arrow.svg";


const NewNavbar = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { showDraw, setShowDraw, selectedMenu, setSelectedMenu,collapsedSideBar, setCollapsedSideBar,setselectedcontactquery,
        selectNav, setSelectNav, prevnav, setprevnav, setcompanynav, navHover, setnavHover, homeDarrow, sethomeDarrow} =
        useContext(GlobalContex);
        const handleNav = (from) => {
            if(from === 'about') {
              setSelectNav(from)
              setprevnav('about')
              navigate('/')
              setCollapsedSideBar(false)
            }else if(from === 'products'){
              setSelectNav(from)
              navigate('/products')
            }
            else if(from === 'casestudies'){
              setSelectNav(from)
              setprevnav('casestudies')
              navigate('/casestudies')
            }
            else if(from === 'templates'){
              setSelectNav(from)
              // setprevnav('templates')
              // navigate('/templates')
            }
            else if(from === 'Solutions'){
              setSelectNav(from)
              setprevnav('templates')
              navigate('/templates')
            }
            else if(from === 'thecompany'){
              setSelectNav(from)
              setselectedcontactquery("")
              setcompanynav("company")
              setprevnav('thecompany')
              navigate('/aboutus')
            }
          }
          
  return (
      <div className='nn-main'>
          <div className='nn-left'>
            <div className="l1">
              <img className='nn-img' src={myCrypto_full} alt=""  onClick={() => navigate('/')} />
              </div>
              <div className="nn-arrow" onClick={() => sethomeDarrow(!homeDarrow)}>
                <img style={{transform:homeDarrow ? "rotate(0deg)" : "rotate(180deg)", transition:"all ease .5s"}} src={d1arrow} alt="" />
              </div>
          </div>
          <div className='nn-right'>
              {/* <div className='nn-menu' onClick={(e) => handleNav('about')} style={{fontWeight: selectNav === 'about' ? '700' : ''}}>About</div> */}
              {/* <div className='nn-menu'
             onClick={()=>navigate('/templates')}
             onMouseEnter={() => setnavHover('Solutions')} onMouseLeave={() => setnavHover('')}>Templates</div> */}
              <div className='nn-menu' 
              onClick={()=>navigate('/products')}
              // onClick={(e) => selectNav === 'products' ? handleNav(prevnav) : handleNav('products')} 
              style={{fontWeight: selectNav === 'products' ? '700' : ''}} onMouseEnter={() => setnavHover('products')} onMouseLeave={() => setnavHover('')}> Product Lines</div>
             
             
             {/* <div className='nn-menu'
             onClick={()=>navigate('/templates')}
             onMouseEnter={() => setnavHover('Solutions')} onMouseLeave={() => setnavHover('')}>Templates</div> */}

              <div className='nn-menu' 
                 
                
              // onClick={(e) =>  handleNav('templates')} 
              style={{fontWeight: selectNav === 'templates' ? '700' : ''}} onMouseEnter={() => setnavHover('Pricing')} onMouseLeave={() => setnavHover('')}
              
              onClick={()=>navigate('/pricing')}
              >Pricing</div>
              {/* <div className='nn-menu' 
              // onClick={(e) => handleNav('casestudies')}
               style={{fontWeight: selectNav === 'casestudies' ? '700' : ''}} onMouseEnter={() => setnavHover('casestudies')} onMouseLeave={() => setnavHover('')}>Used Cases</div> */}
                            <div className='nn-menu'
              //  onClick={(e) =>  handleNav('thecompany')}
                style={{fontWeight: selectNav === 'thecompany' ? '700' : ''}} onMouseEnter={() => setnavHover('thecomapny')} onMouseLeave={() => setnavHover('')}>About Us</div>
                 </div>
                 <div className='nn-right'
                 style={{
                  gap:"1rem"
                 }}
                 >

 
                              <div className="nn-btn-app" onClick={() => window.open('https://metaverseapp.store/brands', '_blank')}>App Store</div>
              <div className="nn-btn" onClick={() => navigate('/tracker')}>Tracker</div>
          </div>
      </div>
  )
}

export default NewNavbar;