import React, { useState, useContext } from "react";
import { GlobalContex } from "../../../globalContex";
import Products from '../Products/products';
import "./facingProducts.scss";

const FacingProducts = () => {
    const { selectedfpnav, setselectedfpnav } = useContext(GlobalContex);
  return (
    <div className='fp-main'>
        <div className='fp-nav'>
            <div className={selectedfpnav === "Proprietary Infrastructure" ? 'each-fp-nav each-fp-nav-active' : 'each-fp-nav'} onClick={() => setselectedfpnav("Proprietary Infrastructure")}>Proprietary Infrastructure</div>
            <div className={selectedfpnav === "Localized Marketplace" ? 'each-fp-nav each-fp-nav-active' : 'each-fp-nav'} onClick={() => setselectedfpnav("Localized Marketplace")}>Localized Marketplace</div>
            <div className={selectedfpnav === "Capital Markets" ? 'each-fp-nav each-fp-nav-active' : 'each-fp-nav'} onClick={() => setselectedfpnav("Capital Markets")}>Capital Markets</div>
            <div className={selectedfpnav === "Integrated Infrastructure" ? 'each-fp-nav each-fp-nav-active' : 'each-fp-nav'} onClick={() => setselectedfpnav("Integrated Infrastructure")}>Integrated Infrastructure</div>
        </div>
        <div className='fp-productbox'>
          {selectedfpnav === "Proprietary Infrastructure" ? 
         <Products />
          : ""}
        </div>
    </div>
  )
}

export default FacingProducts;