import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContex } from "../../../globalContex";
import SplashHeader from "../../../globalComponents/SplashHeader";
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";
import productmaindesk from "../../../static/productlaptop.svg";
import "./productMain.scss";
// import { productMenu } from "../alldata";
import MyscrollSpy from "../../../globalComponents/mdscrollspy/msscrollcomp";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import OldNavmenu from "../../../globalComponents/newNav/newNavbar";
import NewNavbar from "../../../globalComponents/newNav/newNavbar";
import Navmenu from "../../../globalComponents/navMenus/navmenu";
import { Link } from "react-router-dom";
import classNames from "../About/about.module.scss";
import Newtoggle from "../../../globalComponents/toggle/newtoggle";
import cryptobrandlog from "../../../static/web3today.svg";
import FacingProducts from "../About/FaceProducts"
import correct from "../../../static/images/templateLogos/correct.svg";
import wrong from "../../../static/images/templateLogos/wrong.svg";
import {
  eachvalMenu,
  carouselMenu,
  productMenu,
  providecards,
  providecardss,
  describeMenu,
  newcarouselMenu,
} from "../alldata";

import "../Templates/templates.scss";








import labelarrow from "../../../static/images/about/labelarrow.svg";

import downarrow from "../../../static/images/icons/dropdownarrow.svg";
import uparrow from "../../../static/images/icons/dropuparrow.svg";

import sidearrow from "../../../static/images/products/arrow1.svg";


const ProductMain = () => {
  const {
    collapsedSideBar,
    selectedTemplate,
    setSelectedTemplate,
    templateList,
    setSelectedTemplateMenu,
    disableBack,
    showDraw,
    setShowDraw,
    productmainMenu,
    setproductmainMenu,
    mobsize,
    pin,
    selectedCoinSplash,
    setAllCoins,

    selectNav,
    setSelectNav,
    setSelectedMenu1,

    setcurrentitem,
    setcompanynav,
    selectedpair,
    setselectedpair,
    ischeck,
    setischeck,
    mobdescribe,
    setmobdescribe,
    userque,
    setuserque,

    setnavHover,
    sethomeDarrow,



  } = useContext(GlobalContex);
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(pin, "kjegdkjweded");
    // if (pin !== "4141") {
    //   navigate("/");
    // }
  }, []);









  const [query, setQuery] = useState("");
  const [classChange, setclassChange] = useState(false);
  // const [selectedpair, setselectedpair] = useState('')

  const wrapperRef = useRef();
  const [tempdropdown, settempdropdown] = useState();

  const [totalAppUser, setTotalAppUser] = useState(0);
  const [fiatAsset, setFiatAsset] = useState(0);
  const [cryptoAsset, setCryptoAsset] = useState(0);
  const [visibleVideoModal, setVisibleVideoModal] = useState(false);
  const [allApps, setAllApps] = useState(0);

  const [loading, setLoading] = useState(false);
  const [showfulldescribe, setshowfulldescribe] = useState(false);

  // const [disableBack, setDisableBack] = useState(false);
  // const [mobsize, setmobsize] = useState(0);
  const [youTubeLink, setYouTubeLink] = useState("");
  const [allcompanies, setallcompanies] = useState([]);
  const [rescer, setrescer] = useState([]);
  const [selectedacc, setselectedacc] = useState(1);
  const [iscompanyLoading, setisCompanyLoading] = useState(false);
  const [lazyloadimg, setlazyloadimg] = useState(false);
  const [playVideo, setplayVideo] = useState(false);
  const [scrollup, setscrollup] = useState(0);
  const [showallnav, setshowallnav] = useState(true);
  const [scrolltop, setscrolltop] = useState(0);
  const [startmenuCount, setstartmenuCount] = useState(10);
  const [faqdrop, setfaqdrop] = useState(9);
  const [indexdata, setindexdata] = useState(0);

  const [hoverdata, sethoverdata] = useState("Web3");
  const [hovertoogle, sethovertoogle] = useState(false);
  // const [endmenuCount , setendmenuCount] = useState(4);
  // const [tempmenu, settempmenu] = useState(newcarouselMenu.slice(0, 4));

  // useEffect(() => { vidRef.current.play(); },[]);
  const sliderRef = useRef();




  const convertCurrencySystem = (inputValue) => {
    if (selectedCoinSplash.coinSymbol === "INR") {
      return Math.abs(Number(inputValue)) >= 1.0e7
        ? (Math.abs(Number(inputValue)) / 1.0e7).toFixed(1)
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e5
          ? (Math.abs(Number(inputValue)) / 1.0e5).toFixed(1)
          : // Three Zeroes for Thousands
          Math.abs(Number(inputValue)) >= 1.0e3
            ? (Math.abs(Number(inputValue)) / 1.0e3).toFixed(1)
            : Math.abs(Number(inputValue));
    } else {
      // Nine Zeroes for Billions
      return Math.abs(Number(inputValue)) >= 1.0e9
        ? (Math.abs(Number(inputValue)) / 1.0e9).toFixed(1)
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e6
          ? (Math.abs(Number(inputValue)) / 1.0e6).toFixed(1)
          : // Three Zeroes for Thousands
          Math.abs(Number(inputValue)) >= 1.0e3
            ? (Math.abs(Number(inputValue)) / 1.0e3).toFixed(1)
            : Math.abs(Number(inputValue));
    }
  };

  const convertCurrencySystem1 = (inputValue) => {
    if (selectedCoinSplash.coinSymbol === "INR") {
      return Math.abs(Number(inputValue)) >= 1.0e7
        ? "Cr"
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e5
          ? "L"
          : // Three Zeroes for Thousands
          Math.abs(Number(inputValue)) >= 1.0e3
            ? "K"
            : "";
    } else {
      // Nine Zeroes for Billions
      return Math.abs(Number(inputValue)) >= 1.0e9
        ? "B"
        : // Six Zeroes for Millions
        Math.abs(Number(inputValue)) >= 1.0e6
          ? "M"
          : // Three Zeroes for Thousands
          Math.abs(Number(inputValue)) >= 1.0e3
            ? "K"
            : "";
    }
  };

  const handlenav = (title) => {
    setSelectedMenu1(title);
    setSelectNav("products");
    // console.log(selectNav);
  };



  const handleChange = (id) => {
    setuserque(id);
    setplayVideo(false);
  };



  const handlemove = (e) => {
    console.log(typeof e.currentTarget.id);
    if (e.currentTarget.id == 7) {
      document.getElementById(17).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(17);
    } else if (e.currentTarget.id == 9) {
      document.getElementById(18).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(18);
    } else if (e.currentTarget.id == 19) {
      document.getElementById(10).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(10);
    } else if (e.currentTarget.id == 20) {
      document.getElementById(11).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(11);
    } else {
      document.getElementById(e.currentTarget.id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
      setstartmenuCount(e.currentTarget.id);
    }
  };
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  // const lastImageIndex = images.length - 1;




















  const handleScroll = (e) => {
    if (e.target.scrollTop >= e.target.children[0].clientHFive - 20) {
      setclassChange(true);
    } else {
      setclassChange(false);
    }
  };

  const handleNav = (each) => {
    setproductmainMenu(each.title);
  };

  useEffect(() => {
    navigate(`/products/${productmainMenu.toLowerCase()}`);
  }, [productmainMenu]);

  return (
    <div>
      <div
        onMouseDown={(e) => setShowDraw(false)}
        className={showDraw ? "overlayClose" : ""}
      >
        {" "}
      </div>
      {/* <SplashHeader /> */}
      <>
        {/* <OldNavmenu /> */}
        {
          mobsize > 900 ? (
            <>
              {/* <SplashHeader /> */}
              <NewNavbar />
              <Navmenu />
              {/* <SplashHeader /> */}
            </>
          ) : (
            <></>
          )
          // <SplashHeader />
        }
      </>
      <>
        {collapsedSideBar ? (
          <Mobmenu />
        ) : (
          <>
            <div
              className="productmain-master"
              onScroll={(e) => handleScroll(e)}
            >
              <div className="section1">
                <div className="full-box">
                  <div className="section1-rightbox">
                    <img
                      className="section1-main-img1"
                      src={productmaindesk}
                      alt=""
                    />
                  </div>
                  <div className="section1-leftbox">
                    <div className="mini-head">Stand Out In Your Industry</div>
                    <div className="main-head">
                      Nine Industry Specific <br />
                      Product Lines
                    </div>
                    <div className="start-btn">Get Started</div>
                  </div>
                </div>
              </div>
              <div className="section2">
                <div
                  className="section2-part1"
                  style={{ display: classChange ? "none" : "" }}
                >
                  <div className="section2-main-card">

                    <div className="section2-all-pairs">
                      {productMenu.map((each, id) => {
                        return (
                          <div className="section2-each-pair"
                            style={{
                              opacity: each.title == hoverdata ? 1 : 0.5
                            }}
                            onMouseEnter={() => sethoverdata(each.title)}
                            onClick={() => sethoverdata(each.title)}
                          >
                            <img
                              className="section2-each-img"
                              src={each.icon}
                              alt=""
                            />
                            <div className="section2-each-title">
                              {each.title}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className={classNames.alltempcards}
                // style={{
                //   borderRadius: "0px 0px 30px 30px"
                // }}
                >
                  <div className={classNames.labelarrow}>
                    <img
                      className={classNames.imglabelarrow}
                      src={labelarrow}
                      alt=""
                    />
                  </div>
                  <div className={classNames.mainalllabels}>
                    <div
                      className={classNames.alllabelsbox}
                      // onScroll={e => handlelabelscroll(e)}
                      ref={wrapperRef}
                    >
                      {newcarouselMenu.map((each, j) => {
                        return (
                          <div
                            className={classNames.eachlabelsbox}
                            style={{
                              backgroundImage: `url(${each.backgroundImg})`,
                              backgroundSize: "cover",
                              opacity: startmenuCount == j ? 1 : 0.4,
                              cursor: "pointer",
                            }}
                            // ref={wrapperRef}
                            id={j}
                            onClick={
                              // parseInt(j) === 8 ? "" :parseInt(j) === 9 ? "" : parseInt(j) === 19 ? "" :parseInt(j) === 20 ? "" :
                              handlemove
                            }
                          >
                            <div className="blacktinit"></div>
                            <img
                              className={classNames.eachitemimg}
                              src={each.icon}
                              alt=""
                            />
                            <div className={classNames.whitelabtitle}>
                              {each.title}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={classNames.tempdatabox}>
                    <div className={classNames.tpdheadbox}>
                      <div className={classNames.tpdsec1}>
                        <div className={classNames.tpdtextbox}>
                          <div className={classNames.tpdhead}>
                            {newcarouselMenu[startmenuCount].head}
                          </div>
                          <div className={classNames.tpdsubhead}>
                            {newcarouselMenu[startmenuCount].subhead}
                          </div>
                        </div>
                        <div className={classNames.tpdbuttonbox}>
                          <div
                            className={classNames.tpdbtn1}
                            style={{
                              cursor:
                                newcarouselMenu[startmenuCount].id > 5
                                  ? "not-allowed"
                                  : "",
                              opacity:
                                newcarouselMenu[startmenuCount].id > 5
                                  ? "0.25"
                                  : "",
                            }}
                            onClick={(e) =>
                              !newcarouselMenu[startmenuCount]
                                .disableexample &&
                                newcarouselMenu[startmenuCount].id <= 5
                                ? window.open(
                                  newcarouselMenu[startmenuCount]
                                    .navurl,
                                  "_blank"
                                )
                                : ""
                            }
                          >
                            See&nbsp;Demo
                          </div>
                          <div
                            className={classNames.tpdbtn2}
                            style={{
                              cursor: "not-allowed",
                              opacity: "0.25",
                            }}
                          // onClick={() => {
                          //   setselectedpair(newcarouselMenu[startmenuCount].title)
                          //   navigate(newcarouselMenu[startmenuCount].link)}}
                          >
                            Learn More
                          </div>
                        </div>
                      </div>
                      <div className={classNames.tpddesc}>
                        {newcarouselMenu[startmenuCount].desc}
                      </div>
                    </div>
                    <div className={classNames.tpddropbox}>
                      <div
                        onClick={() =>
                          tempdropdown === 0
                            ? settempdropdown(4)
                            : settempdropdown(0)
                        }
                      >
                        <div className={classNames.tpdeachdrop}>
                          <div>Who Is It For?</div>
                          <img
                            className={classNames.tpdeachdropimg}
                            src={
                              tempdropdown === 0 ? uparrow : downarrow
                            }
                            alt=""
                          />
                        </div>

                        <div
                          style={{
                            display: tempdropdown === 0 ? "" : "none",
                          }}
                        >
                          {newcarouselMenu[
                            startmenuCount
                          ].drop1data.map((one, k) => {
                            return (
                              <div className={classNames.drop1box}>
                                <div
                                  className={classNames.drop1title}
                                >
                                  {one.head}
                                </div>
                                <>
                                  {one.desc.map((element, l) => {
                                    return (
                                      <div
                                        className={
                                          classNames.drop1desc
                                        }
                                      >
                                        <div>
                                          <img
                                            className={
                                              classNames.tempdrop1img
                                            }
                                            src={sidearrow}
                                            alt=""
                                          />
                                        </div>
                                        <div>{element}</div>
                                      </div>
                                    );
                                  })}
                                </>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          tempdropdown === 1
                            ? settempdropdown(4)
                            : settempdropdown(1)
                        }
                      >
                        <div className={classNames.tpdeachdrop}>
                          <div>How To Monetize?</div>
                          <img
                            className={classNames.tpdeachdropimg}
                            src={
                              tempdropdown === 1 ? uparrow : downarrow
                            }
                            alt=""
                          />
                        </div>

                        <div
                          className={classNames.alldrop2cards}
                          style={{
                            display: tempdropdown === 1 ? "" : "none",
                          }}
                        >
                          {newcarouselMenu[
                            startmenuCount
                          ].drop2data.map((one, k) => {
                            return (
                              <div className={classNames.drop2box}>
                                <div>
                                  <span>
                                    <img
                                      className={
                                        classNames.tpdeachdrop2img
                                      }
                                      src={one.icon}
                                      alt=""
                                    />
                                    {one.title}
                                  </span>
                                </div>
                                <div className={classNames.drop2desc}>
                                  {one.text}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        style={{ borderBottom: "1px solid #EFEFEF" }}
                      // onClick={() =>
                      //   tempdropdown === 2
                      //     ? settempdropdown(4)
                      //     : settempdropdown(2)
                      // }
                      >
                        <div className={classNames.tpdeachdrop1}>
                          <div>What Is The Cost?</div>
                          <div
                            className={classNames.requestPricing}
                            onClick={(e) =>
                              window.open(
                                "https://marketsverse.com/thecompany/brokerrelated",
                                "_blank"
                              )
                            }
                          >
                            Request Pricing
                          </div>
                          {/* <img
                                      className={classNames.tpdeachdropimg}
                                      src={
                                        tempdropdown === 2 ? uparrow : downarrow
                                      }
                                      alt=""
                                    /> */}
                        </div>

                        <div
                          className={classNames.tpdstatsbox}
                          style={{
                            display: tempdropdown === 2 ? "" : "none",
                          }}
                        >
                          {/* <div className={classNames.tpdeachstat}>
                                    <div className={classNames.tpdeachstathead}>{newcarouselMenu[startmenuCount].drop3data[0]}</div>
                                    <div className={classNames.tpdeachstatsubhead}>{newcarouselMenu[startmenuCount].drop3data[1]}</div>
                                  </div>
                                  <img  className={classNames.tpdeachstatimg} src={line1} alt="" />
                                  <div  className={classNames.tpdeachstat}>
                                    <div  className={classNames.tpdeachstathead}>{newcarouselMenu[startmenuCount].drop3data[2]}</div>
                                    <div className={classNames.tpdeachstatsubhead}>{newcarouselMenu[startmenuCount].drop3data[3]}</div>
                                  </div>
                                  <img  className={classNames.tpdeachstatimg} src={line1} alt="" />
                                  <div  className={classNames.tpdeachstat}>
                                    <div  className={classNames.tpdeachstathead}>{newcarouselMenu[startmenuCount].drop3data[4]}</div>
                                    <div className={classNames.tpdeachstatsubhead}>{newcarouselMenu[startmenuCount].drop3data[5]}</div>
                                  </div> */}
                          {newcarouselMenu[
                            startmenuCount
                          ].newpricedata.map((single, n) => (
                            <div
                              key={n}
                              className={classNames.tpdstatsminibox}
                            >
                              <div
                                className={
                                  classNames.tpdstatsboxTitle
                                }
                              >
                                {single.title}
                              </div>
                              <div
                                className={classNames.tpdeachstatrow}
                              >
                                {single.prices.map((item, m) => (
                                  <div
                                    className={classNames.tpdeachstat}
                                    key={m}
                                  >
                                    <div
                                      className={
                                        classNames.tpdeachstathead
                                      }
                                    >
                                      {item.value}
                                    </div>
                                    <div
                                      className={
                                        classNames.tpdeachstatsubhead
                                      }
                                    >
                                      {item.head}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
                  className="section2-part1-update"
                  style={{ display: classChange ? "" : "none" }}
                >
                  <div className="update-all-pairs">
                    {productMenu.map((each, id) => {
                      return (
                        <div
                          className="update-each-pair"
                          onClick={(e) => handleNav(each)}
                        >
                          <img
                            className="update-each-img"
                            src={each.icon}
                            alt=""
                          />
                          <div
                            className="update-each-title"
                            style={{
                              display:
                                productmainMenu === each.title ? "" : "none",
                            }}
                          >
                            {each.title}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="scroll-main-body">
                    <MyscrollSpy />
                  </div>
                </div> */}



                <section className={classNames.productbox} id="sec_4">
                  <div className="productboxinner">
                    <div className={classNames.producthead}>
                      <div>User Facing Products</div>
                      <div className={classNames.togbox}>
                        By Template&nbsp;&nbsp;
                        <Newtoggle />
                        &nbsp;&nbsp;By Category
                      </div>
                    </div>
                    <div className={classNames.mobstatshead}>
                      Products By Category
                    </div>
                    <div className={classNames.facingproductsbox}>
                      {!ischeck ? (
                        <div className={classNames.tablebox}>
                          <table className={classNames.wholetable}>
                            <thead>
                              <tr>
                                <th>
                                  <div
                                    className={classNames.tableNavdiv1}
                                  >
                                    <img
                                      className={classNames.tablemainimg}
                                      src={cryptobrandlog}
                                      alt=""
                                    />
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Crypto Vaults
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Fiat Vaults
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    MoneyMarkets
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    TokenSwap
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Affiliate App Integration
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Custom Comp Plan
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    OTC
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    OTC Marketplace
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Portfolio.AI
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    ShareToken Issuance
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    ShareToken Marketplace
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    FundCoin Issuance
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    FundCoin Marketplace
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    IndexFund Issuance
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    IndexFund Marketplace
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Bond Issuance
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Bond Marketplace
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    NFT Issuance
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    NFTMarketplace
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Terminal Local
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Terminal Global
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Staking Vaults
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Publication
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Education Issuance
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Education Marketplace
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Crypto Wallets
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Defi Integration
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Defi Routing Engine
                                  </div>
                                </th>
                                <th>
                                  <div className={classNames.columnhead}>
                                    Decentralized Staking
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {carouselMenu.map((single, i) => {
                                return (
                                  <tr>
                                    <th>
                                      <div
                                        className={
                                          classNames.eachtablesidemenu
                                        }
                                      >
                                        <img
                                          className={
                                            classNames.tablemenuimg
                                          }
                                          src={single.coloricon}
                                          alt=""
                                        />
                                        <div
                                          className={
                                            classNames.tablemenutitle
                                          }
                                        >
                                          {single.title}
                                        </div>
                                      </div>
                                    </th>
                                    <>
                                      {single.templateAccess.map(
                                        (one, j) => {
                                          return (
                                            <td>
                                              <div
                                                className={
                                                  classNames.tabmenubox1
                                                }
                                              >
                                                <div>
                                                  <img
                                                    src={
                                                      one.value === true
                                                        ? correct
                                                        : wrong
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                          );
                                        }
                                      )}
                                    </>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <FacingProducts />
                      )}
                    </div>
                    <div className={classNames.facingproductsboxmob}>
                      <FacingProducts />
                    </div>
                    <div className={classNames.mobstatshead1}>
                      Products By Template
                    </div>
                    <div className={classNames.facingproductsboxmob}>
                      <div className={classNames.tablebox}>
                        <table className={classNames.wholetable}>
                          <thead>
                            <tr>
                              <th>
                                <div className={classNames.tableNavdiv1}>
                                  <img
                                    className={classNames.tablemainimg}
                                    src={cryptobrandlog}
                                    alt=""
                                  />
                                  <div
                                    className={
                                      classNames.mobtablemaintext
                                    }
                                  >
                                    Template
                                  </div>
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Crypto Vaults
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Fiat Vaults
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  MoneyMarkets
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  TokenSwap
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Affiliate App Integration
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Custom Comp Plan
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  OTC
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  OTC Marketplace
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Portfolio.AI
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  ShareToken Issuance
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  ShareToken Marketplace
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  FundCoin Issuance
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  FundCoin Marketplace
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  IndexFund Issuance
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  IndexFund Marketplace
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Bond Issuance
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Bond Marketplace
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  NFT Issuance
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  NFTMarketplace
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Terminal Local
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Terminal Global
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Staking Vaults
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Publication
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Education Issuance
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Education Marketplace
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Crypto Wallets
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Defi Integration
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Defi Routing Engine
                                </div>
                              </th>
                              <th>
                                <div className={classNames.columnhead}>
                                  Decentralized Staking
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {carouselMenu.map((single, i) => {
                              return (
                                <tr>
                                  <th>
                                    <div
                                      className={
                                        classNames.eachtablesidemenu
                                      }
                                    >
                                      <img
                                        className={
                                          classNames.tablemenuimg
                                        }
                                        src={single.coloricon}
                                        alt=""
                                      />
                                      <div
                                        className={
                                          classNames.tablemenutitle
                                        }
                                      >
                                        {single.title}
                                      </div>
                                    </div>
                                  </th>
                                  <>
                                    {single.templateAccess.map(
                                      (one, j) => {
                                        return (
                                          <td>
                                            <div
                                              className={
                                                classNames.tabmenubox1
                                              }
                                            >
                                              <div>
                                                <img
                                                  src={
                                                    one.value === true
                                                      ? correct
                                                      : wrong
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </td>
                                        );
                                      }
                                    )}
                                  </>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default ProductMain;
