// Package Imports
import { useState, useEffect, useContext, useLayoutEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Redirect,
  useNavigate,
} from "react-router-dom";

// CSS Imports
import "./App.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

// Context Imports
import { GlobalContex } from "./globalContex";

// Image Imports
import MyCrypto from "./static/images/menulogos/myCrypto.svg";


import logo1 from "./static/images/globalSidebarLogos/1.svg";
import logo2 from "./static/images/globalSidebarLogos/2.svg";
import logo3 from "./static/images/globalSidebarLogos/3.svg";
import logo4 from "./static/images/globalSidebarLogos/4.svg";
import logo5 from "./static/images/globalSidebarLogos/5.svg";
import logo6 from "./static/images/globalSidebarLogos/6.svg";
import logo7 from "./static/images/globalSidebarLogos/7.svg";

import Meta from "./static/images/icons/meta.svg";
import Banker from "./static/images/menulogos/Banker.svg";
import Capitalized from "./static/images/menulogos/Capitalized.svg";
import Create from "./static/images/menulogos/Create.svg";
import FundManager from "./static/images/menulogos/FundManager.svg";
import OTCDesks from "./static/images/menulogos/OtcDesks.svg";
import Terminals from "./static/images/menulogos/Terminals.svg";

import myCrypto_full from "./static/images/logos/myCrypto_full.svg";

import meta_full from "./static/images/logos/mverse_full.svg";
import banker_full from "./static/images/logos/banker_full.svg";
import cap_full from "./static/images/logos/cap_full.svg";
import create_full from "./static/images/logos/create_full.svg";
import otc_full from "./static/images/logos/otc_full.svg";
import terminals_full from "./static/images/logos/terminals_full.svg";
import funds_full from "./static/images/logos/funds_full.svg";

import crm from "./static/images/sidebarIcons/crm.svg";
import dash from "./static/images/sidebarIcons/dash.svg";
import affiliates from "./static/images/sidebarIcons/affiliates.svg";
import vaults from "./static/images/sidebarIcons/vaults.svg";
import terminal from "./static/images/sidebarIcons/terminal.svg";
import bonds from "./static/images/sidebarIcons/bonds.svg";
import loans from "./static/images/sidebarIcons/socially.svg";
import Lock from "./static/images/icons/lock.svg";
import defaultImg from "./static/images/icons/app_placeholder.png";

import hyfi from "./static/images/templateLogos/hyfi.svg";
import wealth from "./static/images/templateLogos/wealth.svg";
import otcDesks from "./static/images/templateLogos/otcDesks.svg";
import terminals from "./static/images/templateLogos/terminals.svg";
import fundManagement from "./static/images/templateLogos/fundManagement.svg";
import nftMarketplace from "./static/images/templateLogos/nftMarketplace.svg";
import nftReward from "./static/images/templateLogos/nftReward.svg";
import defi from "./static/images/templateLogos/defi.svg";
import signals from "./static/images/templateLogos/signals.svg";
import nftrewardtemp from "./static/images/templateLogos/nftrewardtemp.svg";

import vaults_full from "./static/images/templateLogos/vaults_full.svg";
import tokenSwap_full from "./static/images/templateLogos/tokenSwap_full.svg";
import moneyMarkets_full from "./static/images/templateLogos/moneyMarkets_full.svg";
import affiliate_full from "./static/images/templateLogos/affiliate_full.svg";
import bondIssuance_full from "./static/images/templateLogos/bondIssuance_full.svg";

import bondMarkets_full from "./static/images/templateLogos/bondMarkets_full.svg";
import portfolioAi_full from "./static/images/templateLogos/portfolioAi_full.svg";
import shareTokenIssuance_full from "./static/images/templateLogos/shareTokenIssuance_full.svg";
import shareTokenMarket_full from "./static/images/templateLogos/shareTokenMarket_full.svg";
import fundsCoinIssuance_full from "./static/images/templateLogos/fundsCoinIssuance_full.svg";
import fundCoinMarketplace_full from "./static/images/templateLogos/fundCoinMarketplace_full.svg";
import indexFundsIssuance_full from "./static/images/templateLogos/indexFundsIssuance_full.svg";
import indexFundsMarketplace_full from "./static/images/templateLogos/indexFundsMarketplace_full.svg";

import { ReactComponent as Collapse_img } from "./static/images/icons/collapse.svg";
import { ReactComponent as Collapse1_img } from "./static/images/icons/collapse1.svg";
import BusinessOverview from "./pages/SplashPage/Templates/SpecificPage/BusinessOverview";
import SecondSection from "./pages/SplashPage/Templates/SpecificPage/SecondSection";

//cPage/Component Imports
// import Elements from "./pages/Elements";
import SplashPage from "./pages/SplashPage";
import axios from "axios";
import Templates from "./pages/SplashPage/Templates";
import IndividualProduct from "./pages/SplashPage/IndividualProduct/individualProduct";
import Starfish from "./pages/SplashPage/Starfish";
import PartnerShips from "./pages/SplashPage/partnershipsPage";
import CaseStudies from "./pages/SplashPage/caseStudiesPage/caseStudies";
// import Products from "./pages/SplashPage/Products/products";
import ProductMain from "./pages/SplashPage/productInnercomp/productMain";
import Thecomapny from "./pages/SplashPage/Company/theComapny";
import EnpHome from "./entrepreneursPages/enpHomePage/enpHome";
import Whitelabel from "./pages/SplashPage/About/WhiteLabel";
import Tracker from "./pages/Tracker";
import FaceProduct from "./pages/SplashPage/About/FaceProducts";
import AOS from "aos";
import "aos/dist/aos.css";
import ComingSoon from "./pages/ComingSoon";
import Pricing from './pages/SplashPage/Priceing/Priceing'
import Aboutus from "./pages/SplashPage/DupicateCompanies/theComapny"

import About from './pages/SplashPage/About'
function App() {
  const [showabout, setshowabout] = useState("");
  const [loginData, setLoginData] = useState(null);
  const [login, setLogin] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [selectedApp, setSelectedApp] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [banker, setBanker] = useState(null);
  const [bankerEmail, setBankerEmail] = useState("");
  const [bankerTag, setBankerTag] = useState("");
  const [allBankers, setAllBankers] = useState([]);
  const [allCoins, setAllCoins] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const [selectedFilter1, setSelectedFilter1] = useState(null);
  const [selectedFilter2, setSelectedFilter2] = useState("");
  const [selectedFilter21, setSelectedFilter21] = useState("");
  const [filter1, setFilter1] = useState(false);
  const [filter2, setFilter2] = useState(false);
  const [customerEmailFilter, setCustomerEmailFilter] = useState(null);
  const [openCoinFilter, setOpenCoinFilter] = useState(false);

  const [globalMenuAdd, setGlobalMenuAdd] = useState(false);
  const [productmainMenu, setproductmainMenu] = useState("Digital Bank");
  const [currentitem, setcurrentitem] = useState("one");
  const [selectedpair, setselectedpair] = useState("");
  const [userque, setuserque] = useState(0);

  const [showDraw, setShowDraw] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedSplashCoin, setSelectedSplashCoin] = useState(null);
  const [ischeck, setischeck] = useState(false);
  const [mobdescribe, setmobdescribe] = useState(true);
  const [pin, setPin] = useState("");
  const [selectedfpnav, setselectedfpnav] = useState(
    "Proprietary Infrastructure"
  );
  const [selectedTemplate, setSelectedTemplate] = useState({
    logo: hyfi,
    templateicon: hyfi,
    name: "HyFi",
    desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
    cards: [
      { logo: vaults_full },
      { logo: tokenSwap_full },
      { logo: moneyMarkets_full },
      { logo: affiliate_full },
      { logo: bondIssuance_full },
    ],
  });
  const [selectedTemplateMenu, setSelectedTemplateMenu] = useState({
    logo: hyfi,
    templateicon: hyfi,
    name: "HyFi",
    desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
    cards: [
      { logo: vaults_full },
      { logo: tokenSwap_full },
      { logo: moneyMarkets_full },
      { logo: affiliate_full },
      { logo: bondIssuance_full },
    ],
  });

  const [collapsedSideBar, setCollapsedSideBar] = useState(false);
  const [selectNav, setSelectNav] = useState("about");
  const [enpselectNav, setenpselectNav] = useState("create");
  const [prevnav, setprevnav] = useState("about");
  const [selectedProduct, setselectedProduct] = useState({});
  const [selectedMenu1, setSelectedMenu1] = useState("Digital Bank");
  const [selectedcontactquery, setselectedcontactquery] = useState("");
  const [companynav, setcompanynav] = useState("company");
  const [mobsize, setmobsize] = useState(0);
  const [showMenu, setshowMenu] = useState("");
  const [navHover, setnavHover] = useState("");

  const [homeDarrow, sethomeDarrow] = useState(false);

  const templateList = [
    {
      logo: hyfi,
      templateicon: hyfi,
      name: "HyFi",
      desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
      cards: [
        { logo: vaults_full },
        { logo: tokenSwap_full },
        { logo: moneyMarkets_full },
        { logo: affiliate_full },
        { logo: bondIssuance_full },
      ],
    },
    {
      logo: wealth,
      templateicon: wealth,
      name: "Wealth",
      desc: "The Wealth Management template offers a total portfolio management system to your users which allows them to track their digital net-worth while giving them access to fixed income, equity, and crypto markets.",
      cards: [
        { logo: vaults_full },
        { logo: tokenSwap_full },
        { logo: moneyMarkets_full },
        { logo: affiliate_full },
        { logo: bondIssuance_full },
        { logo: bondMarkets_full },
        { logo: portfolioAi_full },
        { logo: shareTokenIssuance_full },
        { logo: shareTokenMarket_full },
        { logo: fundsCoinIssuance_full },
        { logo: fundCoinMarketplace_full },
        { logo: indexFundsIssuance_full },
        { logo: indexFundsMarketplace_full },
      ],
    },
    {
      logo: otcDesks,
      templateicon: otcDesks,
      name: "OTCDesk",
      desc: "The OTCDesk is a standard fiat to cryptocurrency exchange application which empowers your users to fund their accounts with their local currency and swap into digital assets.",
      cards: [],
    },
    {
      logo: terminals,
      templateicon: terminals,
      name: "Terminal",
      desc: "Terminals is an advanced cryptocurrency exchange platform which allows your users to high frequency trading across hundreds of digital assets. ",
      cards: [],
    },
    {
      logo: nftReward,
      templateicon: nftrewardtemp,
      name: "NFTReward",
      desc: "The NFTReward template allows you to create an exclusive application for your NFT whereby users can earn rewards for holding your NFT.",
      cards: [],
    },
    {
      logo: fundManagement,
      templateicon: fundManagement,
      name: "Funds",
      desc: "The Fund Management template is an retail investment platform which allows users to grow their assets by investing and interacting with your specific FundCoin.",
      cards: [],
    },
    {
      logo: nftMarketplace,
      templateicon: nftMarketplace,
      name: "NFT Markets",
      desc: "The NFT Marketplace allows enables NFT creators to list their NFT's on your platform as well as providing buyers with a secondary resale market.",
      cards: [],
    },
    {
      logo: defi,
      templateicon: defi,
      name: "Defi Markets",
      desc: "DefiMarkets is a decentralized routing engine that allows your users to manage their DEFI lending operations accross different protocols.",
      cards: [],
    },
    {
      logo: signals,
      templateicon: signals,
      name: "Signals",
      desc: "The Signals template allows you to launch your very own financial education platform where you can offer courses, analysis and market signals.",
      cards: [],
    },
  ];

  const [selectedCoinSplash, setSelectedCoinSplash] = useState({
    coinName: "US Dollar",
    coinSymbol: "USD",
    symbol: "$",
    coinImage:
      "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png",
    type: "fiat",
  });

  const FormatNumber = (value, prec) => {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: prec,
      minimumFractionDigits: prec,
    }).format(isNaN(value) ? 0 : value);
  };

  // useEffect(() => {
  //   axios
  //     .get(`https://comms.globalxchange.io/coin/vault/get/all/coins`)
  //     .then((res) => {
  //       if (res.data.status) {
  //         setAllCoins(res.data.coins);
  //         setSelectedCoin({
  //           coinImage:
  //             "https://apimachine-s3.s3.us-east-2.amazonaws.com/coinImages/dollar.png",
  //           coinName: "US Dollar",
  //           coinSymbol: "USD",
  //           market_cap: 0,
  //           symbol: "$",
  //           type: "fiat",
  //           usd_price: 1,
  //           volume_24hr: 0,
  //           _24hrchange: 0,
  //           _id: "5f21042d0562332558c93180",
  //         });
  //       }
  //     });
  // }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setmobsize(window.innerWidth);
      if (window.innerWidth > 900) {
        setCollapsedSideBar(false);
        setmobdescribe(true);
        setuserque(0);
      }
 
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [mobsize]);

  useEffect(() => {
    AOS.init();
  }, []);

  const value = {
    showabout,
    setshowabout,
    collapse,
    setCollapse,
    selectedApp,
    setSelectedApp,
    modalOpen,
    setModalOpen,
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    loginData,
    setLoginData,
    bankerTag,
    setBankerTag,
    login,
    setLogin,
    Lock,
    Collapse_img,
    Collapse1_img,
    defaultImg,
    allBankers,
    setAllBankers,
    bankerEmail,
    setBankerEmail,
    allCoins,
    setAllCoins,
    selectedCoin,
    setSelectedCoin,
    selectedFilter1,
    setSelectedFilter1,
    selectedFilter2,
    setSelectedFilter2,
    selectedFilter21,
    setSelectedFilter21,
    filter1,
    setFilter1,
    filter2,
    setFilter2,
    customerEmailFilter,
    setCustomerEmailFilter,
    openCoinFilter,
    setOpenCoinFilter,
    globalMenuAdd,
    setGlobalMenuAdd,
    FormatNumber,
    selectedCoinSplash,
    setSelectedCoinSplash,
    showDraw,
    setShowDraw,
    selectedMenu,
    setSelectedMenu,
    selectedSplashCoin,
    setSelectedSplashCoin,
    selectedTemplate,
    setSelectedTemplate,
    templateList,
    selectedTemplateMenu,
    setSelectedTemplateMenu,
    collapsedSideBar,
    setCollapsedSideBar,
    selectNav,
    setSelectNav,
    prevnav,
    setprevnav,
    selectedProduct,
    setselectedProduct,
    selectedMenu1,
    setSelectedMenu1,
    selectedcontactquery,
    setselectedcontactquery,
    mobsize,
    setmobsize,
    showMenu,
    setshowMenu,
    productmainMenu,
    setproductmainMenu,
    currentitem,
    setcurrentitem,
    companynav,
    setcompanynav,
    selectedfpnav,
    setselectedfpnav,
    selectedpair,
    setselectedpair,
    ischeck,
    setischeck,
    mobdescribe,
    setmobdescribe,
    userque,
    setuserque,
    enpselectNav,
    setenpselectNav,
    navHover,
    setnavHover,
    homeDarrow,
    sethomeDarrow,
    pin,
    setPin,
  };
  // const navigate=useNavigate()
  return (
    <div>
      <GlobalContex.Provider value={value}>
        <BrowserRouter>
          <Routes>
            {/* {window.innerWidth < 900 ? (
              <Route path="/" element={<Navigate to="/error-page" />} />
            ) : (
              
            )} */}

            {/* <Route path="/hi" element={<EnpHome />} /> */}

            <Route exact path="/" element={<About />} />

            <Route path="/casestudies" element={<CaseStudies />} />
            <Route path="/templates" element={<Templates />}></Route>
            <Route path="/sta" element={<Starfish />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/aboutus" element={<Aboutus />} />
            
            
            {/* <Route exact path="templates/:id" element={<Templates />}>
              <Route path="*" element={<SecondSection />} />
            </Route> */}
            <Route path="/products" element={<ProductMain />} />
            <Route path="/products/:id" element={<ProductMain />} />
            {/* <Route path='/products/:id/:ele' element={ <IndividualProduct />} /> */}
            <Route path="/thecompany" element={<Thecomapny />} />
            <Route path="/thecompany/:id" element={<Thecomapny />} />
            <Route path="/tracker" element={<Tracker />} />
            <Route path="/whitelabeltemplates" element={<Whitelabel />} />
            <Route path="/userfacingproducts" element={<FaceProduct />} />
    
          </Routes>
        </BrowserRouter>
      </GlobalContex.Provider>
    </div>
  );
}

export default App;
