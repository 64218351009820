import React, {useState, useContext} from 'react';
import { GlobalContex } from "../../globalContex";
import './newtoggle.scss';


const Newtoggle = () => {
  const {ischeck, setischeck, } = useContext(GlobalContex);
  return (
    <div>
        <input type='checkbox' id='c3d' onClick={() => {setischeck(!ischeck)
    console.log(ischeck)}}/>
        <label for='c3d'>light</label>
    </div>
  )
}

export default Newtoggle;