import React, { useEffect, useRef, useState, useContext } from "react";
import { GlobalContex } from "../../../globalContex";
import { Link, useNavigate } from "react-router-dom";
import SplashHeader from "../../../globalComponents/SplashHeader";
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";
import { companyMenu } from "../alldata";
import ContactUs from "../ContactusPage";
import gxicon from "../../../static/images/company/gxicon.svg";
import main from "../../../static/images/company/main.svg";
import shorupanimg from "../../../static/images/company/shorupanimg.svg";
import bluetick from "../../../static/images/company/bluetick.svg";
import "./theCompany.scss";
import OldNavmenu from "../../../globalComponents/oldNavMenu/oldNavMenu";
import NewNavbar from "../../../globalComponents/newNav/newNavbar";
import Navmenu from "../../../globalComponents/navMenus/navmenu";
const Thecomapny = () => {
  const {
    collapsedSideBar,
    setselectedcontactquery,
    showDraw,
    setShowDraw,
    companynav,
    setcompanynav,
    mobsize,
    pin,
  } = useContext(GlobalContex);

  let navigate = useNavigate();
  useEffect(() => {
    // console.log(pin, "kjegdkjweded");
    // if (pin !== "4141") {
    //   navigate("/");
    // }
  }, []);

  return (
    <div>
      <div
        onMouseDown={(e) => setShowDraw(false)}
        className={showDraw ? "overlayClose" : ""}
      >
        {" "}
      </div>
      {/* <SplashHeader /> */}
      <>
        <div
        // style={{
        //   display: showallnav ? "" : mobdescribe === false ? "" : "none",
        // }}
        >
          {mobsize > 900 ? (
            <>
              <NewNavbar />
              <Navmenu />
              {/* <SplashHeader />
            <OldNavmenu /> */}
            </>
          ) : (
            <SplashHeader />
          )}
        </div>
      </>
      <div className="landingPage">
        <div className="pageWrap">
          <>
            {collapsedSideBar ? (
              <Mobmenu />
            ) : (
              <>
                <div className="companymaster">
                  
                  <div>
                    {companynav === "company" ? (
                      <div className="company-main">
                        <div className="company-leftsidebox">
                          <div className="left-1">
                            <div className="comp-mainhead">The Company</div>
                            <div className="comp-subhead">
                              The
                              <span>
                                <img className="gx-image" src={gxicon} alt="" />
                              </span>
                              GX Ecosystem
                            </div>
                          </div>
                          <div className="left-2">
                            <div>
                              MarketsVerse is the flagship component of Global X
                              Change, the world's first operating system for
                              crypto businesses.
                            </div>
                            <br />
                            <div>
                              GX was started in 2019 by Shorupan Pirakaspathy in
                              Toronto, Canada, as the world's first exchange
                              which allowed users to become cryptocurrency
                              brokers. GXBrokers, as they were called, could
                              control and earn the exchange fees that their
                              customers paid on each trade. The success of this
                              innovation led to the emergence of what would
                              become GX's B2B2C ecosystem.
                            </div>
                            <br />
                            <div>
                              In 2020, GX white-labelled its flagship crypto
                              exchange to several top GXBrokers who wanted to
                              launch their own branded application. The tech
                              stack originally built for GXbrokers lent itself
                              perfectly to white labelled "app owners.". Since
                              then, GX has transitioned its Broker products into
                              a complete B2B ecosystem in which MarketsVerse.com
                              is the leading offering to entrepreneurs and
                              companies.
                            </div>
                          </div>
                        </div>
                        <div className="company-rightsidebox">
                          {companyMenu.map((each, id) => {
                            return (
                              <div
                                className="each-compmenu"
                                style={{ background: each.backgroundc }}
                                key={id}
                              >
                                <div className="each-data">
                                  <div className="each-title">{each.title}</div>
                                  {/* <div className="each-desc">{each.desc}</div> */}
                                </div>
                                <div className="each-icon">
                                  <img src={each.icon} alt="" />
                                </div>
                                {/* <div className="each-arrow">
                                            <img src={each.arrow} alt="" />
                                        </div> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : companynav === "founder" ? (
                      <div className="founder-main">
                        {/* <div className="founder-img-box">
                         <div className="founder-each-img" style={{backgroundImage: `url(${main})`}}> </div>
                    </div> */}
                        <div className="founder-text-box">
                          <div className="founder-title">The Founder & CEO</div>
                          <div className="founder-subtitle">
                            Shorupan Pirakaspathy
                          </div>
                          <div className="founder-desc">
                            Shorupan entered the cryptocurrency space in 2017
                            with the formation of the Nvest.Group, a Canadian
                            fin-tech holdings company with the stated aim of
                            incubating crypto startups. In 2019 the Nvest.Group
                            gave birth to Global X Change, and Shorupan has
                            served as CEO of the company throughout its tenure.
                            Overseeing the development of the initial GXBroker
                            business and initiating the transition of GX into
                            what you see today as the world's leading operating
                            system for crypto businesses.
                          </div>
                          <div className="founder-head">Today GX Has Over</div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Over 12,000 Users In 20+ Countries
                          </div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Over 100 Employees With Operations In Canada, UK &
                            India
                          </div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Over 7 Independently Running Whitelabeled
                            Applications
                          </div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Built Over 50 Crypto Enabled Applications
                          </div>
                        </div>

                        <div className="founder-img-box">
                          {/* <div className="founder-each-img" style={{backgroundImage: `url(${main})`}}> </div> */}
                          <img className="founder-full-img" src={main} alt="" />
                        </div>
                        {/* <img className="founder-full-img" src={shorupanimg} alt="" /> */}
                      </div>
                    ) : companynav === "contactus" ? (
                      <div className="contact-main">
                        <ContactUs />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mob-companymaster">
                  <div
                    className="mob-each-company-nav"
                    onClick={() =>
                      companynav === "company"
                        ? setcompanynav("")
                        : setcompanynav("company")
                    }
                  >
                    <div
                      style={{
                        padding: "35px 0px",
                        fontSize: companynav === "company" ? "25px" : "",
                        fontWeight: companynav === "company" ? "800" : "",
                      }}
                    >
                      The Company
                    </div>
                    {companynav === "company" ? (
                      <div className="company-main">
                        <div className="company-leftsidebox">
                          <div className="left-1">
                            {/* <div className="comp-mainhead">The Company</div> */}
                            <div className="comp-subhead">
                              The
                              <span>
                                <img className="gx-image" src={gxicon} alt="" />
                              </span>
                              GX Ecosystem
                            </div>
                          </div>
                          <div className="left-2">
                            <br />
                            <div>
                              MarketsVerse is the flagship component of Global X
                              Change, the world's first operating system for
                              crypto businesses.
                            </div>
                            <br />
                            <div>
                              GX was started in 2019 by Shorupan Pirakaspathy in
                              Toronto, Canada, as the world's first exchange
                              which allowed users to become cryptocurrency
                              brokers. GXBrokers, as they were called, could
                              control and earn the exchange fees that their
                              customers paid on each trade. The success of this
                              innovation led to the emergence of what would
                              become GX's B2B2C ecosystem.
                            </div>
                            <br />
                            <div>
                              In 2020, GX white-labelled its flagship crypto
                              exchange to several top GXBrokers who wanted to
                              launch their own branded application. The tech
                              stack originally built for GXbrokers lent itself
                              perfectly to white labelled "app owners.". Since
                              then, GX has transitioned its Broker products into
                              a complete B2B ecosystem in which MarketsVerse.com
                              is the leading offering to entrepreneurs and
                              companies.
                            </div>
                            <br />
                          </div>
                        </div>
                        <div className="company-rightsidebox">
                          {companyMenu.map((each, id) => {
                            return (
                              <div
                                className="each-compmenu"
                                style={{ background: each.backgroundc }}
                                key={id}
                              >
                                <div className="each-data">
                                  <div className="each-title">{each.title}</div>
                                  {/* <div className="each-desc">{each.desc}</div> */}
                                </div>
                                <div className="each-icon">
                                  <img src={each.icon} alt="" />
                                </div>
                                {/* <div className="each-arrow">
                                            <img src={each.arrow} alt="" />
                                        </div> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="mob-each-company-nav"
                    onClick={() => setcompanynav("founder")}
                  >
                    <div
                      style={{
                        padding: "35px 0px",
                        fontSize: companynav === "founder" ? "25px" : "",
                        fontWeight: companynav === "founder" ? "800" : "",
                      }}
                    >
                      The Founder & CEO
                    </div>
                    {companynav === "founder" ? (
                      <div className="founder-main">
                        {/* <div className="founder-img-box">
                         <div className="founder-each-img" style={{backgroundImage: `url(${main})`}}> </div>
                    </div> */}
                        <div className="founder-text-box">
                          {/* <div className="founder-title">The Founder & CEO</div> */}
                          <div className="founder-subtitle">
                            Shorupan Pirakaspathy
                          </div>
                          <div className="founder-desc">
                            Shorupan entered the cryptocurrency space in 2017
                            with the formation of the Nvest.Group, a Canadian
                            fin-tech holdings company with the stated aim of
                            incubating crypto startups. In 2019 the Nvest.Group
                            gave birth to Global X Change, and Shorupan has
                            served as CEO of the company throughout its tenure.
                            Overseeing the development of the initial GXBroker
                            business and initiating the transition of GX into
                            what you see today as the world's leading operating
                            system for crypto businesses.
                          </div>
                          <div className="founder-img-box">
                            <img
                              className="founder-full-img"
                              src={main}
                              alt=""
                            />
                          </div>
                          <div className="founder-head">Today GX Has Over</div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Over 12,000 Users In 20+ Countries
                          </div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Over 100 Employees With Operations In Canada, UK &
                            India
                          </div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Over 7 Independently Running Whitelabeled
                            Applications
                          </div>
                          <div className="founder-data">
                            <span style={{ marginRight: "10px" }}>
                              <img src={bluetick} alt="" />
                            </span>
                            Built Over 50 Crypto Enabled Applications
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="mob-each-company-nav"
                    style={{ opacity: "0.25", cursor: "not-allowed" }}
                    onClick={
                      () => ""
                      //  {setcompanynav("contactus"); setselectedcontactquery("");}
                    }
                  >
                    <div
                      style={{
                        padding: "35px 0px",
                        fontSize: companynav === "contactus" ? "27px" : "",
                        fontWeight: companynav === "contactus" ? "800" : "",
                      }}
                    >
                      Contact Us
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Thecomapny;
