import React ,{useState}from "react";
import "./price.scss";
import NewNavbar from "../../../globalComponents/newNav/newNavbar";
import Navmenu from "../../../globalComponents/navMenus/navmenu";
import Switch from "react-switch";
export default function Priceing() {

  const [checked,setchecked]=useState(true)
const  handleChange=(checked) =>{
  setchecked(checked)
}

  return (
    <div className="PricingScroll">
      <NewNavbar />
      <Navmenu />
      <div className="priceingSecton">
        <div className="innerpriceseactiom">
          <h1>Choose Your Template & Plan. Get Started</h1>

          <h2>
            Transparent, entrepreneur-friendly pricing to launch your own native
            apps.
          </h2>

          <div className="annual">Annual 
            
          
          <Switch
            checked={checked}
            onChange={handleChange}
            // onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={30}
            uncheckedIcon={true}
            checkedIcon={true}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />


            
            
            Monthly</div>
        </div>
        <div className="greySection">
        <div className="boxSection">
          <div className="innerbox">
            <div className="list">
              <div className="item">
                <div className="divfeacture">Features</div>
              </div>
              <div className="customsectiionlist">
                <div className="customitem">
                  <h3>Starter</h3>
                  <h5>$1200/mo</h5>
                  <label>Get Started</label>
                </div>
                <div className="customitem">
                  <h3>Scaler</h3>
                  <h5>$2400/mo</h5>
                  <label>Get Started</label>
                </div>
                <div className="customitem">
                  <h3>Enterprise</h3>
                  <h5>Contact Us</h5>
                  <label
                  style={{
                    background:"#16171A"
                  }}
                  >Get Started</label>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomSection">
            <div className="listcustom">
              <div className="itemdulicate">
                <div className="text">Your own iOS, Android & Web apps</div>
              </div>
              <div className="customsectiionlist" >
              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                >
                  <path
                    d="M11.3482 0.353699C5.17899 0.353699 0.1604 5.37229 0.1604 11.5415C0.1604 17.7107 5.17899 22.7293 11.3482 22.7293C17.5174 22.7293 22.536 17.7107 22.536 11.5415C22.536 5.37229 17.5174 0.353699 11.3482 0.353699Z"
                    fill="#FF5752"
                  />
                  <path
                    d="M12.3531 11.7487L15.7907 8.31111C16.0112 8.09067 16.0112 7.73373 15.7907 7.51324C15.5702 7.29273 15.2133 7.29268 14.9928 7.51322L11.5552 10.9508L8.1176 7.51324C7.89717 7.29275 7.54021 7.29268 7.31972 7.51321C7.09921 7.7337 7.0992 8.09058 7.31972 8.31109L10.7573 11.7487L7.31971 15.1863C7.09971 15.4063 7.09971 15.7642 7.31971 15.9842C7.54018 16.2047 7.8971 16.2047 8.11759 15.9842L11.5552 12.5466L14.9928 15.9841C15.2132 16.2046 15.5701 16.2047 15.7906 15.9842C16.0111 15.7637 16.0112 15.4068 15.7906 15.1862L12.3531 11.7487Z"
                    fill="white"
                  />
                  <path
                    d="M12.3529 11.7486L15.7905 8.31097C16.011 8.09053 16.0111 7.7336 15.7905 7.5131C15.57 7.2926 15.2131 7.29254 14.9926 7.51309L11.555 10.9507V12.5465L14.9926 15.984C15.213 16.2046 15.57 16.2046 15.7905 15.9841C16.011 15.7636 16.011 15.4067 15.7905 15.1862L12.3529 11.7486Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M12.1217 0.353699C5.95255 0.353699 0.93396 5.37229 0.93396 11.5415C0.93396 17.7107 5.95255 22.7293 12.1217 22.7293C18.2909 22.7293 23.3095 17.7107 23.3095 11.5415C23.3095 5.37229 18.2909 0.353699 12.1217 0.353699Z"
                    fill="url(#paint0_linear_1207_1315)"
                  />
                  <path
                    d="M17.7922 9.17065L11.7321 15.2306C11.5503 15.4124 11.3116 15.5039 11.073 15.5039C10.8343 15.5039 10.5957 15.4124 10.4138 15.2306L7.38388 12.2006C7.01924 11.8361 7.01924 11.2469 7.38388 10.8824C7.74835 10.5177 8.33747 10.5177 8.70212 10.8824L11.073 13.2532L16.4739 7.85241C16.8384 7.48777 17.4275 7.48777 17.7922 7.85241C18.1567 8.21688 18.1567 8.80601 17.7922 9.17065Z"
                    fill="#FAFAFA"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1207_1315"
                      x1="21.7113"
                      y1="20.5317"
                      x2="-10.4392"
                      y2="17.6533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#277EFB" />
                      <stop offset="1" stop-color="#B580F2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M12.1217 0.353699C5.95255 0.353699 0.93396 5.37229 0.93396 11.5415C0.93396 17.7107 5.95255 22.7293 12.1217 22.7293C18.2909 22.7293 23.3095 17.7107 23.3095 11.5415C23.3095 5.37229 18.2909 0.353699 12.1217 0.353699Z"
                    fill="url(#paint0_linear_1207_1315)"
                  />
                  <path
                    d="M17.7922 9.17065L11.7321 15.2306C11.5503 15.4124 11.3116 15.5039 11.073 15.5039C10.8343 15.5039 10.5957 15.4124 10.4138 15.2306L7.38388 12.2006C7.01924 11.8361 7.01924 11.2469 7.38388 10.8824C7.74835 10.5177 8.33747 10.5177 8.70212 10.8824L11.073 13.2532L16.4739 7.85241C16.8384 7.48777 17.4275 7.48777 17.7922 7.85241C18.1567 8.21688 18.1567 8.80601 17.7922 9.17065Z"
                    fill="#FAFAFA"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1207_1315"
                      x1="21.7113"
                      y1="20.5317"
                      x2="-10.4392"
                      y2="17.6533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#277EFB" />
                      <stop offset="1" stop-color="#B580F2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              </div>
            </div>


            <div className="listcustom">
              <div className="itemdulicate">
                <div className="text">Your own iOS, Android & Web apps</div>
              </div>
              <div className="customsectiionlist" >
              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                >
                  <path
                    d="M11.3482 0.353699C5.17899 0.353699 0.1604 5.37229 0.1604 11.5415C0.1604 17.7107 5.17899 22.7293 11.3482 22.7293C17.5174 22.7293 22.536 17.7107 22.536 11.5415C22.536 5.37229 17.5174 0.353699 11.3482 0.353699Z"
                    fill="#FF5752"
                  />
                  <path
                    d="M12.3531 11.7487L15.7907 8.31111C16.0112 8.09067 16.0112 7.73373 15.7907 7.51324C15.5702 7.29273 15.2133 7.29268 14.9928 7.51322L11.5552 10.9508L8.1176 7.51324C7.89717 7.29275 7.54021 7.29268 7.31972 7.51321C7.09921 7.7337 7.0992 8.09058 7.31972 8.31109L10.7573 11.7487L7.31971 15.1863C7.09971 15.4063 7.09971 15.7642 7.31971 15.9842C7.54018 16.2047 7.8971 16.2047 8.11759 15.9842L11.5552 12.5466L14.9928 15.9841C15.2132 16.2046 15.5701 16.2047 15.7906 15.9842C16.0111 15.7637 16.0112 15.4068 15.7906 15.1862L12.3531 11.7487Z"
                    fill="white"
                  />
                  <path
                    d="M12.3529 11.7486L15.7905 8.31097C16.011 8.09053 16.0111 7.7336 15.7905 7.5131C15.57 7.2926 15.2131 7.29254 14.9926 7.51309L11.555 10.9507V12.5465L14.9926 15.984C15.213 16.2046 15.57 16.2046 15.7905 15.9841C16.011 15.7636 16.011 15.4067 15.7905 15.1862L12.3529 11.7486Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M12.1217 0.353699C5.95255 0.353699 0.93396 5.37229 0.93396 11.5415C0.93396 17.7107 5.95255 22.7293 12.1217 22.7293C18.2909 22.7293 23.3095 17.7107 23.3095 11.5415C23.3095 5.37229 18.2909 0.353699 12.1217 0.353699Z"
                    fill="url(#paint0_linear_1207_1315)"
                  />
                  <path
                    d="M17.7922 9.17065L11.7321 15.2306C11.5503 15.4124 11.3116 15.5039 11.073 15.5039C10.8343 15.5039 10.5957 15.4124 10.4138 15.2306L7.38388 12.2006C7.01924 11.8361 7.01924 11.2469 7.38388 10.8824C7.74835 10.5177 8.33747 10.5177 8.70212 10.8824L11.073 13.2532L16.4739 7.85241C16.8384 7.48777 17.4275 7.48777 17.7922 7.85241C18.1567 8.21688 18.1567 8.80601 17.7922 9.17065Z"
                    fill="#FAFAFA"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1207_1315"
                      x1="21.7113"
                      y1="20.5317"
                      x2="-10.4392"
                      y2="17.6533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#277EFB" />
                      <stop offset="1" stop-color="#B580F2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M12.1217 0.353699C5.95255 0.353699 0.93396 5.37229 0.93396 11.5415C0.93396 17.7107 5.95255 22.7293 12.1217 22.7293C18.2909 22.7293 23.3095 17.7107 23.3095 11.5415C23.3095 5.37229 18.2909 0.353699 12.1217 0.353699Z"
                    fill="url(#paint0_linear_1207_1315)"
                  />
                  <path
                    d="M17.7922 9.17065L11.7321 15.2306C11.5503 15.4124 11.3116 15.5039 11.073 15.5039C10.8343 15.5039 10.5957 15.4124 10.4138 15.2306L7.38388 12.2006C7.01924 11.8361 7.01924 11.2469 7.38388 10.8824C7.74835 10.5177 8.33747 10.5177 8.70212 10.8824L11.073 13.2532L16.4739 7.85241C16.8384 7.48777 17.4275 7.48777 17.7922 7.85241C18.1567 8.21688 18.1567 8.80601 17.7922 9.17065Z"
                    fill="#FAFAFA"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1207_1315"
                      x1="21.7113"
                      y1="20.5317"
                      x2="-10.4392"
                      y2="17.6533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#277EFB" />
                      <stop offset="1" stop-color="#B580F2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              </div>
            </div>

            <div className="listcustom">
              <div className="itemdulicate">
                <div className="text">Your own iOS, Android & Web apps</div>
              </div>
              <div className="customsectiionlist" >
              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                >
                  <path
                    d="M11.3482 0.353699C5.17899 0.353699 0.1604 5.37229 0.1604 11.5415C0.1604 17.7107 5.17899 22.7293 11.3482 22.7293C17.5174 22.7293 22.536 17.7107 22.536 11.5415C22.536 5.37229 17.5174 0.353699 11.3482 0.353699Z"
                    fill="#FF5752"
                  />
                  <path
                    d="M12.3531 11.7487L15.7907 8.31111C16.0112 8.09067 16.0112 7.73373 15.7907 7.51324C15.5702 7.29273 15.2133 7.29268 14.9928 7.51322L11.5552 10.9508L8.1176 7.51324C7.89717 7.29275 7.54021 7.29268 7.31972 7.51321C7.09921 7.7337 7.0992 8.09058 7.31972 8.31109L10.7573 11.7487L7.31971 15.1863C7.09971 15.4063 7.09971 15.7642 7.31971 15.9842C7.54018 16.2047 7.8971 16.2047 8.11759 15.9842L11.5552 12.5466L14.9928 15.9841C15.2132 16.2046 15.5701 16.2047 15.7906 15.9842C16.0111 15.7637 16.0112 15.4068 15.7906 15.1862L12.3531 11.7487Z"
                    fill="white"
                  />
                  <path
                    d="M12.3529 11.7486L15.7905 8.31097C16.011 8.09053 16.0111 7.7336 15.7905 7.5131C15.57 7.2926 15.2131 7.29254 14.9926 7.51309L11.555 10.9507V12.5465L14.9926 15.984C15.213 16.2046 15.57 16.2046 15.7905 15.9841C16.011 15.7636 16.011 15.4067 15.7905 15.1862L12.3529 11.7486Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M12.1217 0.353699C5.95255 0.353699 0.93396 5.37229 0.93396 11.5415C0.93396 17.7107 5.95255 22.7293 12.1217 22.7293C18.2909 22.7293 23.3095 17.7107 23.3095 11.5415C23.3095 5.37229 18.2909 0.353699 12.1217 0.353699Z"
                    fill="url(#paint0_linear_1207_1315)"
                  />
                  <path
                    d="M17.7922 9.17065L11.7321 15.2306C11.5503 15.4124 11.3116 15.5039 11.073 15.5039C10.8343 15.5039 10.5957 15.4124 10.4138 15.2306L7.38388 12.2006C7.01924 11.8361 7.01924 11.2469 7.38388 10.8824C7.74835 10.5177 8.33747 10.5177 8.70212 10.8824L11.073 13.2532L16.4739 7.85241C16.8384 7.48777 17.4275 7.48777 17.7922 7.85241C18.1567 8.21688 18.1567 8.80601 17.7922 9.17065Z"
                    fill="#FAFAFA"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1207_1315"
                      x1="21.7113"
                      y1="20.5317"
                      x2="-10.4392"
                      y2="17.6533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#277EFB" />
                      <stop offset="1" stop-color="#B580F2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

              <div className="customitemlist">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                >
                  <path
                    d="M12.1217 0.353699C5.95255 0.353699 0.93396 5.37229 0.93396 11.5415C0.93396 17.7107 5.95255 22.7293 12.1217 22.7293C18.2909 22.7293 23.3095 17.7107 23.3095 11.5415C23.3095 5.37229 18.2909 0.353699 12.1217 0.353699Z"
                    fill="url(#paint0_linear_1207_1315)"
                  />
                  <path
                    d="M17.7922 9.17065L11.7321 15.2306C11.5503 15.4124 11.3116 15.5039 11.073 15.5039C10.8343 15.5039 10.5957 15.4124 10.4138 15.2306L7.38388 12.2006C7.01924 11.8361 7.01924 11.2469 7.38388 10.8824C7.74835 10.5177 8.33747 10.5177 8.70212 10.8824L11.073 13.2532L16.4739 7.85241C16.8384 7.48777 17.4275 7.48777 17.7922 7.85241C18.1567 8.21688 18.1567 8.80601 17.7922 9.17065Z"
                    fill="#FAFAFA"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1207_1315"
                      x1="21.7113"
                      y1="20.5317"
                      x2="-10.4392"
                      y2="17.6533"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#277EFB" />
                      <stop offset="1" stop-color="#B580F2" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
