import React, { useEffect, useState } from "react";
import "./tacker.scss";
import toparrow from "../../static/images/toparrow.svg";
import { useNavigate } from "react-router-dom";
import logo from "../../static/logo.svg";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import CountUp from "react-countup";
export default function Index() {
  const navigate = useNavigate();
  const [menuName, setMenuName] = useState("Global");
  const [tabName, settabName] = useState("Usage");
  const [CustomerAllApps, setCustomerAllApps] = useState([]);
  const [countdata, setcountdata] = useState({});
  const [titleloader, settitleloader] = useState(true);
  const [loader, setloader] = useState(true);
  const [tableName, settableName] = useState("Products");
  const [foundercount, setfoundercount] = useState("");
  const [Allproducts, setAllproducts] = useState([]);
  const [loaderfp, setloaderfp] = useState(true);
  const [AllPurchase, setAllPurchase] = useState([]);
  const [valueName, setvalueName] = useState("Total");
  const [Bloader, setBloader] = useState(true);
  const tabs = [
    {
      name: "Usage",
    },
    {
      name: "Economy",
    },
    {
      name: "Explorer",
    },
  ];
  const year = [
    "Total",
    "Last 24Hrs",
    "30 Days",
    "90 Days",
    "YTD",
    "12 Months",
  ];
  const menu = ["Global"];

  const mS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  useEffect(() => {
    appcall();
    founder();
    appbussiness();
    axios
      .get(
        `https://comms.globalxchange.io/coin/vault/service/commissions/total/values/get`
      )
      .then((res) => {
        setcountdata(res.data);
        settitleloader(false);
      });
  }, []);

  const founder = () => {
    axios
      .get(
        `https://comms.globalxchange.io/gxb/product/get?status=active`
      )
      .then((res) => {
        setAllproducts(res.data.products);
        setfoundercount(res.data);
        setloaderfp(false);
      });
  };

  const appcall = () => {
    axios.get(`https://comms.globalxchange.io/coin/vault/user/license/new/get`).then((res) => {
      setCustomerAllApps(res.data.data);
      setloader(false);
    });
  };

  const appbussiness = () => {
    axios.get(`https://comms.globalxchange.io/coin/vault/user/license/get`).then((res) => {
      setAllPurchase(res.data.licenses);
      setBloader(false);
    });
  };

  const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
      formetedNumber = splitArray[0];
    }
    return formetedNumber;
  };

  const headertable = () => {
    switch (tableName) {
      case "Purchases":
        return (
          <div className="headerSectionTable">
            <div
              style={{
                width: "28%",
                paddingLeft: "3%",
              }}
            >
              Product
            </div>
            <div
              style={{
                width: "22%",
              }}
            >
              Customer
            </div>
            <div
              style={{
                width: "20%",
              }}
            >
              Purchase Date
            </div>
            <div
              style={{
                width: "20%",
              }}
            >
              Purchased On
            </div>
            <div
              style={{
                width: "10%",
              }}
            >
              Licenses Status
            </div>
          </div>
        );

      case "Customers":
        return (
          <div className="headerSectionTable">
            <div
              style={{
                width: "56%",
                paddingLeft: "3%",
              }}
            >
        Name
            </div>
            <div
              style={{
                width: "33%",
              }}
            >
            Products Purchased
            </div>
            <div
              style={{
                width: "11%",
              }}
            >
          Transactions
            </div>
         
          </div>
        );

      case "Products":
        return (
          <div className="headerSectionTable">
            <div
              style={{
                width: "33%",
                paddingLeft: "3%",
              }}
            >
              Name
            </div>
            <div
              style={{
                width: "37%",
              }}
            >
           Created By
            </div>
            <div
              style={{
                width: "25%",
              }}
            >
           Sold On
            </div>
            <div
              style={{
                width: "10%",
              }}
            >
              Units Sold
            </div>
          </div>
        );
    }
  };

  const swtchData = () => {
    switch (tableName) {
      case "Purchases":
        return (
          <>
            {Bloader ? (
              <>
                {[1, 2, 2, 2, 2, 2, 2, 2].map((item) => {
                  return (
                    <div className="contain-bottom">
                      <Skeleton style={{ height: "30px", width: "60px" }} />
                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "60px" }} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                 {AllPurchase?.map((item, i) => {
                  console.log(
                    " item?.date?.split('-')?.[0]",
                    item?.date?.split(",")?.[0]?.split("/")?.[0]
                  );
                  return (
                    <div className="contain-bottom-des">
                  
                      <div
                        style={{
                            width: "28%",
                            paddingRight:"3%"
                          }}
                      >

                        <div 
                        style={{
                            display:'flex',
                            alignItems:"flex-start"
                        }}
                        >

                        <h1
                        style={{
                          marginRight:"15px"
                        }}
                      >
                        {i + 1}
                      </h1>
                   <div>
                      <h1
                       style={{
                        // width: "27%",
                      }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                          }}
                          src={item?.product_icon}
                        />
                        {item?.product_name}
                      </h1>
                      <h5>{item?.sub_text}</h5>
                      </div>
                      </div>
                      </div>


                      <div
                        style={{
                            width: "22%",
                            paddingRight:"3%"
                          }}
                      >

                        <div 
                        style={{
                            display:'flex',
                            alignItems:"flex-start"
                        }}
                        >
{/* 
                        <h1
                        style={{
                          marginRight:"15px"
                        }}
                      >
                        {i + 1}
                      </h1> */}
                   <div>
                      <h1
                       style={{
                        // width: "27%",
                      }}
                      >
                        {/* <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                          }}
                          src={item?.product_icon}
                        /> */}
                        {item?.name}
                      </h1>
                      <h5>{item?.email}</h5>
                      </div>
                      </div>
                      </div>
                 

                      <h1
                        style={{
                          width: "20%",
                        }}
                      >
                         {mS[item?.date?.split(",")?.[0]?.split("/")?.[0] - 1]}{" "}
                        {ordinal_suffix_of(
                          item?.date?.split(",")?.[0]?.split("/")?.[1]
                        )}{" "}
                        {item?.date?.split(",")?.[0]?.split("/")?.[2]}
                      </h1>

                      
                      <h1
                        style={{
                          width: "20%",
                        }}
                      >
                      {item?.app_code}
                      </h1>
                      <h1
                        style={{
                          width: "10%",
                        }}
                      >
                       {item?.license_status}
                      </h1>
                    </div>
                  );
                })}
              </>
            )}
          </>
        );
      case "Products":
        return (
          <>
            {loaderfp ? (
              <>
                {[1, 2, 2, 2, 2, 2, 2, 2].map((item) => {
                  return (
                    <div className="contain-bottom">
                      <Skeleton style={{ height: "30px", width: "60px" }} />
                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "60px" }} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {Allproducts?.map((item, i) => {
                  console.log(
                    " item?.date?.split('-')?.[0]",
                    item?.date?.split(",")?.[0]?.split("/")?.[0]
                  );
                  return (
                    <div className="contain-bottom-des">
                  
                      <div
                        style={{
                            width: "33%",
                            paddingRight:"3%"
                          }}
                      >

                        <div 
                        style={{
                            display:'flex',
                            alignItems:"flex-start"
                        }}
                        >

                        <h1
                        style={{
                          marginRight:"15px"
                        }}
                      >
                        {i + 1}
                      </h1>
                   <div>
                      <h1
                       style={{
                        // width: "27%",
                      }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                          }}
                          src={item?.product_icon}
                        />
                        {item?.product_name}
                      </h1>
                      <h5>{item?.sub_text}</h5>
                      </div>
                      </div>
                      </div>

                      <h1
                        style={{
                          width: "37%",
                        }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                            width: "27.48px",
                            borderRadius: "500px",
                          }}
                          src={item?.banker_metadata?.displayName}
                        />
                        {item?.banker_metadata?.displayName}
                      </h1>

                      <h1
                        style={{
                          width: "25%",
                        }}
                      >
                        {item?.app_code} 
                      </h1>

                      <h1
                        style={{
                          width: "10%",
                        }}
                      >
                        ---
                      </h1>
                    </div>
                  );
                })}
              </>
            )}
          </>
        );

      case "Customers":
        return (
          <>
            {loader ? (
              <>
                {[1, 2, 2, 2, 2, 2, 2, 2].map((item) => {
                  return (
                    <div className="contain-bottom">
                      <Skeleton style={{ height: "30px", width: "60px" }} />
                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "80px" }} />

                      <Skeleton style={{ height: "30px", width: "60px" }} />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {CustomerAllApps?.map((item, i) => {
                  console.log(
                    " item?.date?.split('-')?.[0]",
                    item?.date?.split(",")?.[0]?.split("/")?.[0]
                  );
                  return (
                    <div className="contain-bottom-des">
                               <div
                        style={{
                            width: "56%",
                            paddingRight:"3%"
                          }}
                      >

                        <div 
                        style={{
                            display:'flex',
                            alignItems:"flex-start"
                        }}
                        >

                        <h1
                        style={{
                          marginRight:"15px"
                        }}
                      >
                        {i + 1}
                      </h1>
                   <div>
                      <h1
                       style={{
                        // width: "27%",
                      }}
                      >
                        <img
                          style={{
                            height: "27.48px",
                            marginRight: "10px",
                          }}
                          src={item?.profile_img}
                        />
                        {item?.name}
                      </h1>
                      <h5>{item?.email}</h5>
                      </div>
                      </div>
                      </div>
                    

                      <h1
                        style={{
                          width: "33%",
                        }}
                      >
                        {item?.productCount}
                      </h1>

                      <h1
                        style={{
                          width: "11%",
                        }}
                      >
                           {item?.licenseCount}
                      </h1>
                    </div>
                  );
                })}
              </>
            )}
          </>
        );
    }
  };

  return (
 <>
    

  
        
        <div className="continer-height">
          <div className="box-tacker">
            <div className="citixen-box"
            style={{
              paddingBottom: "26px",
              paddingTop: "15px"
            }}
            >
              <p>Transaction Volume</p>
              <h2
                style={{
                  marginTop: "13px"
                
                }}
                >
                 $0.00
                
                </h2>
                <div className="price-value-tacker">
                    <div className="round-img">
                      <img src={toparrow} />
                    </div>
                    +0 (+0.00%)
                  </div>
            </div>

            <div className="rightside-tacker">
            <div className="yearly-tavker">
              {year.map((item) => {
                return (
                  <div
                    className="year-tab"
                    onClick={() => setvalueName(item)}
                    style={
                      item === valueName
                        ? {
                            // borderBottom: "0.5px solid #16171A",
                            opacity: 1,
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "34px",

                            color: "#16171A",
                            backgroundImage:
                              "-webkit-linear-gradient(205deg, rgb(39 126 251 / 0%) calc(100% - 112px), white 0rem)",
                          }
                        : { cursor: "pointer" }
                    }
                  >
                    {item}
                  </div>
                );
              })}
            </div>
              <div className="combine-box">
                <div
                  className="citixen-box-right"
                  onClick={() => settableName("Products")}
                  style={{
                    // boxShadow:
                    //   tableName == "Products" &&
                    //   "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    border: tableName == "Products" && "0.5px solid #E5E5E5",
                  }}
                >
                  <p>Products</p>
                  {loaderfp ? (
                    <Skeleton style={{ height: "30px", width: "60px" }} />
                  ) : (
                    <h2>
                      <CountUp end={Allproducts?.length} />
                      <div className="price-value-tacker">
                        <div className="round-img">
                          <img src={toparrow} />
                        </div>
                        +0
                      </div>
                    </h2>
                    // <h2>{formatNumber(foundercount?.count)}</h2>
                  )}
                </div>
                <div
                  className="citixen-box-right"
                  onClick={() => settableName("Customers")}
                  style={{
                    // boxShadow:
                    //   tableName == "Customers" &&
                    //   "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    border:
                      tableName == "Customers" && "0.5px solid #E5E5E5",
                  }}
                >
                  <p>Customers</p>
                  {loader ? (
                    <Skeleton style={{ height: "30px", width: "60px" }} />
                  ) : (
                    <h2>
                      <CountUp end={CustomerAllApps?.length} />
                      <div className="price-value-tacker">
                        <div className="round-img">
                          <img src={toparrow} />
                        </div>
                        +0
                      </div>
                    </h2>
                    // <h2>{formatNumber(countdata?.appscount)}</h2>
                  )}
                </div>
                <div
                  className="citixen-box-right"
                  onClick={() => settableName("Purchases")}
                  style={{
                    // boxShadow:
                    //   tableName == "Purchases" &&
                    //   "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    border:
                      tableName == "Purchases" && "0.5px solid #E5E5E5",
                  }}
                >
                  <p>Purchases</p>
                  {Bloader ? (
                    <Skeleton style={{ height: "30px", width: "60px" }} />
                  ) : (
                    <h2>
                      {" "}
                      <CountUp
                        end={AllPurchase?.length}

                        // prefix="EUR "
                      />
                      <div className="price-value-tacker">
                        <div className="round-img">
                          <img src={toparrow} />
                        </div>
                        +0
                      </div>
                    </h2>
                    // <h2>{formatNumber(countdata?.commissions?.usersCount)}</h2>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="gobleName">Global {tableName} Leaderboard</div>
          <div className="bottom-table-tracker">
            {headertable()}
            <div className="tableTracker">{swtchData()}</div>
          </div>
        
      </div>
    </>
  );
}
