import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { GlobalContex } from "../../../globalContex";
import "./partnership.scss";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SelectOptions from "../selectoptions";
import tik from '../../../static/images/tik.svg';
import SplashHeader from "../../../globalComponents/SplashHeader";
import myCrypto_full from "../../../static/images/logos/myCrypto_full.svg";
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";

const myCards = [
    {
        key:0,
        title:'White-Label',
        status:false,
    },
    {
        key:1,
        title:'API’s',
        status:false,
    },
    {
        key:2,
        title:'Plugins',
        status:false,
    },
    {
      key:3,
      title:'Liquidity',
      status:false,
    },
    {
      key:4,
      title:'Marketing',
      status:false,
    },
    {
      key:5,
      title:'Distribution',
      status:false,
    },
    {
      key:6,
      title:'Other',
      status:false,
    },
]

function PartnerShips() {
  let navigate = useNavigate();
  const {collapsedSideBar, setSelectNav,selectedcontactquery, showDraw, setShowDraw, setcompanynav} = useContext(GlobalContex);
  const [fullname, setfullname] = useState('');
  const [company, setcompany] = useState('');
  const [description, setdescription] = useState('');
  const [email, setemail] = useState('');
  const [phoneno, setphoneno] = useState('');
  const [whatsapp, setwhatsapp] = useState('');
  const [selectedCards, setSelectedCards] =  useState([])
  const [ submit, setsubmit] = useState(false)


  const handleselectbox = (item) => {
    if (selectedCards.find((o) => o === item.title)) {
      setSelectedCards(selectedCards.filter((o) => o !== item.title));
    } else {
      setSelectedCards([...selectedCards, item.title]);
    }
    
  }

  const handleNav = () => {
    setSelectNav('about')
    navigate('/')
  }


  return (
   
    <div className='landingPage'>
     
      <div className='pageWrap'>
        <div className='patnershipMaster'>
          <div className='selectoptions'>
            <SelectOptions />
          </div>
          <div className='abcd'>
          <div className='pMaintitle'>{selectedcontactquery}</div>
            <div className='partnershipSection'>
              <div className='psubhead1'>Enter Your Full Name</div>
              <div className='ptextbox'>
                <input className='pinput' style={{opacity : fullname !== ''? 1 : 0.5}} type='text' placeholder='Name....' value={fullname} onChange={e => setfullname(e.target.value)}/>
              </div>
              <div className='psubhead'>Enter The Name Of Your Company</div>
              <div className='ptextbox'>
                <input className='pinput' style={{opacity : company !== ''? 1 : 0.5}} type='text' placeholder='Company....' value={company} onChange={e => setcompany(e.target.value)}/>
              </div>
              <div className='psubhead'>What Type Of Partnership Are You Interested In?</div>
              <div className='multiselectbox'>
                {
                  myCards.map((each, id) => {

                    return(
                      
                      <div className='eachselect'
                      
                      style={{
                        border: selectedCards.find((o) => o === each.title) !== undefined ? '2px solid #16171A': '0.5px solid #EFEFEF'
                      }}
                      key={each.title}  
                      onClick={() => handleselectbox(each)}>
                        <div style={{display:'flex', justifyContent: 'end', position: 'relative'}}>
                            {selectedCards.find((o) => o === each.title) !== undefined ?
                              <img style={{position:"absolute",
                              margin: "3px 0px 0 0px",
                              padding: "0 10px 0 0"}} src={tik} alt='' />
                            :''
                            }
                          </div>
                        <div>
                          <div className='cardtitle'>
                          <div>{each.title}</div>
                          </div>
                        </div>
                      </div>
                    
                    )
                  })
                }
              </div>
              <div className='psubhead'>Explain The Nature Of The Partnership You Had In Mind</div>
              <div className='ptextbox'>
                <textarea className='ptextarea'  style={{opacity : description !== ''? 1 : 0.5}} placeholder='Description....' value={description} onChange={e => setdescription(e.target.value)}></textarea>
              </div>
              <div className='psubhead'>Enter Your Email</div>
              <div className='ptextbox'>
                <input className='pinput' style={{opacity : email !== ''? 1 : 0.5}} type='text' placeholder='Email....' value={email} onChange={e => setemail(e.target.value)}/>
              </div>
              <div className='psubhead'>Enter A Phone Number That Is Best To Call You At</div>
              <div className='ptextbox'>
                <input className='pinput' style={{opacity : phoneno !== ''? 1 : 0.5}} type='number' placeholder='Phone Number...' value={phoneno} onChange={e => setphoneno(e.target.value)}/>
              </div>
              <div className='psubhead'>Enter A WhatsApp Enabled Phone Number</div>
              <div className='ptextbox'>
                <input className='pinput' style={{opacity : whatsapp !== ''? 1 : 0.5}} type='number' placeholder='WhatsApp Number...' value={whatsapp} onChange={e => setwhatsapp(e.target.value)}/>
              </div>
              <div className='finalsubmit' onClick={() => setsubmit(true)}>Submit Inquiry</div>
            </div>
          </div>
        </div>
      </div>
    <div>
    <>
    {submit ?
    <div className='pagemask' onMouseDown={() => setsubmit(false)}>
      <div className='finalBox'>
        <div className='confirmationbox'>
          <div className='endbox' onMouseDown={(e) => e.stopPropagation()}>
            <div className='imgbox'>
              <img className='otclogo' src={myCrypto_full} alt='' />
            </div>
            <div className='textbox'>
              <div className='confirmtext'>Your Inquiry Has Been Submitted Successfully. What Would You Like To Do Next?</div>
            </div>
            <div className='buttonBox'>
              <div className='homebtn' onClick={() => handleNav()}>Go Home</div>
              <div  className='inquiry' onClick={() =>{
                setcompanynav("contactus")
                navigate('/thecompany')
              }}>New Inquiry</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    :''
    }
    </>
    </div>
    </div>
  );
}

export default PartnerShips;
