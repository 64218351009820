import React, { useState, useContext } from "react";
import { GlobalContex } from "../../../globalContex";
import "./products.scss";

////images
import affliate from "../../../static/images/products/productsidebar/affliate.svg";
import bonds from "../../../static/images/products/productsidebar/bonds.svg";
import defi from "../../../static/images/products/productsidebar/defi.svg";
import digitalbank from "../../../static/images/products/productsidebar/digitalbank.svg";
import equity from "../../../static/images/products/productsidebar/equity.svg";
import exchange from "../../../static/images/products/productsidebar/exchange.svg";
import funds from "../../../static/images/products/productsidebar/funds.svg";
import nft from "../../../static/images/products/productsidebar/nft.svg";
import payments from "../../../static/images/products/productsidebar/payments.svg";
import clickarrow from "../../../static/images/products/productsdata/clickarrow.svg";
import crypto from "../../../static/images/products/productsdata/crypto.svg";
import forex from "../../../static/images/products/productsdata/forex.svg";
import safestorage from "../../../static/images/products/productsdata/safestorage.svg";
import vaults from "../../../static/images/products/productsdata/vaults.svg";
import portfolio from "../../../static/images/products/productsdata/portfolio.svg";
import walletscan from "../../../static/images/products/productsdata/walletscan.svg";
import otc from "../../../static/images/products/productsdata/otc.svg";
import routingengine from "../../../static/images/products/productsdata/routingengine.svg";
import tokenswap from "../../../static/images/products/productsdata/tokenswap.svg";
import localorderbook from "../../../static/images/products/productsdata/localorderbook.svg";
import vaultswhiteicon from "../../../static/images/products/productsdata/vaultswhiteicon.svg";
import { useNavigate } from "react-router-dom";
import Mobmenu from "../../../globalComponents/mobMenubar/mobmenu";

import arrow1 from "../../../static/images/products/arrow1.svg";
import leftarrow1 from "../../../static/images/products/leftarrow1.svg";
import rightarrow1 from "../../../static/images/products/rightarrow1.svg";

const sideBarMenu = [
  {
    id: 0,
    title: "Digital Bank",
    icon: digitalbank,
  },
  {
    id: 1,
    title: "Exchange",
    icon: exchange,
  },
  {
    id: 2,
    title: "Defi",
    icon: defi,
  },
  {
    id: 3,
    title: "Debt",
    icon: bonds,
  },
  {
    id: 4,
    title: "Equity",
    icon: equity,
  },
  {
    id: 5,
    title: "Funds",
    icon: funds,
  },
  {
    id: 6,
    title: "NFT’s",
    icon: nft,
  },
  {
    id: 7,
    title: "Affiliate",
    icon: affliate,
  },
  // {
  //   id: 8,
  //   title: "All Products",
  // },
  
];

const subMenudata = [
  {
    id: 0,
    menu: "Digital Bank",
    icon: digitalbank,
    info: "To See All Digital Bank Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a digital bank by embedding wallets and wallet tracking tracking features for the end users.",
    allsubmenus: [
      {
        eleid: 0,
        menu: "Digital Bank",
        title: "Centralized Custody",
        info: "To See All Digital Bank Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Crypto Vaults",
            desc: "Provide your users with 60+ crypto wallets directly in your application. Users can deposit, withdraw, transfer and view transaction ledgers for each wallet.",
            icon: vaults,
            whiteicon: vaultswhiteicon,
          },
          {
            key: 1,
            head: "Forex Vaults",
            desc: "Provide your users with 10+ fiat wallets directly in your application. Users can deposit, withdraw, transfer and view transaction ledgers for each wallet.",
            icon: forex,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        menu: "Digital Bank",
        title: "Decentralized Custody",
        info: "To See All Digital Bank Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Safe.Storage",
            desc: "Provide your users with clients with Multi-Signature and Multi-Wallet custody solution whereby they are given the ability to store their own private keys. ",
            icon: safestorage,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 2,
        menu: "Digital Bank",
        title: "Portfolio Management",
        info: "To See All Digital Bank Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Portfolio.Ai",
            desc: "Enable your users to track their live balances across multiple asset classes in a single analytics dashboard.",
            icon: portfolio,
            whiteicon: "",
          },
          {
            key: 1,
            head: "WalletScan",
            desc: "Enable your users to sync all transactions from multiple wallets in different platforms into a consolidated downloadble transaction ledger.",
            icon: walletscan,
            whiteicon: "",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    menu: "Exchange",
    icon: exchange,
    info: "To See All Exchange Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a crypto exchange by embedding localized and global through multiple user interfaces.",
    allsubmenus: [
      {
        eleid: 0,
        title: "Simple Trading",
        menu: "Exchange",
        info: "To See All Exchange Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "TokenSwap",
            desc: "Your users will be able to convert between any of the assets within their Crypto or Forex Vaults in a Coinbase style interface. All liquidity managed by us and you set the fees.",
            icon: tokenswap,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        title: "Advanced Trading",
        menu: "Exchange",
        info: "To See All Exchange Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Local Terminal",
            desc: "Offer a order matching engine in the form of a high frequency trading interface that your users can use to create a localized market.",
            icon: localorderbook,
            whiteicon: "",
          },
          {
            key: 1,
            head: "Global Terminal",
            desc: "Offer your users unlimited liquidity from the top crypto exchanges in the form of a high frequency trading interface where you set the fees.",
            icon: routingengine,
            whiteicon: "",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    menu: "Defi",
    icon: defi,
    info: "To See All Defi Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a passive income generating platform by embedding a variety of defi to wallet protocols.",
    allsubmenus: [
      {
        eleid: 0,
        menu: "Defi",
        title: "Integrated Defi",
        info: "To See All Defi Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "MoneyMarkets",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 1,
            head: "Defi Routing Engine",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        menu: "Defi",
        title: "Defi As A Service",
        info: "To See All Defi Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Local Pool",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: localorderbook,
            whiteicon: "",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    menu: "Debt",
    icon: bonds,
    info: "To See All Debt Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a passive income generating platform by embedding a variety of defi to wallet protocols.",
    allsubmenus: [
      {
        eleid: 0,
        menu: "Debt",
        title: "Debt Issuance",
        info: "To See All Debt Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Bonds",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 1,
            head: "Convertable Notes",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        menu: "Debt",
        title: "Debt Arbitrage",
        info: "To See All Debt Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Connected Bonds",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: localorderbook,
            whiteicon: "",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    menu: "Equity",
    icon: equity,
    info: "To See All Equity Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a passive income generating platform by embedding a variety of defi to wallet protocols.",
    allsubmenus: [
      {
        eleid: 0,
        menu: "Equity",
        title: "Equity Issuance",
        info: "To See All Equity Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "ShareTokens",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 1,
            head: "ESOP’s",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 2,
            head: "Dividends",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 3,
            head: "Virtual Prospectus",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        menu: "Equity",
        title: "Secondary Markets",
        info: "To See All Equity Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Local Orderbook",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    menu: "Funds",
    icon: funds,
    info: "To See All Funds Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a passive income generating platform by embedding a variety of defi to wallet protocols.",
    allsubmenus: [
      {
        eleid: 0,
        menu: "Funds",
        title: "Fund Issuance",
        info: "To See All Funds Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "FundCoins",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 1,
            head: "Index-FundCoins",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 2,
            head: "Dividends",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
          },
          {
            key: 3,
            head: "Virtual Prospectus",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 4,
            head: "Escrow Trading",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        menu: "Funds",
        title: "Secondary Markets",
        info: "To See All Funds Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Local Orderbook",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    menu: "NFT’s",
    icon: nft,
    info: "To See All NFT’s Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a passive income generating platform by embedding a variety of defi to wallet protocols.",
    allsubmenus: [
      {
        eleid: 0,
        menu: "NFT’s",
        title: "NFT Issuance",
        info: "To See All NFT’s Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Single Chain",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 1,
            head: "Multi-Chain",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        menu: "NFT’s",
        title: "NFT Markets",
        info: "To See All NFT’s Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Local Orderbook",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: localorderbook,
            whiteicon: "",
          },
          {
            key: 1,
            head: "Galleries",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: localorderbook,
            whiteicon: "",
          },
          {
            key: 2,
            head: "Displays",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: localorderbook,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 2,
        menu: "NFT’s",
        title: "NFT X DEFI",
        info: "To See All NFT’s Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "NFTReward",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    menu: "Affiliate",
    icon: affliate,
    info: "To See All Affiliate Products",
    boldtext: "Click Here",
    proddesc:"A suite of products which can turn any application into a passive income generating platform by embedding a variety of defi to wallet protocols.",
    allsubmenus: [
      {
        eleid: 0,
        menu: "Affiliate",
        title: "Affiliate",
        info: "To See All Affiliate Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "OTCDesk",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
          {
            key: 1,
            head: "InstaCrypto",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: otc,
            whiteicon: "",
          },
        ],
      },
      {
        eleid: 1,
        menu: "Affiliate",
        title: "P2P",
        info: "To See All Affiliate Products",
        boldtext: "Click Here",
        minicards: [
          {
            key: 0,
            head: "Local Orderbook",
            desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
            icon: localorderbook,
            whiteicon: "",
          },
        ],
      },
    ],
  },
];

const Products = () => {
  const navigate = useNavigate();
  const {
    collapsedSideBar,
    setselectedProduct,
    selectedMenu1,
    setSelectedMenu1,
    showMenu,
    setshowMenu,
    productmainMenu, setproductmainMenu,
    selectedfpnav
  } = useContext(GlobalContex);

  const handleMenu = (each) => {
    if (each.menu !== showMenu) {
      setshowMenu(each.menu);
    } else {
      setshowMenu("");
    }
  };

  const handleproductNav = (each) => {
    let url = `/products/${selectedMenu1}/${each.head.replace(" ", "").toLowerCase()}`;
    localStorage.setItem("eachproduct", JSON.stringify(each));
    setselectedProduct(each);
    navigate(url);
  };

  const handlenav = (each) => {
    setproductmainMenu(each.menu)
    navigate(`/products/${each.menu.toLowerCase()}`)
  }
  return (
    <div className="productMaster">
      {/* <>
        {collapsedSideBar ? (
          <Mobmenu />
        ) : (
          <> */}
            <div className="productSideBar">
              {sideBarMenu.map((each, id) => {
                return (
                  <div
                    className="eachMenu"
                    style={{
                      borderRight:
                        each.title === selectedMenu1 ? "5px solid #16171A" : "",
                    }}
                    onClick={(e) => each.title === 'All Products'? handlenav(subMenudata[0].allsubmenus[0]): setSelectedMenu1(each.title)}
                    key={id}
                  >
                    {each.title !== 'All Products'?
                    <>
                    <img className="menuicon" src={each.icon} alt="" />
                    <div className="menutext">{each.title}</div>
                    </>
                    :
                    <div className="menutext1">{each.title}</div>
                  }
                  </div>
                );
              })}
            </div>
            <div className="productContentBox">
              {
                subMenudata
                  .filter((x) => {
                    return x.menu === selectedMenu1;
                  })
                  .map((element, i) => {
                    return (
                      <div>
                        <div className="ps-box">
                          <div className="ps-title-box">
                            <div className="ps-left-box">
                              <div className="ps-img">
                              <img className="ps-img" src={element.icon} alt="" />
                              </div>
                              <div  className="ps-textbox">
                                <div className="ps-title">{element.menu}</div>
                                <div className="type-info">
                                  <span className="type-bold" 
                                  // onClick={e =>handlenav(each)}
                                  >
                                      {element.boldtext}
                                    </span>
                                    &nbsp;{element.info}
                                  </div>
                              </div>
                            </div>
                            <div className="ps-right-box" 
                            // onClick={e =>handlenav(element)}
                          >See All Products&nbsp;&nbsp;<span><img src={arrow1} alt="" /></span></div>
                          </div>
                          <div className="ps-desc-box">{element.proddesc}</div>
                        </div>
                        <div key={i}>
                          {element.allsubmenus.map((each, j) => {
                            return (
                              <div
                                className={
                                  each.eleid === 0
                                    ? "each-type-box"
                                    : "each-type-box1"
                                }
                                key={j}
                              >
                                <div className="type-title">{each.title}</div>
                                <div className="all-cards">
                                  {each.minicards.map((one, k) => {
                                    return (
                                      <div
                                        className="one-box"
                                        key={k}
                                        // onClick={(e) => handleproductNav(one)}
                                      >
                                        <div className="p-card-title">
                                          {one.head}
                                        </div>
                                        <div className="card-desc">
                                          {one.desc}
                                        </div>
                                        <div className="card-image-box">
                                          <button className="zoom1" >
                                            <span
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-evenly",
                                              }}
                                            >
                                              <div className="check1-text">CHECK</div>
                                              <img
                                                className="clickarrow-img"
                                                src={clickarrow}
                                                alt=""
                                              />
                                            </span>
                                          </button>
                                          <img
                                            className="icon1-img"
                                            src={one.icon}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                // :''
              }
            </div>
            <div className="mobileView">
              {subMenudata.map((each, id) => {
                return (
                  <div
                    className="each-mob-menu"
                    key={id}
                  >
                    <div className="each-mob-menu-head">
                      <div className="each-mob-title">
                        <img className="each-mob-img1" src={each.icon} alt="" />
                        <div className="each-mob-head">{each.menu}</div>
                      </div>
                      <div className="each-mob-imgbox" onClick={(e) => handleMenu(each)}>
                        <img
                            src={showMenu === each.menu ? rightarrow1 : leftarrow1}
                            alt=""
                        />
                      </div>
                    </div>
                    <div
                      className="each-mob-menu-body"
                      style={{
                        display: showMenu === each.menu ? "block" : "none",
                      }}
                    >
                      {
                        subMenudata
                          .filter((x) => {
                            return x.menu === showMenu;
                          })
                          .map((element, i) => {
                            return (
                              <div key={i}>
                                {element.allsubmenus.map((each, j) => {
                                  return (
                                    <div
                                      className={
                                        each.eleid === 0
                                          ? "each-type-box"
                                          : "each-type-box1"
                                      }
                                      key={j}
                                    >
                                      <div className="type-title">{each.title}</div>
                                      <div className="type-info">
                                      <span className="type-bold" 
                                      // onClick={e =>handlenav(each)}
                                      >
                                          {each.boldtext}
                                        </span>
                                        &nbsp;{each.info}
                                      </div>
                                      <div className="all-cards">
                                        {each.minicards.map((one, k) => {
                                          return (
                                            <div
                                              className="one-box"
                                              key={k}
                                              // onClick={(e) =>
                                              //   handleproductNav(one)
                                              // }
                                            >
                                              <div className="p-card-title">
                                                {one.head}
                                              </div>
                                              <div className="card-desc">
                                                {one.desc}
                                              </div>
                                              <div className="card-image-box">
                                                <button className="zoom1">
                                                  <span
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-evenly",
                                                    }}
                                                  >
                                                    <div className="check1-text">
                                                      CHECK
                                                    </div>
                                                    <img
                                                      className="clickarrow-img"
                                                      src={clickarrow}
                                                      alt=""
                                                    />
                                                  </span>
                                                </button>
                                                <img
                                                  className="icon1-img"
                                                  src={one.icon}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })
                        // :''
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          {/* </>
        )}
      </> */}
    </div>
  );
};

export default Products;
