import axios from "axios";

// get all companies

export const GetAllCompanies = () => {
    try {
        const response =  axios.get(`https://comms.globalxchange.io/gxb/apps/get`);
        return response;
    }
    catch(error){
        return error.response
    }
    
}

// Get Brand Categories

export const GetBrandCategories = () => {
    try {
        const response =  axios.get(`https://comms.globalxchange.io/gxb/apps/category/get`);
        return response;
    }
    catch(error){
        return error.response
    }
    
}

// Get All Brands

export const GetAllBrands = () => {
    try {
        const response =  axios.get(`https://comms.globalxchange.io/gxb/apps/get`);
        return response;
    }
    catch(error){
        return error.response
    }
    
}

// Get All Brands For One Category ID

export const GetBrandsOnCategoryaBased = (id) => {
    try {
        const response =  axios.get(`https://comms.globalxchange.io/gxb/apps/get`,
        {
            params: {
                category_id: id
            }
        }
        );
        return response;
    }
    catch(error){
        return error.response
    }
    
}