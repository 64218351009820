import React, { useContext, useEffect, useRef, useState } from "react";

import myCrypto_full from "../../static/logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import d1arrow from "../../static/images/about/d1arrow.svg";
import cryptoStartup from "../../static/images/menulogos/cryptoStartup.svg";
import cryptoMarketing from "../../static/images/menulogos/cryptoMarketing.svg";
import cryptoLaw from "../../static/images/menulogos/cryptoLaw.svg";
import safeStorage from "../../static/images/menulogos/safeStorage.svg";
import blockSoftware from "../../static/images/menulogos/blockSoftware.svg";
import cryptoShild from "../../static/images/menulogos/cryptoShild.svg";
import news from "../../static/images/icons/news.svg";
import insta from "../../static/images/menulogos/insta.svg";
import discord from "../../static/images/menulogos/discord.svg";
import youtube from "../../static/images/menulogos/youtube.svg";
import email from "../../static/images/icons/home_logo_3.svg";
import whatsApp from "../../static/images/icons/whatsApp.svg";
import connection from "../../static/images/icons/connection.svg";
import telegram from "../../static/images/icons/telegram.svg";

import classNames from "./splashHeader.scss";
import SplashSidebar from "../SplashSidebar";
import { GlobalContex } from "../../globalContex";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import garageic from "../../static/images/about/garageic.svg";
import devsic from "../../static/gra.svg";

import m1 from "../../static/mar.svg";
import m2 from "../../static/m2.svg";
import m3 from "../../static/m3.svg";

import headerlogo from "../../static/headerlogo.svg";
const mainMenu = [
  {
    id: 0,
    title: "Marketsverse",
    desc: "Discover, hire and manage your Ai powered development team.",
    icon: m1,
    link: "https://gravitichain.com/",
  },
  {
    id: 0,
    title: "Gravitichain",
    desc: "Discover, hire and manage your Ai powered development team.",
    icon: devsic,
    link: "https://gravitichain.com/",
  },
  {
    id: 1,
    title: "Garage",
    desc: "Create and manage your startup in your GarageOS.",
    icon: garageic,
    link: "https://innovationgarage.in/",
  },
  {
    id: 1,
    title: "Viralverse",
    desc: "Create and manage your startup in your GarageOS.",
    icon: m2,
    link: "https://viral.group/",
  },
  {
    id: 1,
    title: "AIR",
    desc: "Create and manage your startup in your GarageOS.",
    icon: m3,
    link: "https://airevolution.app/",
  },
  // {
  //     id:2,
  //     title:"Publications",
  //     desc:"Create and monetize your own content business.",
  //     icon:pubic,
  //     link:"https://publications.app/"
  // },
];
const SplashHeader = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showDraw, setShowDraw, selectedMenu, setSelectedMenu,collapsedSideBar, setCollapsedSideBar,setselectedcontactquery,
    selectNav, setSelectNav, prevnav, setprevnav, setcompanynav, setnavHover,sethomeDarrow,homeDarrow} =
    useContext(GlobalContex);

  const [allCoins, setAllCoins] = useState([]);

  const handleNav = (from) => {
    if(from === 'about') {
      setSelectNav(from)
      setprevnav('about')
      navigate('/')
      setCollapsedSideBar(false)
    }else if(from === 'products'){
      setSelectNav(from)
      navigate('/products')
    }
    else if(from === 'casestudies'){
      setSelectNav(from)
      setprevnav('casestudies')
      navigate('/casestudies')
    }
    else if(from === 'templates'){
      setSelectNav(from)
      setprevnav('templates')
      navigate('/templates')
    }
    else if(from === 'thecompany'){
      setSelectNav(from)
      setselectedcontactquery("")
      setcompanynav("company")
      setprevnav('thecompany')
      navigate('/thecompany')
    }
  }

  const appMenu = [
    {
      icon: cryptoStartup,
      title: "CryptoStartups.com",
      subtitle: "Crypto Business Publication",
      link: "http://cryptostartups.com",
    },
    {
      icon: cryptoMarketing,
      title: "CryptoMarketingPro",
      subtitle: "Blockchain Marketing Agency",
      link: "http://cryptomarketingpro.com",
    },
    {
      icon: cryptoLaw,
      title: "CryptoLaw.com",
      subtitle: "Crypto Legal Publication",
      link: "http://cryptolaw.com",
    },
    {
      icon: safeStorage,
      title: "Safe Storage",
      subtitle: "Institutional Custody Solution",
      link: "http://safe.storage",
    },
    {
      icon: blockSoftware,
      title: "Block.Software",
      subtitle: "Blockchain Development Agency",
      link: "http://block.software",
    },
    {
      icon: cryptoShild,
      title: "CryptocurrencyShield",
      subtitle: "Crypto Insurance Platform",
      link: "http://cryptocurrencyshield.com",
    },
  ];

  const socialMediaMenu = [
    {
      icon: news,
      title: "Blog",
      // subtitle: "blog.mycryptobrand.com",
      link: "http://blog.mycryptobrand.com",
    },
    {
      icon: email,
      title: "Email",
      // subtitle: "support@inr.group",
      link: "contact@MarketsVerse.com",
    },
    {
      icon: discord,
      title: "Discord",
      // subtitle: "Join Server",
      link: "",
    },
    {
      icon: youtube,
      title: "Youtube",
      // subtitle: "Go To Channel",
      link: "https://www.youtube.com/channel/UCthJbOwKpKcPSx6gYaMeWTw",
    },
    {
      icon: insta,
      title: "Instagram",
      // subtitle: "@inr.group",
      link: "https://www.instagram.com/mycryptobrandofficial/",
    },
    {
      icon: whatsApp,
      title: "WhatsApp",
      // subtitle: "@inr.group",
      link: "https://api.whatsapp.com/send?phone=16477234329&text=&source=&data=",
    },
    {
      icon: telegram,
      // title: "Telegram",
      subtitle: "@inr.group",
      link: "",
    },
    {
      icon: connection,
      title: "Connection",
      // subtitle: "@inr.group",
      link: "",
    },
  ];

  useEffect(() => {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/get/all/coins`)
      .then((res) => {
        setAllCoins(res.data.coins);
      });
  }, []);

  return (
    <>
      <div style={{position:'fixed', width: '100%', zIndex:'3', background:'#FFFFFF'}}>
        {/* <div className="gridTwo">
          <div className="gridTwo1div"
      
          >
            <div
              onClick={(e) => window.open("http://globalxchange.com", "_blank")}
              className={!showDraw ? "topIcons" : ""}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div>
                <img
                  src={
                    require("../../static/images/icons/home_logo_11.svg")
                      .default
                  }
                  alt=""
                />
              </div>
            </div>
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowDraw(true);
                setSelectedMenu(appMenu);
              }}
              className={!showDraw ? "topIcons" : ""}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={
                  require("../../static/images/icons/home_logo_2.svg").default
                }
                alt=""
              />
            </div>
          </div>

          <div>&nbsp;</div>
          <div className="gridtwo2div"
            // style={{
            //   color: "white",
            //   display: "flex",
            //   justifyContent: "space-around",
            // }}
          >
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowDraw(true);
                setSelectedMenu(socialMediaMenu);
              }}
              // ref={wrapperRef}
              className={!showDraw ? "topIcons1" : ""}
              style={{
                // marginLeft: "-1.5px",
                //   borderLeft: !disableBack ? "0.5px solid #E5E5E5" : "",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                //   pointerEvents: showMenu ? "none" : "",
              }}
            >
              <img
                //   style={{ opacity: !disableBack ? 1 : 0.3 }}
                src={
                  require("../../static/images/icons/home_logo_3.svg").default
                }
                alt=""
              />
            </div>
            <div
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                setShowDraw(true);
                setSelectedMenu(allCoins);
              }}
              className={!showDraw ? "topIcons1" : ""}
              style={{
                //   borderLeft: !disableBack ? "0.5px solid #E5E5E5" : "",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //   opacity: !disableBack ? 1 : 0.4,
              }}
            >
              <img
                //   style={{ opacity: !disableBack ? 1 : 0.4 }}
                src={
                  require("../../static/images/icons/home_logo_4.svg").default
                }
                alt=""
              />
            </div>
          </div>
        </div> */}

        <div className="grid">
          

          
          <div  className='cryptoimage1' style={{display:'flex', flexDirection:'column', justifyContent:'center', paddingLeft:'80px', alignItems:'flex-start'}} onClick={(e) => navigate("/")} >
            <div className='cryptoimage2'>
            <img
              style={{ width: "140px", cursor: "pointer" }}
              src={myCrypto_full}
              alt=""              
            />
            </div>
          </div>
          <div style={{display: "flex",
                justifyContent:"flex-start"}}>
            <input
              className="searchBox"
              type="text"
              style={{
                padding: "0px 20px",
                //   opacity: !disableBack ? 1 : 0.03,
                border: "none",
              }}
              placeholder="Search MarketsVerse...."
            />
          </div>
          {/* <div
          onClick={(e) => handleNav('about')}
          >
            <span
              className={pathname === '/' && selectNav === 'about' ? "selectednav" : "nonselectednav"}
              
            >
              About
            </span>
          </div> */}
      
          <div className="nonselectednav"
           onMouseEnter={() => setnavHover('products')} onMouseLeave={() => setnavHover('')}
          // onClick={(e) => selectNav === 'products' ? handleNav(prevnav) : handleNav('products')}
            // onClick={(e) => window.open(`http://marketsverse.com`, "_blank")}
          >
            <span
              className={
                pathname.startsWith('/products') || selectNav === "products" ? "selectednav" : "nonselectednav"
              }
            >
         Products
            </span>
            
          </div>
          <div 
           onMouseEnter={() => setnavHover('templates')} onMouseLeave={() => setnavHover('')}
          // onClick={(e) =>  handleNav('templates')}
          >
            <span
              className={
                pathname.startsWith('/templates') || selectNav === "templates" ? "selectednav" : "nonselectednav"
              }
            >
              Templates
            </span>
          </div>
       
    
          {/* <div 
           onMouseEnter={() => setnavHover('casestudies')} onMouseLeave={() => setnavHover('')}
          onClick={(e) => handleNav('casestudies')}
           >
             <span
              className={
                pathname.startsWith('/casestudies') || selectNav === "casestudies" ? "selectednav" : "nonselectednav"
              }
            >
              Used Cases
            </span>
          </div> */}
          <div
          className="nonselectednav"
          // onMouseEnter={() => setnavHover('thecomapny')} onMouseLeave={() => setnavHover('')}
          // onClick={(e) =>  handleNav('thecompany')}
            // onClick={(e) => window.open(`http://metaverseapps.io`, "_blank")}
          >
           <span
              className={
                pathname.startsWith('/aboutus') || selectNav === "thecompany" ? "selectednav" : "nonselectednav"
              }
            >
              About Us
            </span>
          </div>
          {/* <div
            className="nonselectednav"
            onClick={(e) =>
              window.open(`http://app.mycryptobrand.com`, "_blank")
            }
          >
            Login
          </div> */}
          <div
          className="reg-nav"
            style={{
              // color: "white",
              fontWeight: "700",
              transform: "scale(1.04)",
              // opacity: !disableBack ? 1 : 0.1,
            }}
            onClick={() => window.open('https://metaverseapp.store/brands', '_blank')}
            // onClick={() => window.open("https://registration.mycryptobrand.com/mycryptobrand", "_blank")}
          >
            App Store
          </div>
        </div>
        <div className='mob-nav1'>
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
        {/* <div id="navMenu" onClick={()=>setCollapsedSideBar(!collapsedSideBar)}>
            <div className="McButton">
              <div className="navbar-collapse-icon"></div>
              <div className="navbar-collapse-icon"></div>
              <div className="navbar-collapse-icon"></div>
            </div>  
          </div> */}

          {/* ttt */}
            
          <svg className={!collapsedSideBar ?  "ham hamRotate ham4" : "ham hamRotate ham4 active" } viewBox="0 0 100 100" width="80" onClick={()=>setCollapsedSideBar(!collapsedSideBar)} style={{height: "60px", width: "60px"}}>
            <path
                  className="line top"
                  d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
            <path
                  className="line middle"
                  d="m 70,50 h -40" />
            <path
                  className="line bottom"
                  d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
          </svg>


          {/* yyy */}
        </div>
        <div className="cryptoimage"
        // onClick={(e) => navigate("/")}
           >
             
            <img
                  style={{ width: "170px", cursor: "pointer", marginLeft:"20px" }}
              src={myCrypto_full}
              alt=""
              onClick={(e) => navigate("/")}
            />
                 <div className="nn-arrow" onClick={() => sethomeDarrow(!homeDarrow)}>
                <img style={{transform:homeDarrow ? "rotate(0deg)" : "rotate(180deg)", transition:"all ease .5s"}} src={d1arrow} alt="" />
              </div>
          </div>
        </div>
      </div>
      {showDraw ? (
        <div
          style={{
            // display: "static",
            height: "100vh",
            width: "350px",
            background: "white",
            position: "absolute",
            zIndex: 111,
            top: 0,
            left: 0,
          }}
        >
          <SplashSidebar
            showDraw={showDraw}
            setShowDraw={setShowDraw}
            selectedMenu={selectedMenu}
          />
        </div>
      ) : (
        ""
      )}

      
<div
        className={ homeDarrow ?" big-box0 ":"xcv "}
      // style={{ display: homeDarrow ? "block" : "none" }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        

        <div className="cardsMenu">
          {mainMenu.map((each, i) => (
            <>
              <div
                className="boxinside"
                onClick={() => each.title!="marketsverse" && window.open(each?.link, "_blank")}
              >
                <div className="boxinsideinner">
                  <img className="menu-img" src={each.icon} alt="" />
                </div>
                <p>{each.title}</p>
              </div>
            </>
          
          ))}
        </div>
       
      </div>
    </>

  );
};

export default SplashHeader;
