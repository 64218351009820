/// carousel images
import hifi from "../../static/hifi.jpg";
import wealth from "../../static/images/about/wealth.svg";
import otcdesk from "../../static/images/about/otcdesk.svg";
import terminal from "../../static/term.jpg";
import fundc from "../../static/images/about/fundc.svg";
import nftmarket from "../../static/images/about/nftmarket.svg";
import nftreward from "../../static/ntfraword.jpg";
import defimarket from "../../static/images/about/defimarket.svg";
import signal from "../../static/images/about/signal.svg";

import hifiicon from "../../static/images/about/hifiicon.svg";
import wealthicon from "../../static/images/about/wealthicon.svg";
import otcdeskicon from "../../static/images/about/otcdeskicon.svg";
import terminalicon from "../../static/images/about/terminalicon.svg";
import nftrewardicon from "../../static/images/about/nftrewardicon.svg";
import fundcicon from "../../static/images/about/fundcicon.svg";
import nftmarketicon from "../../static/images/about/nftmarketicon.svg";
import defimarketicon from "../../static/images/about/defimarketicon.svg";
import signalicon from "../../static/images/about/signalicon.svg";
////

import healthcare from "../../static/healthcare.svg";
import retailProduct  from "../../static/retailProduct.svg";
import gamning from "../../static/gam.svg";
import finch from "../../static/finch.svg";
import coverage from "../../static/coverage.svg";
import Experiencess from "../../static/expen.svg"



import cdcd from "../../static/cdcd.svg"
import banjer from "../../static/banjer.svg"


///table images
import hyfic from "../../static/images/templateLogos/hyfi.svg";
import wealthc from "../../static/images/templateLogos/wealth.svg";
import otcDesksc from "../../static/images/templateLogos/otcDesks.svg";
import terminalsc from "../../static/images/templateLogos/terminals.svg";
import fundManagementc from "../../static/images/templateLogos/fundManagement.svg";
import nftMarketplacec from "../../static/images/templateLogos/nftMarketplace.svg";
import nftRewardc from "../../static/images/templateLogos/nftReward.svg";
import defic from "../../static/images/templateLogos/defi.svg";
import signalsc from "../../static/images/templateLogos/signals.svg";

import allsibgle from "../../static/singlessn.svg";
import fundsall from "../../static/fundsall.svg";
import nftsll from "../../static/nftsll.svg";
import myctobrand from "../../static/myctobrand.svg";
import publication from "../../static/publication.svg";
import classrooms from "../../static/classrooms.svg";

import Capitalss from "../../static/Capitalss.svg";
import Firmsdd from "../../static/firms.svg";
import Mallsdata from "../../static/Mallsdata.svg";
import Experiences from "../../static/exprinec.svg";
import hifis from "../../static/hifi.svg";

import accva from "../../static/accva.svg"

/// end here

import nftrewardtemp from "../../static/images/templateLogos/nftrewardtemp.svg";
import arrow from "../../static/images/icons/arrowRight1.svg";
import downarrow from "../../static/images/icons/dropdownarrow.svg";
import uparrow from "../../static/images/icons/dropuparrow.svg";
import downarrow11 from "../../static/images/about/downarrow11.svg";
import interest from "../../static/images/about/interest.svg";
import affliate from "../../static/images/products/productsidebar/affliate.svg";
import bonds from "../../static/images/products/productsidebar/bonds.svg";
import defi from "../../static/images/products/productsidebar/defi.svg";
import digitalbank from "../../static/images/products/productsidebar/digitalbank.svg";
import equity from "../../static/images/products/productsidebar/equity.svg";
import exchange from "../../static/images/products/productsidebar/exchange.svg";
import funds from "../../static/images/products/productsidebar/funds.svg";
import nft from "../../static/images/products/productsidebar/nft.svg";
import mycryptoicon from "../../static/images/about/mycryptoicon.svg";
import horzline from "../../static/images/about/horzline.svg";
import vertline from "../../static/images/about/vertline.svg";

/////


/////mdbspyscroll img
import businessOverview from "../../static/images/templateIcons/businessOverview.svg";
import revenueModels from "../../static/images/templateIcons/revenueModels.svg";
import technology from "../../static/images/templateIcons/technology.svg";
import coreGxProducts from "../../static/images/templateIcons/coreGxProducts.svg";
import marketsversePlugin from "../../static/images/templateIcons/marketsversePlugin.svg";
import caseStudy from "../../static/images/templateIcons/caseStudy.svg";
import deployment from "../../static/images/templateIcons/deployment.svg";
import pricing from "../../static/images/templateIcons/pricing.svg";
///

// what we provide images
import branded from '../../static/images/about/branded.svg';
import business from '../../static/images/about/business.svg';
import tokenized from '../../static/images/about/tokenized.svg';
///

/// describe menu images

import start from "../../static/images/about/start.svg";
import runningcrypto from "../../static/images/about/runningcrypto.svg";
import community from "../../static/images/about/community.svg";
import invest from "../../static/images/about/invest.svg";
import runningnoncrypto from "../../static/images/about/runningnoncrypto.svg";
import noneofabove from "../../static/images/about/noneofabove.svg";

import whitestart from "../../static/images/about/whitestart.svg";
import whiterunningcrypto from "../../static/images/about/whiterunningcrypto.svg";
import whitecommunity from "../../static/images/about/whitecommunity.svg";
import whiteinvest from "../../static/images/about/whiteinvest.svg";
import whiterunningnoncrypto from "../../static/images/about/whiterunningnoncrypto.svg";
import whitenoneofabove from "../../static/images/about/whitenoneofabove.svg";

import startv from "../../static/images/z1.svg";
import improvev from "../../static/images/z2.svg";
import montizev from "../../static/images/z3.svg";
import ftxv from "../../static/images/z4.svg";
import spacev from "../../static/images/z5.svg";

////

///company images

import mcbicon from "../../static/images/company/mcbicon.svg";
import marketverse from "../../static/images/company/marketverse.svg";
import metaverseapps from "../../static/images/company/metaverseapps.svg";
import blocksoftware from "../../static/images/company/blocksoftware.svg";
import bluebgarrow from "../../static/images/company/bluebgarrow.svg";
import greybgarrow from "../../static/images/company/greybgarrow.svg";

////

import bondarbitrage from "../../static/images/templates/bondarbitrage.svg";
import moneymarket from "../../static/images/templates/moneymarket.svg";
import tokenswap from "../../static/images/templates/tokenswap.svg";
import withdrawal from "../../static/images/templates/withdrawal.svg";
import sharetokenlisting from "../../static/images/templates/sharetokenlisting.svg";
import fundcoinlisting from "../../static/images/templates/fundcoinlisting.svg";
import nftlisting from "../../static/images/templates/nftlisting.svg";
import depositfees from "../../static/images/templates/depositfees.svg";
import networkfees from "../../static/images/templates/networkfees.svg";
import yieldsharing from "../../static/images/templates/yieldsharing.svg";
import subscriptions from "../../static/images/templates/subscriptions.svg";

import a1 from "../../static/web.svg";
import a2 from "../../static/publi.svg";
import a3 from "../../static/images/templateIcons/a3.svg"

import classRoom from "../../static/classRoom.svg";

import z1 from "../../static/z1.svg";

import z2 from "../../static/z2.svg";

import z3 from "../../static/z7.svg";

import z4 from "../../static/z4.svg";
import p1 from "../../static/p1.svg";

import p2 from "../../static/p2.svg";
import z11 from "../../static/z1.jpg";

////

//// env whatdodo
import raisecapital from "../../static/images/envhome/raisecapital.svg";
////


export const providecardss = [
  // {
  //   id:0,
  //   head:'InstaApp',
  //   subhead:'Instant Web/Mobile Apps',
  //   desc:'Choose from several pre-built applications which cover the most popular trends in the blockchain industry such as trading, DEFI, NFT’s, ICO’s, Equity For Your Business',
  //   icon:a1,
  // },
  // {
  //   id:0,
  //   head:'InstaApp',
  //   subhead:'Instant Web/Mobile Apps',
  //   desc:'Choose from several pre-built applications which cover the most popular trends in the blockchain industry such as trading, DEFI, NFT’s, ICO’s, Equity For Your Business',
  //   icon:a1,
  // },
  {
    id:0,
    head:'Web3',
    subhead:'For Blockchain Companies',
    desc:'Choose from several pre-built applications which cover the most popular trends in the blockchain industry, such as trading, DEFI, NFTs, & Tokenization.',
    icon:a1,
    color:"#16171A",
    url:"https://mycryptobrand.com/",
  },
  {
    id:1,
    head:'Publications',
    subhead:'For Media Entrepreneurs',
    desc:"Create or convert your existing content into a fully branded Publication App. Choose a template that caters to your content's subject matter while hiring, monetizing and increasing view engagement.",
    icon:p1,
    color:"#4B9DDC",
    url:"https://publications.app/",
  },
  {
    id:4,
    head:'Firms',
    subhead:'For Professional Industries',
    desc:'Modernize your legal or accounting practice with one of our templates for Firms. Allow your clients to discover, purchase and access services directly from your app.',
    icon:z2,
    color:"#021842",
    url:"https://firms.app/",
    
  },
    // },
    {
      id:7,
      head:'Classrooms',
      subhead:'Share Your Course With The World',
      desc:'Convert your course into a live classroom app where students can join and engage with your recorded and live content and interact with AI-based evaluation, engagement and support tools.',
      icon:classRoom,
      color:"#186AB4",
      url:"https://classrooms.app/",
    },
  {
    id:3,
    head:'Malls',
    subhead:'Social Commerce',
    desc:'Transform your offline or existing online store into a Mall ("Social-Commerce" app). Get all the features required to manage your e-commerce business while unlocking the power of your in-built shopping community.',
    icon:z1,
    color:"#6669B0",
    url:"https://malls.app/",
  },
  {
    id:3,
    head:'Malls',
    subhead:'Social Commerce',
    desc:'Transform your offline or existing online store into a Mall ("Social-Commerce" app). Get all the features required to manage your e-commerce business while unlocking the power of your in-built shopping community.',
    icon:z1,
    color:"#6669B0",
    url:"https://malls.app/",
  },

  // {
  //   id:5,
  //   head:'Experiences',
  //   subhead:'Re-eventing Events',
  //   desc:'Make your event live forever by releasing an Experiences App where guests can find, buy, and share tickets. On event day, your app becomes an immersive in-venue/digitally streamed experience',
  //   icon:z3,
  //   color:"#283790",
  //   url:"https://experiences.group/",
  // },
  // {
  //   id:6,
  //   head:'Capital Markets',
  //   subhead:'Fin-Tech Made Easy',
  //   desc:'Create a fintech tech platform in minutes. Whether you want to digitalize an existing investment business or become the latest marketplace for financial assets, we have the template for you. ',
  //   icon:z4,
  //   color:"#100F0D",
  //   url:"https://globalcapital.markets/",


]

export const providecards = [
  // {
  //   id:0,
  //   head:'InstaApp',
  //   subhead:'Instant Web/Mobile Apps',
  //   desc:'Choose from several pre-built applications which cover the most popular trends in the blockchain industry such as trading, DEFI, NFT’s, ICO’s, Equity For Your Business',
  //   icon:a1,
  // },
  // {
  //   id:0,
  //   head:'InstaApp',
  //   subhead:'Instant Web/Mobile Apps',
  //   desc:'Choose from several pre-built applications which cover the most popular trends in the blockchain industry such as trading, DEFI, NFT’s, ICO’s, Equity For Your Business',
  //   icon:a1,
  // },
  {
    id:0,
    head:'Web3',
    subhead:'For Blockchain Companies',
    desc:'Choose from several pre-built applications which cover the most popular trends in the blockchain industry, such as trading, DEFI, NFTs, & Tokenization.',
    icon:a1,
    color:"#16171A",
    url:"https://mycryptobrand.com/",
  },
  {
    id:1,
    head:'Publications',
    subhead:'For Media Entrepreneurs',
    desc:"Create or convert your existing content into a fully branded Publication App. Choose a template that caters to your content's subject matter while hiring, monetizing and increasing view engagement.",
    icon:p1,
    color:"#4B9DDC",
    url:"https://publications.app/",
  },
  {
    id:4,
    head:'Firms',
    subhead:'For Professional Industries',
    desc:'Modernize your legal or accounting practice with one of our templates for Firms. Allow your clients to discover, purchase and access services directly from your app.',
    icon:z2,
    color:"#021842",
    url:"https://firms.app/",
    
  },
    // },
    {
      id:7,
      head:'Classrooms',
      subhead:'Share Your Course With The World',
      desc:'Convert your course into a live classroom app where students can join and engage with your recorded and live content and interact with AI-based evaluation, engagement and support tools.',
      icon:classRoom,
      color:"#186AB4",
      url:"https://classrooms.app/",
    },
  {
    id:3,
    head:'Malls',
    subhead:'Social Commerce',
    desc:'Transform your offline or existing online store into a Mall ("Social-Commerce" app). Get all the features required to manage your e-commerce business while unlocking the power of your in-built shopping community.',
    icon:z1,
    color:"#6669B0",
    url:"https://malls.app/",
  },
  
  // {
  //   id:7,
  //   head:'Classrooms',
  //   subhead:'Share Your Course With The World',
  //   desc:'Choose from several pre-built applications which cover the most popular trends in the blockchain industry such as trading, DEFI, NFT’s, ICO’s, Equity For Your Business',
  //   icon:classRoom,
  //   color:"#186AB4",
  //   url:"https://classrooms.app/",
  // },
]


export const eachvalMenu = [
    {
      key: 0,
      head: "Integrated Revenue Streams",
      desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your....",
    },
    {
      key: 1,
      head: "Branding & Exposure",
      desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your....",
    },
    {
      key: 2,
      head: "Credibility",
      desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
    },
    {
      key: 3,
      head: "Raising Capital",
      desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
    },
    {
      key: 4,
      head: "External Revenue Integrations",
      desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
    },
    {
      key: 5,
      head: "Controling Payment Lifecycle",
      desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
    },
    {
      key: 6,
      head: "Affiliate App Integration",
      desc: "Create, manage, and grow your asset manage business with the IFO system. Create, manage, and grow your.....",
    },
  ];
  
export const carouselMenu = [
    {
      id: 0,
      backgroundImg: hifi,
      title: "Hyfi",
      link: "/templates/hyfi/businessOverview",
      icon: hifiicon,
      coloricon: hyfic,
      templateicon:hyfic,
      head: "Hybrid Defi",
      subhead: "Platform",
      desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
      navurl: "https://hyfi.mycryptobrand.com/mycryptobrand",
      disableexample: false,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: true,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: false,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: false,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: false,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: false,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: true,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: false,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: false,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
    {
      id: 1,
      backgroundImg: z11,
      title: "Wealth",
      link: "/templates/wealth/businessOverview",
      icon: wealthicon,
      coloricon: wealthc,
      templateicon: wealthc,
      head: "Portfolio Management",
      subhead: "Platform",
      desc: "The Wealth Management template offers a total portfolio management system to your users which allows them to track their digital net-worth while giving them access to fixed income, equity, and crypto markets.",
      navurl: "https://wealth.mycryptobrand.com/mycryptobrand",
      disableexample: false,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: true,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: true,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: true,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: true,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: true,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: true,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: true,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: true,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: true,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: true,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: true,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: true,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: true,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
    {
      id: 2,
      backgroundImg: otcdesk,
      title: "OTCDesk",
      link: "/templates/otcdesks/businessOverview",
      icon: otcdeskicon,
      coloricon: otcDesksc,
      templateicon: otcDesksc,
      head: "Fiat To Crypto",
      subhead: "Exchange",
      desc: "The OTCDesk is a standard fiat to cryptocurrency exchange application which empowers your users to fund their accounts with their local currency and swap into digital assets.",
      navurl: "https://otc.mycryptobrand.com/mycryptobrand",
      disableexample: false,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: true,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: true,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: false,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: false,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: false,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: false,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: false,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: false,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: false,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
    {
      id: 3,
      backgroundImg: terminal,
      title: "Terminal",
      link: "/templates/terminals/businessOverview",
      icon: terminalicon,
      coloricon: terminalsc,
      templateicon: terminalsc,
      head: "Crypto To Crypto",
      subhead: "Exchange",
      desc: "Terminals is an advanced cryptocurrency exchange platform which allows your users to high frequency trading across hundreds of digital assets.",
      navurl: "https://my.terminals.app/mycryptobrand",
      disableexample: false,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: false,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: false,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: false,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: false,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: false,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: false,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: false,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: false,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: true,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: true,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
    {
      id: 4,
      backgroundImg: nftreward,
      title: "NFTReward",
      link: "/templates/nftreward/businessOverview",
      icon: nftrewardicon,
      coloricon: nftrewardtemp,
      templateicon: nftrewardtemp,
      head: "NFT Rewards",
      subhead: "Platform",
      desc: "The NFTReward template allows you to create an exclusive application for your NFT whereby users can earn rewards for holding your NFT.",
      navurl: "https://nftreward.mycryptobrand.com/mycryptobrand",
      disableexample: false,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: true,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: false,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: false,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: false,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: false,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: false,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: true,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: false,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
    {
      id: 5,
      backgroundImg: fundsall,
      title: "Funds",
      link: "/templates/fund management/businessOverview",
      icon: fundcicon,
      coloricon: fundManagementc,
      templateicon: fundManagementc,
      head: "Investment Fund",
      subhead: "Platform",
      desc: "The Fund Management template is an retail investment platform which allows users to grow their assets by investing and interacting with your specific FundCoin.",
      navurl: "http://fund.mycryptobrand.com/mycryptobrand",
      disableexample: false,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: true,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: true,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: true,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: true,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: true,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: false,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: false,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: false,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
    {
      id: 6,
      backgroundImg: nftsll,
      title: "NFT Markets",
      link: "/templates/nft marketplace/businessOverview",
      icon: nftmarketicon,
      coloricon: nftMarketplacec,
      templateicon: nftMarketplacec,
      head: "NFT",
      subhead: "Marketplace",
      desc: "The NFTMarkets template allows enables NFT creators to list their NFT's on your platform as well as providing buyers with a secondary resale market.",
      navurl: "http://nftmarkets.mycryptobrand.com/mycryptobrand.com",
      disableexample: false,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: true,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: false,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: false,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: false,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: false,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: false,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: true,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: true,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
    {
      id: 7,
      backgroundImg: hifis,
      title: "DEFI Markets",
      link: "/templates/defi markets/businessOverview",
      icon: defimarketicon,
      coloricon: defic,
      templateicon: defic,
      head: "Defi Managment",
      subhead: "Platform",
      desc: "DefiMarkets is a decentralized routing engine that allows your users to manage their DEFI lending operations accross different protocols.",
      navurl: "",
      disableexample: true,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: false,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: false,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: false,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: false,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: false,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: false,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: false,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: false,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: false,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: false,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: false,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: false,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: false,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: false,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: true,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: true,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: true,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: true,
        },
      ],
    },
    {
      id: 8,
      backgroundImg: allsibgle,
      title: "Signals",
      link: "/templates/signals/businessOverview",
      icon: signalicon,
      coloricon: signalsc,
      templateicon: signalsc,
      head: "Education",
      subhead: "Platform",
      desc: "The Signals template allows you to launch your very own financial education platform where you can offer courses, analysis and market signals.",
      navurl: "",
      disableexample: true,
      templateAccess: [
        {
          key: 0,
          name: "Crypto Vaults",
          value: true,
        },
        {
          key: 1,
          name: "Fiat Vaults",
          value: true,
        },
        {
          key: 2,
          name: "MoneyMarkets",
          value: true,
        },
        {
          key: 3,
          name: "TokenSwap",
          value: true,
        },
        {
          key: 4,
          name: "Affiliate App Integration",
          value: true,
        },
        {
          key: 5,
          name: "Custom Comp Plan",
          value: false,
        },
        {
          key: 6,
          name: "OTC",
          value: false,
        },
        {
          key: 7,
          name: "OTC Marketplace",
          value: false,
        },
        {
          key: 8,
          name: "Portfolio.AI",
          value: false,
        },
        {
          key: 9,
          name: "ShareToken Issuance",
          value: false,
        },
        {
          key: 10,
          name: "ShareToken Marketplace",
          value: false,
        },
        {
          key: 11,
          name: "FundCoin Issuance",
          value: false,
        },
        {
          key: 12,
          name: "FundCoin Marketplace",
          value: false,
        },
        {
          key: 13,
          name: "IndexFund Issuance",
          value: false,
        },
        {
          key: 14,
          name: "IndexFund Marketplace",
          value: false,
        },
        {
          key: 15,
          name: "Bond Issuance",
          value: false,
        },
        {
          key: 16,
          name: "Bond Marketplace",
          value: false,
        },
        {
          key: 17,
          name: "NFT Issuance",
          value: false,
        },
        {
          key: 18,
          name: "NFTMarketplace",
          value: false,
        },
        {
          key: 19,
          name: "Terminal Local",
          value: false,
        },
        {
          key: 20,
          name: "Terminal Global",
          value: false,
        },
        {
          key: 21,
          name: "Staking Vaults",
          value: false,
        },
        {
          key: 22,
          name: "Publication",
          value: false,
        },
        {
          key: 23,
          name: "Education Issuance",
          value: true,
        },
        {
          key: 24,
          name: "Education Marketplace",
          value: true,
        },
        {
          key: 25,
          name: "Crypto Wallets",
          value: false,
        },
        {
          key: 26,
          name: "Defi Integration",
          value: false,
        },
        {
          key: 27,
          name: "Defi Routing Engine",
          value: false,
        },
        {
          key: 28,
          name: "Decentralized Staking",
          value: false,
        },
      ],
    },
  ];

export const newcarouselMenu = [
  {
    id: 8,
    backgroundImg: allsibgle,
    title: "Signals",
    link: "/templates/signals/businessOverview",
    icon: signalicon,
    coloricon: signalsc,
    templateicon: signalsc,
    head: "Education",
    subhead: "Platform",
    desc: "The Signals template allows you to launch your very own financial education platform where you can offer courses, analysis and market signals.",
    navurl: "",
    disableexample: true,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing their users with excusive content, alerts, signals, education and training", "Providing their users with an array of investable opportunities","Providing users with short term interest bearing via MoneyMarkets"],
      },
     
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:subscriptions,
        title:"Subscriptions",
        text:"Create and sell subscriptions for your users to gain access to your content",
      },
     
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$3,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$250.00"
          },
          {
            head:"Security Deposit",
            value:"$6,000.00"
          },
          {
            head:"MRKT Staking",
            value:"3,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$1,500.00"
          },
          {
            head:"Monthly Cost",
            value:"$125.00"
          },
          {
            head:"Security Deposit",
            value:"$3,000.00"
          },
          {
            head:"MRKT Staking",
            value:"1,500.00"
          },

        ]
      },
    ],

    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: true,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: true,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 0,
    backgroundImg: hifi,
    title: "Hyfi",
    link: "/templates/hyfi/businessOverview",
    icon: hifiicon,
    coloricon: hyfic,
    templateicon:hyfic,
    head: "Hybrid Defi",
    subhead: "Platform",
    desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
    navurl: "https://hyfi.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Prodiving users with long term interest bearing instruments such as Bonds", "Providing users with short term interest bearing via MoneyMarkets"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized Bonds"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:bondarbitrage,
        title:"Bond Arbitrage",
        text:"Earn the difference between the retail & wholesale bond rate",
      },
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
      
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: true,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 1,
    backgroundImg: z11,
    title: "Wealth",
    link: "/templates/wealth/businessOverview",
    icon: wealthicon,
    coloricon: wealthc,
    templateicon: wealthc,
    head: "Portfolio Management",
    subhead: "Platform",
    desc: "The Wealth Management template offers a total portfolio management system to your users which allows them to track their digital net-worth while giving them access to fixed income, equity, and crypto markets.",
    navurl: "https://wealth.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Prodiving users with long term interest bearing instruments such as Bonds", "Providing users with short term interest bearing via MoneyMarkets", "Allowing other companies to list their ShareTokens, FundCoins, Bonds, and NFT’s on your app"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized Bonds, ShareTokens, NFT’s and/or FundCoins"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:bondarbitrage,
        title:"Bond Arbitrage",
        text:"Earn the difference between the retail & wholesale bond rate",
      },
      {
        ele: 4,
        icon:sharetokenlisting,
        title:"ShareToken Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a ShareToken",
      },
      {
        ele: 5,
        icon:fundcoinlisting,
        title:"FundCoin Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a FundCoin",
      },
      {
        ele: 6,
        icon:nftlisting,
        title:"NFT Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a NFT",
      },
    ],
    drop3data:["$24,000.00", "Annual Cost", "1000 GXT", "Monthly Cost", "$50,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$18,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,500.00"
          },
          {
            head:"Security Deposit",
            value:"$36,000.00"
          },
          {
            head:"MRKT Staking",
            value:"18,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$36,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$3,000.00"
          },
          {
            head:"Security Deposit",
            value:"$72,000.00"
          },
          {
            head:"MRKT Staking",
            value:"36,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$9,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$750.00"
          },
          {
            head:"Security Deposit",
            value:"$18,000.00"
          },
          {
            head:"MRKT Staking",
            value:"9,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: true,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: true,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: true,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: true,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: true,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: true,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: true,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: true,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: true,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: true,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: true,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: true,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 2,
    backgroundImg: otcdesk,
    title: "OTCDesk",
    link: "/templates/otcdesks/businessOverview",
    icon: otcdeskicon,
    coloricon: otcDesksc,
    templateicon: otcDesksc,
    head: "Fiat To Crypto",
    subhead: "Exchange",
    desc: "The OTCDesk is a standard fiat to cryptocurrency exchange application which empowers your users to fund their accounts with their local currency and swap into digital assets.",
    navurl: "https://otc.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing user with the ability convert between forex and crypto currencies", "Providing users with short term interest bearing via MoneyMarkets", "Providing a users with a banking on and off ramp for their local currencies"],
      },
      
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:depositfees,
        title:"Deposit Fees",
        text:"Set fees on the fiat deposits that your users make from their bank",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: true,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 3,
    backgroundImg: terminal,
    title: "Terminal",
    link: "/templates/terminals/businessOverview",
    icon: terminalicon,
    coloricon: terminalsc,
    templateicon: terminalsc,
    head: "Crypto To Crypto",
    subhead: "Exchange",
    desc: "Terminals is an advanced cryptocurrency exchange platform which allows your users to high frequency trading across hundreds of digital assets.",
    navurl: "https://my.terminals.app/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing users with the ability to trade between crypto currencies from multiple exchanges", "Providing emerging tokens with the ability to get listed on their terminal", "Providing users with short term interest bearing via MoneyMarkets"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who create liquidity",
        desc:["For a token which they launched by providing a native marketplace for trading that token"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:terminalsc,
        title:"Terminal Fees",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:terminalsc,
        title:"Token Listing",
        text:"Earn a one time listing fee as well a a commission each time your users trade the listed token.",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$30,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,500.00"
          },
          {
            head:"Security Deposit",
            value:"$60,000.00"
          },
          {
            head:"MRKT Staking",
            value:"30,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$60,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$5,000.00"
          },
          {
            head:"Security Deposit",
            value:"$120,000.00"
          },
          {
            head:"MRKT Staking",
            value:"60,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$15,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,250.00"
          },
          {
            head:"Security Deposit",
            value:"$30,000.00"
          },
          {
            head:"MRKT Staking",
            value:"15,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: false,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: true,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: true,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 4,
    backgroundImg: nftreward,
    title: "NFTReward",
    link: "/templates/nftreward/businessOverview",
    icon: nftrewardicon,
    coloricon: nftrewardtemp,
    templateicon: nftrewardtemp,
    head: "NFT Rewards",
    subhead: "Platform",
    desc: "The NFTReward template allows you to create an exclusive application for your NFT whereby users can earn rewards for holding your NFT.",
    navurl: "https://nftreward.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Prodiving users with the ability to connect the NFT they purchased from you to a rewards pool on your platform that generates yield through wholesale Bonds", "Providing users with short term interest bearing via MoneyMarkets"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling their own NFT’s"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:nftrewardtemp,
        title:"NFTReward",
        text:"Earn the difference between the wholesale bond rate and the percentage you are giving as rewards",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: true,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 5,
    backgroundImg: fundsall,
    title: "Funds",
    link: "/templates/fund management/businessOverview",
    icon: fundcicon,
    coloricon: fundManagementc,
    templateicon: fundManagementc,
    head: "Investment Fund",
    subhead: "Platform",
    desc: "The Fund Management template is an retail investment platform which allows users to grow their assets by investing and interacting with your specific FundCoin.",
    navurl: "http://fund.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Providing users with tokenized  funds and charging management fees", "Providing users with short term interest bearing via MoneyMarkets",],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling their own investment fund coins"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:fundManagementc,
        title:"Fund Management Fees",
        text:"Charge fixed or variable fee on the assets that your fund manages.",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: true,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: true,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: true,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: true,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 6,
    backgroundImg: nftsll,
    title: "NFT Markets",
    link: "/templates/nft marketplace/businessOverview",
    icon: nftmarketicon,
    coloricon: nftMarketplacec,
    templateicon: nftMarketplacec,
    head: "NFT",
    subhead: "Marketplace",
    desc: "The NFTMarkets template allows enables NFT creators to list their NFT's on your platform as well as providing buyers with a secondary resale market.",
    navurl: "http://nftmarkets.mycryptobrand.com/mycryptobrand.com",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing users with the ability to buy and sell NFT’s", "Providing NFT creators with the ability to mint and list their NFT’s", "Prodiving users with the ability to connect the NFT they purchased from you to a rewards pool on your platform that generates yield through wholesale Bonds", "Providing NFT creators with the ability to attach a bond to their NFT’s to create NFTReward"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized their own NFT’s"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:nftrewardtemp,
        title:"NFTReward",
        text:"Earn the difference between the wholesale bond rate and the percentage you are giving as rewards",
      },
      {
        ele: 3,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 4,
        icon:nftlisting,
        title:"NFT Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a NFT",
      },
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$30,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,500.00"
          },
          {
            head:"Security Deposit",
            value:"$60,000.00"
          },
          {
            head:"MRKT Staking",
            value:"30,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$60,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$5,000.00"
          },
          {
            head:"Security Deposit",
            value:"$120,000.00"
          },
          {
            head:"MRKT Staking",
            value:"60,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$15,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,250.00"
          },
          {
            head:"Security Deposit",
            value:"$30,000.00"
          },
          {
            head:"MRKT Staking",
            value:"15,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: true,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: true,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 7,
    backgroundImg: hifis,
    title: "DEFI Markets",
    link: "/templates/defi markets/businessOverview",
    icon: defimarketicon,
    coloricon: defic,
    templateicon: defic,
    head: "Defi Managment",
    subhead: "Platform",
    desc: "DefiMarkets is a decentralized routing engine that allows your users to manage their DEFI lending operations accross different protocols.",
    navurl: "",
    disableexample: true,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing their users a unified interface whereby they can manage all their defi investments accross multiple protocols", "Providing their users the ability to automate their defi investing using the DRE (Defi Routing Engine)"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:yieldsharing,
        title:"Yield Sharing",
        text:"Take a percentage of all the yields generated from the assets on the platform",
      },
      {
        ele: 1,
        icon:networkfees,
        title:"Network Fees",
        text:"Charge a transaction fee each time a user interacts with a smart contract via your platform",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$30,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,500.00"
          },
          {
            head:"Security Deposit",
            value:"$60,000.00"
          },
          {
            head:"MRKT Staking",
            value:"30,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$60,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$5,000.00"
          },
          {
            head:"Security Deposit",
            value:"$120,000.00"
          },
          {
            head:"MRKT Staking",
            value:"60,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$15,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,250.00"
          },
          {
            head:"Security Deposit",
            value:"$30,000.00"
          },
          {
            head:"MRKT Staking",
            value:"15,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: false,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: false,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: false,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: false,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: false,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: true,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: true,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: true,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: true,
      },
    ],
  },
  {
    id: 8,
    backgroundImg: allsibgle,
    title: "Signals",
    link: "/templates/signals/businessOverview",
    icon: signalicon,
    coloricon: signalsc,
    templateicon: signalsc,
    head: "Education",
    subhead: "Platform",
    desc: "The Signals template allows you to launch your very own financial education platform where you can offer courses, analysis and market signals.",
    navurl: "",
    disableexample: true,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing their users with excusive content, alerts, signals, education and training", "Providing their users with an array of investable opportunities","Providing users with short term interest bearing via MoneyMarkets"],
      },
     
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:subscriptions,
        title:"Subscriptions",
        text:"Create and sell subscriptions for your users to gain access to your content",
      },
     
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$3,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$250.00"
          },
          {
            head:"Security Deposit",
            value:"$6,000.00"
          },
          {
            head:"MRKT Staking",
            value:"3,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$1,500.00"
          },
          {
            head:"Monthly Cost",
            value:"$125.00"
          },
          {
            head:"Security Deposit",
            value:"$3,000.00"
          },
          {
            head:"MRKT Staking",
            value:"1,500.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: true,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: true,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 0,
    backgroundImg: hifi,
    title: "Hyfi",
    link: "/templates/hyfi/businessOverview",
    icon: hifiicon,
    coloricon: hyfic,
    templateicon:hyfic,
    head: "Hybrid Defi",
    subhead: "Platform",
    desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
    navurl: "https://hyfi.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Prodiving users with long term interest bearing instruments such as Bonds", "Providing users with short term interest bearing via MoneyMarkets"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized Bonds"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:bondarbitrage,
        title:"Bond Arbitrage",
        text:"Earn the difference between the retail & wholesale bond rate",
      },
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: true,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 1,
    backgroundImg: z11,
    title: "Wealth",
    link: "/templates/wealth/businessOverview",
    icon: wealthicon,
    coloricon: wealthc,
    templateicon: wealthc,
    head: "Portfolio Management",
    subhead: "Platform",
    desc: "The Wealth Management template offers a total portfolio management system to your users which allows them to track their digital net-worth while giving them access to fixed income, equity, and crypto markets.",
    navurl: "https://wealth.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Prodiving users with long term interest bearing instruments such as Bonds", "Providing users with short term interest bearing via MoneyMarkets", "Allowing other companies to list their ShareTokens, FundCoins, Bonds, and NFT’s on your app"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized Bonds, ShareTokens, NFT’s and/or FundCoins"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:bondarbitrage,
        title:"Bond Arbitrage",
        text:"Earn the difference between the retail & wholesale bond rate",
      },
      {
        ele: 4,
        icon:sharetokenlisting,
        title:"ShareToken Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a ShareToken",
      },
      {
        ele: 5,
        icon:fundcoinlisting,
        title:"FundCoin Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a FundCoin",
      },
      {
        ele: 6,
        icon:nftlisting,
        title:"NFT Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a NFT",
      },
    ],
    drop3data:["$24,000.00", "Annual Cost", "1000 GXT", "Monthly Cost", "$50,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$18,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,500.00"
          },
          {
            head:"Security Deposit",
            value:"$36,000.00"
          },
          {
            head:"MRKT Staking",
            value:"18,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$36,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$3,000.00"
          },
          {
            head:"Security Deposit",
            value:"$72,000.00"
          },
          {
            head:"MRKT Staking",
            value:"36,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$9,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$750.00"
          },
          {
            head:"Security Deposit",
            value:"$18,000.00"
          },
          {
            head:"MRKT Staking",
            value:"9,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: true,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: true,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: true,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: true,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: true,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: true,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: true,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: true,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: true,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: true,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: true,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: true,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 2,
    backgroundImg: otcdesk,
    title: "OTCDesk",
    link: "/templates/otcdesks/businessOverview",
    icon: otcdeskicon,
    coloricon: otcDesksc,
    templateicon: otcDesksc,
    head: "Fiat To Crypto",
    subhead: "Exchange",
    desc: "The OTCDesk is a standard fiat to cryptocurrency exchange application which empowers your users to fund their accounts with their local currency and swap into digital assets.",
    navurl: "https://otc.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing user with the ability convert between forex and crypto currencies", "Providing users with short term interest bearing via MoneyMarkets", "Providing a users with a banking on and off ramp for their local currencies"],
      },
      
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:depositfees,
        title:"Deposit Fees",
        text:"Set fees on the fiat deposits that your users make from their bank",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: true,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 3,
    backgroundImg: terminal,
    title: "Terminal",
    link: "/templates/terminals/businessOverview",
    icon: terminalicon,
    coloricon: terminalsc,
    templateicon: terminalsc,
    head: "Crypto To Crypto",
    subhead: "Exchange",
    desc: "Terminals is an advanced cryptocurrency exchange platform which allows your users to high frequency trading across hundreds of digital assets.",
    navurl: "https://my.terminals.app/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing users with the ability to trade between crypto currencies from multiple exchanges", "Providing emerging tokens with the ability to get listed on their terminal", "Providing users with short term interest bearing via MoneyMarkets"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who create liquidity",
        desc:["For a token which they launched by providing a native marketplace for trading that token"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:terminalsc,
        title:"Terminal Fees",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:terminalsc,
        title:"Token Listing",
        text:"Earn a one time listing fee as well a a commission each time your users trade the listed token.",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$30,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,500.00"
          },
          {
            head:"Security Deposit",
            value:"$60,000.00"
          },
          {
            head:"MRKT Staking",
            value:"30,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$60,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$5,000.00"
          },
          {
            head:"Security Deposit",
            value:"$120,000.00"
          },
          {
            head:"MRKT Staking",
            value:"60,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$15,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,250.00"
          },
          {
            head:"Security Deposit",
            value:"$30,000.00"
          },
          {
            head:"MRKT Staking",
            value:"15,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: false,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: true,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: true,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 4,
    backgroundImg: nftreward,
    title: "NFTReward",
    link: "/templates/nftreward/businessOverview",
    icon: nftrewardicon,
    coloricon: nftrewardtemp,
    templateicon: nftrewardtemp,
    head: "NFT Rewards",
    subhead: "Platform",
    desc: "The NFTReward template allows you to create an exclusive application for your NFT whereby users can earn rewards for holding your NFT.",
    navurl: "https://nftreward.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Prodiving users with the ability to connect the NFT they purchased from you to a rewards pool on your platform that generates yield through wholesale Bonds", "Providing users with short term interest bearing via MoneyMarkets"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling their own NFT’s"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:nftrewardtemp,
        title:"NFTReward",
        text:"Earn the difference between the wholesale bond rate and the percentage you are giving as rewards",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: true,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 5,
    backgroundImg: fundsall,
    title: "Funds",
    link: "/templates/fund management/businessOverview",
    icon: fundcicon,
    coloricon: fundManagementc,
    templateicon: fundManagementc,
    head: "Investment Fund",
    subhead: "Platform",
    desc: "The Fund Management template is an retail investment platform which allows users to grow their assets by investing and interacting with your specific FundCoin.",
    navurl: "http://fund.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Providing users with tokenized  funds and charging management fees", "Providing users with short term interest bearing via MoneyMarkets",],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling their own investment fund coins"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:fundManagementc,
        title:"Fund Management Fees",
        text:"Charge fixed or variable fee on the assets that your fund manages.",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: true,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: true,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: true,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: true,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 6,
    backgroundImg: nftsll,
    title: "NFT Markets",
    link: "/templates/nft marketplace/businessOverview",
    icon: nftmarketicon,
    coloricon: nftMarketplacec,
    templateicon: nftMarketplacec,
    head: "NFT",
    subhead: "Marketplace",
    desc: "The NFTMarkets template allows enables NFT creators to list their NFT's on your platform as well as providing buyers with a secondary resale market.",
    navurl: "http://nftmarkets.mycryptobrand.com/mycryptobrand.com",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing users with the ability to buy and sell NFT’s", "Providing NFT creators with the ability to mint and list their NFT’s", "Prodiving users with the ability to connect the NFT they purchased from you to a rewards pool on your platform that generates yield through wholesale Bonds", "Providing NFT creators with the ability to attach a bond to their NFT’s to create NFTReward"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized their own NFT’s"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:nftrewardtemp,
        title:"NFTReward",
        text:"Earn the difference between the wholesale bond rate and the percentage you are giving as rewards",
      },
      {
        ele: 3,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 4,
        icon:nftlisting,
        title:"NFT Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a NFT",
      },
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$30,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,500.00"
          },
          {
            head:"Security Deposit",
            value:"$60,000.00"
          },
          {
            head:"MRKT Staking",
            value:"30,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$60,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$5,000.00"
          },
          {
            head:"Security Deposit",
            value:"$120,000.00"
          },
          {
            head:"MRKT Staking",
            value:"60,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$15,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,250.00"
          },
          {
            head:"Security Deposit",
            value:"$30,000.00"
          },
          {
            head:"MRKT Staking",
            value:"15,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: true,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: true,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 7,
    backgroundImg: hifis,
    title: "DEFI Markets",
    link: "/templates/defi markets/businessOverview",
    icon: defimarketicon,
    coloricon: defic,
    templateicon: defic,
    head: "Defi Managment",
    subhead: "Platform",
    desc: "DefiMarkets is a decentralized routing engine that allows your users to manage their DEFI lending operations accross different protocols.",
    navurl: "",
    disableexample: true,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing their users a unified interface whereby they can manage all their defi investments accross multiple protocols", "Providing their users the ability to automate their defi investing using the DRE (Defi Routing Engine)"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:yieldsharing,
        title:"Yield Sharing",
        text:"Take a percentage of all the yields generated from the assets on the platform",
      },
      {
        ele: 1,
        icon:networkfees,
        title:"Network Fees",
        text:"Charge a transaction fee each time a user interacts with a smart contract via your platform",
      },
      
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$30,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,500.00"
          },
          {
            head:"Security Deposit",
            value:"$60,000.00"
          },
          {
            head:"MRKT Staking",
            value:"30,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$60,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$5,000.00"
          },
          {
            head:"Security Deposit",
            value:"$120,000.00"
          },
          {
            head:"MRKT Staking",
            value:"60,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$15,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,250.00"
          },
          {
            head:"Security Deposit",
            value:"$30,000.00"
          },
          {
            head:"MRKT Staking",
            value:"15,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: false,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: false,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: false,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: false,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: false,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: true,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: true,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: true,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: true,
      },
    ],
  },
  {
    id: 8,
    backgroundImg: allsibgle,
    title: "Signals",
    link: "/templates/signals/businessOverview",
    icon: signalicon,
    coloricon: signalsc,
    templateicon: signalsc,
    head: "Education",
    subhead: "Platform",
    desc: "The Signals template allows you to launch your very own financial education platform where you can offer courses, analysis and market signals.",
    navurl: "",
    disableexample: true,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by ",
        desc:["Providing their users with excusive content, alerts, signals, education and training", "Providing their users with an array of investable opportunities","Providing users with short term interest bearing via MoneyMarkets"],
      },
     
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:subscriptions,
        title:"Subscriptions",
        text:"Create and sell subscriptions for your users to gain access to your content",
      },
     
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$3,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$250.00"
          },
          {
            head:"Security Deposit",
            value:"$6,000.00"
          },
          {
            head:"MRKT Staking",
            value:"3,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$1,500.00"
          },
          {
            head:"Monthly Cost",
            value:"$125.00"
          },
          {
            head:"Security Deposit",
            value:"$3,000.00"
          },
          {
            head:"MRKT Staking",
            value:"1,500.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: false,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: true,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: true,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 0,
    backgroundImg: hifi,
    title: "Hyfi",
    link: "/templates/hyfi/businessOverview",
    icon: hifiicon,
    coloricon: hyfic,
    templateicon:hyfic,
    head: "Hybrid Defi",
    subhead: "Platform",
    desc: "The HyFi (Hybrid Finance) template enables your users to grow their digital asset portfolio through fixed income instruments. Both in the form of a variable daily interest rate and through fixed term bonds.",
    navurl: "https://hyfi.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Prodiving users with long term interest bearing instruments such as Bonds", "Providing users with short term interest bearing via MoneyMarkets"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized Bonds"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:bondarbitrage,
        title:"Bond Arbitrage",
        text:"Earn the difference between the retail & wholesale bond rate",
      },
    ],
    drop3data:["$12,000.00", "Annual Cost", "500 GXT", "Monthly Cost", "$25,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$12,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,000.00"
          },
          {
            head:"Security Deposit",
            value:"$24,000.00"
          },
          {
            head:"MRKT Staking",
            value:"12,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$24,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$2,000.00"
          },
          {
            head:"Security Deposit",
            value:"$48,000.00"
          },
          {
            head:"MRKT Staking",
            value:"24,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$6,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$500.00"
          },
          {
            head:"Security Deposit",
            value:"$12,000.00"
          },
          {
            head:"MRKT Staking",
            value:"6,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: false,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: false,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: false,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: false,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: false,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: false,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: false,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: true,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: false,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: false,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: false,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: false,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
  {
    id: 1,
    backgroundImg: z11,
    title: "Wealth",
    link: "/templates/wealth/businessOverview",
    icon: wealthicon,
    coloricon: wealthc,
    templateicon: wealthc,
    head: "Portfolio Management",
    subhead: "Platform",
    desc: "The Wealth Management template offers a total portfolio management system to your users which allows them to track their digital net-worth while giving them access to fixed income, equity, and crypto markets.",
    navurl: "https://wealth.mycryptobrand.com/mycryptobrand",
    disableexample: false,
    drop1data:[
      {
        ele: 0,
        head:"Individuals or organizations who wants to make money by",
        desc:["Prodiving users with long term interest bearing instruments such as Bonds", "Providing users with short term interest bearing via MoneyMarkets", "Allowing other companies to list their ShareTokens, FundCoins, Bonds, and NFT’s on your app"],
      },
      {
        ele: 1,
        head:"Individuals or organizations who raise money by",
        desc:["Creating and selling tokenized Bonds, ShareTokens, NFT’s and/or FundCoins"],
      },
    ],
    drop2data:[
      {
        ele: 0,
        icon:tokenswap,
        title:"TokenSwap",
        text:"Set fees on all trades made on your platform",
      },
      {
        ele: 1,
        icon:moneymarket,
        title:"MoneyMarket IRD",
        text:"Take a percentage of all your users liquid MoneyMarket earnings",
      },
      {
        ele: 2,
        icon:withdrawal,
        title:"Withdrawal Fees",
        text:"Set fees on withdrawls from wallets on your platform to another wallet",
      },
      {
        ele: 3,
        icon:bondarbitrage,
        title:"Bond Arbitrage",
        text:"Earn the difference between the retail & wholesale bond rate",
      },
      {
        ele: 4,
        icon:sharetokenlisting,
        title:"ShareToken Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a ShareToken",
      },
      {
        ele: 5,
        icon:fundcoinlisting,
        title:"FundCoin Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a FundCoin",
      },
      {
        ele: 6,
        icon:nftlisting,
        title:"NFT Listing",
        text:"Earn a one time listing fee as well a a commission each time your users by a NFT",
      },
    ],
    drop3data:["$24,000.00", "Annual Cost", "1000 GXT", "Monthly Cost", "$50,000", "Security Deposit"],
    newpricedata:[
      {
        title:"Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$18,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$1,500.00"
          },
          {
            head:"Security Deposit",
            value:"$36,000.00"
          },
          {
            head:"MRKT Staking",
            value:"18,000.00"
          },

        ]
      },
      {
        title:"Non Custodialized",
        prices:[
          {
            head:"Annual Cost",
            value:"$36,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$3,000.00"
          },
          {
            head:"Security Deposit",
            value:"$72,000.00"
          },
          {
            head:"MRKT Staking",
            value:"36,000.00"
          },

        ]
      },
      {
        title:"Joint Venture",
        prices:[
          {
            head:"Annual Cost",
            value:"$9,000.00"
          },
          {
            head:"Monthly Cost",
            value:"$750.00"
          },
          {
            head:"Security Deposit",
            value:"$18,000.00"
          },
          {
            head:"MRKT Staking",
            value:"9,000.00"
          },

        ]
      },
    ],
    templateAccess: [
      {
        key: 0,
        name: "Crypto Vaults",
        value: true,
      },
      {
        key: 1,
        name: "Fiat Vaults",
        value: true,
      },
      {
        key: 2,
        name: "MoneyMarkets",
        value: true,
      },
      {
        key: 3,
        name: "TokenSwap",
        value: true,
      },
      {
        key: 4,
        name: "Affiliate App Integration",
        value: true,
      },
      {
        key: 5,
        name: "Custom Comp Plan",
        value: false,
      },
      {
        key: 6,
        name: "OTC",
        value: false,
      },
      {
        key: 7,
        name: "OTC Marketplace",
        value: false,
      },
      {
        key: 8,
        name: "Portfolio.AI",
        value: true,
      },
      {
        key: 9,
        name: "ShareToken Issuance",
        value: true,
      },
      {
        key: 10,
        name: "ShareToken Marketplace",
        value: true,
      },
      {
        key: 11,
        name: "FundCoin Issuance",
        value: true,
      },
      {
        key: 12,
        name: "FundCoin Marketplace",
        value: true,
      },
      {
        key: 13,
        name: "IndexFund Issuance",
        value: true,
      },
      {
        key: 14,
        name: "IndexFund Marketplace",
        value: true,
      },
      {
        key: 15,
        name: "Bond Issuance",
        value: true,
      },
      {
        key: 16,
        name: "Bond Marketplace",
        value: true,
      },
      {
        key: 17,
        name: "NFT Issuance",
        value: true,
      },
      {
        key: 18,
        name: "NFTMarketplace",
        value: true,
      },
      {
        key: 19,
        name: "Terminal Local",
        value: false,
      },
      {
        key: 20,
        name: "Terminal Global",
        value: false,
      },
      {
        key: 21,
        name: "Staking Vaults",
        value: true,
      },
      {
        key: 22,
        name: "Publication",
        value: false,
      },
      {
        key: 23,
        name: "Education Issuance",
        value: false,
      },
      {
        key: 24,
        name: "Education Marketplace",
        value: false,
      },
      {
        key: 25,
        name: "Crypto Wallets",
        value: false,
      },
      {
        key: 26,
        name: "Defi Integration",
        value: false,
      },
      {
        key: 27,
        name: "Defi Routing Engine",
        value: false,
      },
      {
        key: 28,
        name: "Decentralized Staking",
        value: false,
      },
    ],
  },
];


  
export const productMenu = [
    {
      id: 0,
      name: "Web3",
      title: "Web3",
      icon: accva,
    },
    {
      id: 1,
      name: "Publications",
      title: "Publications",
      icon: publication,
    },
    {
      id: 2,
      name: "Classrooms",
      title: "Classrooms",
      icon: classrooms,
    },
    {
      id: 3,
      name: "Firms",
      title: "Firms",
      icon: Firmsdd,
    },
    {
      id: 4,
      name: "Retailers",
      title: "Retailers",
      icon: retailProduct,
    },
    {
      id: 5,
      name: "Gamers",
      title: "Gamers",
      icon: gamning,
    },
    {
      id: 6,
      name: "Fintech",
      title: "Fintech",
      icon: finch,
    },
    {
      id: 7,
      name: "CoverFi",
      title: "CoverFi",
      icon: coverage,
    },
    {
      id: 8,
      name: "Healthcare",
      title: "Healthcare",
      icon: healthcare,
    },
    {
      id: 8,
      name: "Experiences",
      title: "Experiences",
      icon: Experiencess,
    },
    {
      id: 8,
      name: "Real Estate",
      title: "Real Estate",
      icon:cdcd,
    },
    {
      id: 8,
      name: "Banker",
      title: "Banker",
      icon: banjer,
    },
    
  ];

export const templateMenu = [
  {
    icon: businessOverview,
    name: "Business Overview",
    id: "businessOverview",
  },
  {
    icon: revenueModels,
    name: "Revenue Models",
    id: "revenueModels",
  },
  {
    icon: technology,
    name: "Technology",
    id: "technology",
  },
  {
    icon: coreGxProducts,
    name: "Core GX Products",
    id: "coregxproducts",
  },
  {
    icon: marketsversePlugin,
    name: "Marketsverse Plugins",
    id: "marketsverse",
  },
  {
    icon: caseStudy,
    name: "Case Studies",
    id: "caseStudies",
  },
  {
    icon: deployment,
    name: "Deployment",
    id: "deployment",
  },
  {
    icon: pricing,
    name: "Pricing",
    id: "pricing",
  },
];

export const describeMenu = [
  {
    id:1,
    iconc:start,
    iconw:whitestart,
    textn:"Want To Launch A  ",
    textb:"Tech Startup?",
    rhead1:"Why To Launch A Tech Startup?",
    rhead2:"",
    desc: "Entering the crypto industry has never been so simple. We have eliminated all the traditional complexities with MarketsVerse.com. Just create a free account. Entering the crypto industry has never been so simple. We have eliminated all the traditional complexities.",
    videoimg: startv,
  },
  {
    id:2,
    iconc:runningcrypto,
    iconw:whiterunningcrypto,
    textn:"Already Running A ",
    textb:"Tech Startup?",
    rhead1:"How To Use MarketsVerse To Improve",
    rhead2:"",
    desc: "We provide off the shelf and fully customized solutions for cryptocurrency companies. Launch a new application or integrate our state of the art crypto as a service products into an existing application. Each of our applicaitons can add a revenue positive USP to your crypto business.",
    videoimg: improvev,
  },
  {
    id:3,
    iconc:community,
    iconw:whitecommunity,
    textn:"I Have A Large ",
    textb:"Following/Community",
    rhead1:"Built A Following But Don’t",
    rhead2:"Know How To Monetize?",
    desc: "If you are someone who has established a personal brand on social media, MarketsVerse offers a once in a lifetime opportunity for you to monetize your community by bringing them some of the leading investment opportunities in the crypto markets through your fully branded application.",
    videoimg: montizev,
  },
  {
    id:4,
    iconc:invest,
    iconw:whiteinvest,
    textn:"I Am Looking To Invest ",
    textb:"Into Startups",
    rhead1:"Want To Invest In The Next",
    rhead2:"Coinbase or FTX?",
    desc: "Instead of investing into companies that you will have little control over, we give you access to all the MarketsVerse app owners who need capital for their refundable security deposit. By allocating your capital into their MarketsVerse’s security deposit you can own up to 50% of the company’s ShareTokens while having the assurance that if the company fails, the security deposit is fully refundable.",
    videoimg: ftxv,
  },
  {
    id:5,
    iconc:runningnoncrypto,
    iconw:whiterunningnoncrypto,
    textn:"Already Running A Non-",
    textb:"Tech Business?",
    rhead1:"Looking To Enter The Crypto",
    rhead2:"Space But Don’t Know How?",
    desc: "If you are already running a company which doesn’t operate within the crypto space then entering the market poses a tremendous opportunity and risk for your company and customers. We are here to make sure that you limit your risk while your business enjoys the upside.",
    videoimg: spacev,
  },
  {
    id:6,
    iconc:noneofabove,
    iconw:whitenoneofabove,
    textn:"None Of The Above",
    textb:"",
    rhead1:"None Of The Above",
    rhead2:"",
    desc: "Entering the crypto industry has never been so simple. We have eliminated all the traditional complexities with MarketsVerse.com. Just create a free account",
    videoimg: "",
  },

]

export const companyMenu = [
  {
    id:0,
    title:"MyCryptoBrand",
    desc:"Launch and grow a crypto business with your own whitelabelled application",
    icon:mcbicon,
    // arrow:bluebgarrow,
    backgroundc:"linear-gradient(90deg, #16171A 0%, rgba(0, 41, 133, 0.81) 113.17%)",
  },
  {
    id:1,
    title:"MarketsVerse",
    desc:"Launch and grow a crypto business with your own whitelabelled application",
    icon:marketverse,
    // arrow:greybgarrow,
    backgroundc:"linear-gradient(90deg, #186AB4 0%, #142955 113.17%)",
  },
  {
    id:2,
    title:"MetaverseApps",
    desc:"Launch and grow a crypto business with your own whitelabelled application",
    icon:metaverseapps,
    // arrow:bluebgarrow,
    backgroundc:"linear-gradient(90deg, #277EFB 0%, #1E2931 113.17%)",
  },
  {
    id:3,
    title:"Block.Software",
    desc:"Launch and grow a crypto business with your own whitelabelled application",
    icon:blocksoftware,
    // arrow:greybgarrow,
    backgroundc:"linear-gradient(90deg, #6F40FF 0%, #31E0E6 113.17%)",
  },

]

export const whatToDoMenu = [
  {
    id:0,
    icon:raisecapital,
    title:"Raise Capital",
    desc:"Read About The Latest Updates With CanEarn",
  },
  {
    id:1,
    icon:raisecapital,
    title:"Raise Capital",
    desc:"Read About The Latest Updates With CanEarn",
  },
  {
    id:2,
    icon:raisecapital,
    title:"Raise Capital",
    desc:"Read About The Latest Updates With CanEarn",
  },
  {
    id:3,
    icon:raisecapital,
    title:"Raise Capital",
    desc:"Read About The Latest Updates With CanEarn",
  },
  {
    id:4,
    icon:raisecapital,
    title:"Raise Capital",
    desc:"Read About The Latest Updates With CanEarn",
  },
  {
    id:5,
    icon:raisecapital,
    title:"Raise Capital",
    desc:"Read About The Latest Updates With CanEarn",
  },

]